import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";
export function getRDLocation(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
       /* params: { userId: userId, },*/
    };
    return fetch(baseApiURL + "/Common/GetRDLocationISIRDDL", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};

export function getGroupcategory(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
      /*  params: { userId: userId, },*/
    };
    return fetch(baseApiURL + "/Common/GetCategoryISIRDDL", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};

export function getDevelopment(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
     /*   params: { userId: userId },*/
    };
    return fetch(baseApiURL + "/Common/GetDevelopmentISIRDDL", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};
export function getAutomatedWorkFlowNumber(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {

            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
       /* params: { userId: userId, },*/
    };
    return fetch(baseApiURL + "/Admin/AutomatedRunningWorkflowNoISIR", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};
export function getSeverity(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
      /*  params: { userId: userId, },*/
    };
    return fetch(baseApiURL + "/Common/GetSeverityDDL", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};
export function getSupplierName(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
        /*params: { userId: userId, },*/
    };
    return fetch(baseApiURL + "/Common/GetSupplierDDL", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};
export function getSupplierEmail(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
       /* params: { userId: userId, },*/
    };
    return fetch(baseApiURL + "/Common/GetSupplierEmailISIRDDL", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};
export function SaveasDraft(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
        //body: formData,
        body: JSON.stringify(formData),

    };
    return fetch(baseApiURL + "/Admin/ISIRSaveDraft", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function submit(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, subData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        //body: subData,
        body: JSON.stringify(subData),
    };
    return fetch(baseApiURL + "/Admin/PostISIRDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getStatus(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        /*params: { userId: userId, },*/
    };
    return fetch(baseApiURL + "/Admin/GetISIRStatusmasterDDL", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            //console.log("Error : ", error);
        });
};
////uploadfile data
export function uploadTemplateFileData(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, tabData1) {

    const options = {
        method: "post",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
        processData: false,
        mimeType: "multipart/form-data",  //question need tobe ask
        contentType: false,
        body: tabData1,
    };
    return fetch(baseApiURL + "/Admin/ISIRUploadMultiplefile", options)

        .then((response) => response.json())
        .then((data) => {
            console.log("data", data.result)
            return data.result;

        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function uploadTemplateFileDataTab2(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, tabData2) {

    const options = {
        method: "post",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        processData: false,
        mimeType: "multipart/form-data",  //question need tobe ask
        contentType: false,
        body: tabData2,
    };
    return fetch(baseApiURL + "/Admin/ISIRUploadMultiplefile", options)

        .then((response) => response.json())
        .then((data) => {
            console.log("data", data.result)
            return data.result;

        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addPOTextDetails(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
        body: formData,
    };
    return fetch(baseApiURL + "/Admin/UploadPOText", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//UpdateISIRFinalClosure
export function addClosureDate(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, closeData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
            //"Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(closeData),

    };
    return fetch(baseApiURL + "/Admin/UpdateISIRFinalClosure", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}




//ISIRdownloadfile

//export function getuploadedDatainDownLoadFile(siteToken, userId, isirNumberPassed) {
//  const options = {
//    method: "get",
//   mode: "cors",
////   headers: {
//   Authorization: "Bearer " + siteToken,
////  },
// params: { AutomatedIsirnumber: isirNumberPassed, userId: userId },   //AutomatedISIRNumber///AutomatedIsirnumber//automatedIsirnumber
//AutomatedISIRNumber//AutomatedIsirnumber

//  };
//// return fetch(baseApiURL + "/Admin/GetISIRAttachmentsDetails", options)
////   .then((response) => response.json())
// .then((data) => {
//    console.log(data);
////   return data.result;
//// })
// .catch((error) => {
//     console.log("Error : ", error);
// });
//};

export function getuploadedDatainDownLoadFile(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, isirNumberPassed) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetISIRAttachmentsDetails",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        params: { AutomatedIsirnumber: isirNumberPassed/*, userId: userId*/ },
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}

export function downloadFile(
    siteToken,
     attachmentData) {


    const options = {
        method: "post",
        url: baseApiURL + "/Admin/ISIRdownloadfile",
        mode: "cors",
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken,
        },
        params: {
            AutomatedIsirnumber: attachmentData.AutomatedIsirnumber, Uploadfiletype: attachmentData.Uploadfiletype
        },
    };
    return axios(options)
        .then((response) => {
            return response;
            console.log(response);
        })
        .catch((error) => {
            return error;
        });

}
//export function downloadFile(siteToken, attachmentData) {
//    const options = {
//        method:"post",
//        responseType: "arraybuffer",
//        headers: { Authorization: "Bearer " + siteToken },
//        body: JSON.stringify(attachmentData),
//    };
//    return axios
//        (
//            baseApiURL + "Admin/ISIRdownloadfile",
//            options
//        )
//        .then((response) => {
//            return response;
//        })
//        .catch((error) => {
//            console.log("Error : ", error);
//        });
//}

//getroledetails
export function getrolesForSupplier(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, isirNumberPassed) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
        },
        params: { AutomatedIsirnumber: isirNumberPassed/*, userId: userId,*/ },
    };
    return fetch(baseApiURL + "/Admin/GetRoleDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};

//getallisirdetails
export function getAllIsirDetails(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
       /* params: { userId: userId },*/
    };
    return fetch(baseApiURL + "/Admin/GetAllISIRDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};

export function GetISIRKPIDetails(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, fromDate, toDate, supplierId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/ISIRGetKPI'sDetails",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        params: {
           /* userId: userId,*/
            supplierId: supplierId,
            fromDate: fromDate,
            toDate: toDate
        },
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}

//for sqe
export function getSqe(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
      /*  params: { userId: userId },*/
    };
    return fetch(baseApiURL + "/Admin/GetUserDetailsforSQE", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};
//spr
export function getSpr(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
       /* params: { userId: userId },*/
    };
    return fetch(baseApiURL + "/Admin/GetUserDetailsforSPR", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};
//opr
export function getOpr(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
       /* params: { userId: userId },*/
    };
    return fetch(baseApiURL + "/Admin/GetUserDetailsforOPR", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};

//for suppliertable in list of development tracker //GetUserDetailsforSQE
export function getSupplierlist(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, supplierId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
 },
    };
    return fetch(
        baseApiURL + "/Admin/GetRequestsbySupplier?supplierId=" + supplierId,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

//GetRequestsbyRole
export function getsqeDetailsList(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId, },
    };
    return fetch(
        baseApiURL + "/Admin/GetRequestsbyRole",
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//GetRequestsbyCE
export function getComponentEngiDetailsList(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId, },
    };
    return fetch(
        baseApiURL + "/Admin/GetRequestsbyCE",
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//sieemns remark



export function isirApprovedRemarkBysiemens(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, isirNumberPassed) {
    const options = {
        method: "put",
        url: baseApiURL + "/Admin/UpdateIsapprovedRemarksbyCE",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        params: { AutomatedIsirnumber: isirNumberPassed/*, userId: userId*/ },
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}

//ISIRIsRejectedRemarksBySiemens
export function isirRejectedRemarkBysiemens(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, isirNumberPassed, getRemarkByCe) {
    const options = {
        method: "put",
        url: baseApiURL + "/Admin/UpdateIsRejectedRemarksbyCE",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        params: { AutomatedIsirnumber: isirNumberPassed, remarks: getRemarkByCe, /*userId: userId*/ },
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function uploadMasterFile(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        body: formData
    };
    return fetch(baseApiURL + "/Admin/UploadMasterfiles/", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//checkboxes--->GetAllMasterAttachments
export function getCheckboxesDetails(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
       /* params: { userId: userId },*/
    };
    return fetch(baseApiURL + "/Admin/GetAllMasterAttachments", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
};



 
//refernce from 8d tool using axios
//export function getAttachmentDetails(siteToken, userId, complaintNumber, discipline) {
//const options = {
//   headers: { Authorization: "Bearer " + siteToken },
//  params: { userId: userId, complaintNumber: complaintNumber, Discipline: discipline },
//};
//return axios.get(
//   baseApiURL + "/Discipline/GetDisciplineAttachments/",
//  options
//)
//    .then((response) => {
//      return response.data.result;
//  })
// .catch((error) => {
//     console.log("Error : ", error);
// });
//}

export function getPOText(userId, isirNumberPassed) {
    
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetPOTextView",
        //mode: "cors",
       // headers: {
            //Authorization: "Bearer " + siteToken,
       // },
        params: { automatednumber: isirNumberPassed, userId: userId },
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}

//GetUserDetailsforRD
export function getRdUsers(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, rdlocationId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetUserDetailsforRD",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
        params: { /*userId: userId,*/ rdlocationId: rdlocationId },
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
//GetAllUserDetailsforRD
export function getWholeRDemailId(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetAllUserDetailsforRD",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
       /* params: { userId: userId },*/
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}





