import React,{useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { GetPartDDl } from '../../Redux/APIs/api_Common';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block',
    padding: '0 0 4px',
    lineHeight: '1.5',
    fontSize: '12px'
  },
  input: {
        background:"#EBF7F8",
        fontSize: "14px",
        height: "42px",
        borderRadius: '4px',
        paddingTop:' 8px',
       paddingBottom: '1px',
        boxSizing: 'border-box',
        padding: "4px 6px",
        width: '100%',
        minWidth: '30px',
        flexGrow: '1',
        border: '1px solid #008AA6',
        margin: 0,
        outline: 0,
      
  },
  listbox: {
    width: 342,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: '#D1FFF2',
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#62EEC780',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#EBF7F8',
      color: 'white',
    },
  },
}));

export default function UseAutocomplete(props) {
    const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
    const [partNumber, setPartNumber] = useState([]);
    //azure ad
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const azureToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
  async function PartNumberDDL() {
      const response = await GetPartDDl(siteToken.token,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.role,
          userDetails.roleId);
    if (response && response.length > 0) {
      setPartNumber(response);
    } else {
      setPartNumber([
        {
          id: 0,
          name: "No Options",
        },
      ]);
    }
  }
  
  const classes = useStyles();
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options:partNumber,
    defaultValue:props.defaultValue,
    getOptionLabel: (option) => option.partNumber,
    onChange:props.onChange,
  });
  useEffect(()=>{
    PartNumberDDL();
  },[])
  return (
    <div>
      <div {...getRootProps()}>
        <label className={classes.label} {...getInputLabelProps()}>
          Part Number **
        </label>
        <input disabled={props.disable}  className={classes.input} {...getInputProps()} />
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>{option.partNumber}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}


