import { Grid } from "@material-ui/core";
import React from "react";

export default function ContactUs() {
    return (
        <Grid xs={12} justifyContent="center" className="d-flex">
            <Grid xs={6} className="contactUsCont">
                <h4>
                    Please Contact for any queries or clarifications
                </h4>
                <h3>Name : Sowmya Bhat</h3>
                <h3>Email : sowmya.bhat@siemens.com</h3>
            </Grid>
        </Grid>
    );
}