import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { tableIcons } from "../../../Utilities/Utility";
import { theme, customStylesDefault } from "../../../Utilities/Utility";
import { useSelector, useDispatch } from "react-redux";
import { tableOptionsExport } from "../../../Themes/LightTheme";
import MaterialTable from "material-table";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import {
    FormControl,
    FormHelperText
} from "@material-ui/core";
import Select from 'react-select'
import { getTeamRoleDDL } from "../../../Redux/APIs/api_Common";
import { addD1Details, DeleteD1Details, GetD1Details, UpdateD1 } from "../../../Redux/APIs/api_Disciplines";
import { disciplineAction } from "../../../Redux/Actions/actions";
export default function Discipline1(props) {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const dispatch = useDispatch();
    const discpline1Data = useSelector(
        (state) => state.disciplineReducer.disciplineData
    );
    const [tableData, setTableData] = useState([]);
    //azure ad
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const azureToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    const [teamRole, setTeamRole] = useState([]);
    async function getTeamRoleDetails() {
        const response = await getTeamRoleDDL(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setTeamRole(
                response.map((item) => ({ value: item.id, label: item.name }))
            );
        } else {
            setTeamRole([]);
        }
    }
    function renderTeamRoleValue(value) {
        let obj = {};
        if (teamRole && teamRole.length > 0) {
            obj = teamRole.find((c) => c.value == value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    function isValidEmail(email) {
        const re =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return re.test(String(email).toLowerCase());
    }
    function isValidNameField(name) {
        const re = /^[a-zA-Z ]*$/;
        return re.test(name);
    }
    function isValidNumberField(number) {
        const re = /[0-9]{10}/;
        return re.test(number);
    }
    function validateAlphabet(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidNameField(value)
                ? { isValid: false, helperText: "Only alphabets" }
                : { isValid: true, helperText: "" };
    }

    function validateNumber(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidNumberField(value)
                ? { isValid: false, helperText: "Invalid Number" }
                : { isValid: true, helperText: "" };
    }
    function validateEmail(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidEmail(value)
                ? { isValid: false, helperText: "*invalid Email" }
                : { isValid: true, helperText: "" };
    }
    function validateD1(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    const columns = [
        {
            title: "Position",
            field: "functionPosition",

            validate: (rowData) => validateAlphabet(rowData.functionPosition)
        },
        {
            title: "Name",
            field: "name",
            validate: (rowData) => validateAlphabet(rowData.name)
        },
        {
            title: "Team Role",
            field: "teamRoleId",
            render: (rowData) => <span>{renderTeamRoleValue(rowData.teamRoleId)}</span>,
            editComponent: (props) => getTeamRoleComponent(props),
            validate: (rowData) => validateD1(rowData.teamRoleId)
        },
        {
            title: "Email Address",
            field: "email",
            validate: (rowData) => validateEmail(rowData.email)
        },
        {
            title: "Phone number",
            field: "phoneNumber",
            validate: (rowData) => validateNumber(rowData.phoneNumber)
        }
    ];
    function getTableDataD1() {
        var complaintNum = props?.rowData[0].complaintNumber;
        setIsLoader(true);
        GetD1Details(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, complaintNum)
            .then((response) => {
                if (response.length > 0) {
                    discpline1Data.discipline1 = true;
                    dispatch(disciplineAction(discpline1Data));
                }
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function isEditable() {
        if (userDetails.roleId !== 3) {
            return false;
        }
        else {
            if (props.rowData[0]?.status === "Pending by Supplier" || props.rowData[0]?.status === "Rejected by User") {
                return true
            }
            else {
                return false;
            }
        }
    }

    const handleRowAdd = (newTableData, resolve, reject) => {
        newTableData.loggedinUserId = userDetails.userId;
        if (isEditable() === false) {
            handleSnackOpen("Action Restricted", "Info");
            reject();
            setIsLoader(false)
        }
        else {
            setIsLoader(true);
            newTableData.complaintNum = props?.rowData[0].complaintNumber;
            addD1Details(siteToken.token,
                azureToken.token,
                UID.id,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.role,
                userDetails.roleId, newTableData)
                .then((response) => {
                    if (response.response) {
                        handleSnackOpen(response.responseMsg, "success");
                        resolve();
                        setIsLoader(false);
                        getTableDataD1();
                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        reject();
                        setIsLoader(false);
                    }
                })
                .catch((error) => {
                    handleSnackOpen("Exception : " + error, "error");
                    reject();
                    setIsLoader(false);
                });
        }

    };
    useEffect(() => {
        getTableDataD1();
        getTeamRoleDetails();
    }, [userDetails]);

    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        newTableData.loggedinUserId = userDetails.userId;
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        newTableData.complaintNum = props?.rowData[0].complaintNumber;
        UpdateD1(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
            , newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableDataD1();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    }
    function handleRowDelete(oldFormData, resolve, reject) {
        setIsLoader(true);
        DeleteD1Details(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, oldFormData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableDataD1();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoader(false);
                handleSnackOpen("Exception : " + error, "error");
                reject();
            });
    }
    function getTeamRoleComponent(objProps) {
        const Role = objProps.rowData.teamRoleId
            ? teamRole.find((x) => x.value === objProps.rowData.teamRoleId)
            : null;
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={theme}
                    options={teamRole}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? Role
                            : objProps.value
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt?.value);
                    }}
                    styles={customStylesDefault}
                    placeholder="Team Role"
                    isClearable
                />
                <>
                    <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
                </>
            </FormControl>
        );
    }
    return (
        <>

            <Grid container xs={12} className="complaintsHomePage">
                <Grid item xs={12} className="pageTitle">
                    <h3 className="title-heading">Problem Solving Team</h3>
                </Grid>
                <Grid item xs={12} className="matTable">

                    <MaterialTable
                        columns={columns}
                        data={tableData}
                        icons={tableIcons}
                        localization={{
                            body: {
                                addTooltip: userDetails.roleId === 3 ? 'Add' : 'View Only',
                            }
                        }}
                        editable={{
                            onRowUpdate: (newTableData, oldTableData) =>
                                new Promise((resolve, reject) => {
                                    handleRowUpdate(
                                        newTableData,
                                        oldTableData,
                                        resolve,
                                        reject
                                    );
                                }),
                            onRowDelete: (oldFormData) =>
                                new Promise((resolve, reject) => {
                                    handleRowDelete(oldFormData, resolve, reject);
                                }),
                            onRowAdd: (newTableData) =>
                                new Promise((resolve, reject) => {
                                    handleRowAdd(newTableData, resolve, reject);
                                }),
                            editTooltip: () => userDetails.roleId === 3 ?
                                "Edit" : "Action Restricted"
                            ,
                            deleteTooltip: () => userDetails.roleId === 3 ?
                                "Delete" : "Action Restricted"
                            ,
                            isEditable: isEditable,
                            isDeletable: isEditable
                        }}
                        isLoading={isLoader}
                        options={tableOptionsExport}
                    />
                </Grid>
                {userDetails.roleId === 3 ? <Grid container spacing={3} className="submit-btn">

                </Grid> : <Grid></Grid>}
                <>
                    <SnackbarCustom
                        open={open}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleClose}
                    />
                </>
            </Grid>
        </>
    )
}