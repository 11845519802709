import { useEffect, useState } from "react";
import Loading from "../../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, Button, MenuItem } from "@material-ui/core";
import { getD1WhoDDL, getStatusDDL } from "../../../Redux/APIs/api_Common";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { useSelector } from "react-redux";
import "./Disciplines.scss";
import { GetD1Details, PostD8Details, UpdateD8 } from "../../../Redux/APIs/api_Disciplines";
import { RejectRemarksBySiemens, Status8DApproveBySiemens, Status8DBySupplier } from "../../../Redux/APIs/api_status";
import moment from "moment";
import { getTodayDateMoment } from "../../../Utilities/Utility";
export default function DIscipline8(props) {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const disicplinesData = useSelector(
        (state) => state.disciplineReducer.disciplineData
    );
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [dialogContent8D, setDialogContent8D] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogSubmit, setOpenDialogSumbit] = useState(false);
    //azure ad
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const azureToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const handleClickOpen = (text) => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClickOpenSubmit8D = (text) => {
        setDialogContent8D(text)
        setOpenDialogSumbit(true);
    };
    const handleCloseDialogSubmit8D = () => {
        setOpenDialogSumbit(false);
        navigate("/complaintsList");
    };
    const [remarks, setRemarks] = useState("");
    const handleChangeRemarks = (e) => {
        setRemarks(e.target.value);
        setErrorTexts({
            ...errorTexts,
            remarks: ""
        })
    }
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };

    const [inputValues, setInputValues] = useState({
        Approved8Did: props.d8Details.length > 0 ? props.d8Details[0]?.approved8Did : null,
        Remarks: props.d8Details.length > 0 ? props.d8Details[0]?.remarks : "",
        Date: props.d8Details.length > 0 ? props.d8Details[0]?.date : "",
        StatusId: props.d8Details.length > 0 ? props.d8Details[0].statusId : null,
        complaintNum: props?.rowData[0].complaintNumber,
        loggedinUserId: userDetails.userId,
    })
    const [d8Values, setD8Values] = useState({
        Approved8Did: props.d8Details.length > 0 ? props.d8Details[0]?.approved8Did : null,
        Remarks: props.d8Details.length > 0 ? props.d8Details[0]?.remarks : "",
        Date: props.d8Details.length > 0 ? props.d8Details[0]?.date : "",
        StatusId: props.d8Details.length > 0 ? props.d8Details[0].statusId : null
    })
    const [errorTexts, setErrorTexts] = useState({
        Approved8Did: "",
        Remarks: "",
        Date: "",
        StatusId: "",
        remarks: ""
    })
    const handleChange = (event, value) => {
        let elem = event.target.name;
        switch (elem) {
            case "Approved8Did":
                setInputValues({
                    ...inputValues,
                    [elem]: value.props.children
                });
                setErrorTexts({
                    ...errorTexts,
                    [elem]: ""
                })

                break;
            case "Remarks":
                setInputValues({
                    ...inputValues,
                    [elem]: event.target.value
                });
                setErrorTexts({
                    ...errorTexts,
                    [elem]: ""
                })
                break;
            case "Date":
                setInputValues({
                    ...inputValues,
                    [elem]: event.target.value
                })
                setErrorTexts({
                    ...errorTexts,
                    [elem]: ""
                })
                break;
            case "StatusId":
                setInputValues({
                    ...inputValues,
                    [elem]: event.target.value
                })
                setErrorTexts({
                    ...errorTexts,
                    [elem]: ""
                })
                break;
            default:
                break;
        }
    }
    const [d1, setD1] = useState(false);
    async function getTableDataD1() {
        setIsLoader(true);
        var complaintNum = props?.rowData[0].complaintNumber;
        const response = await GetD1Details(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, complaintNum)
        if (response.length > 0) {
            response.isDraft = true
            setD1(true);
        }
        setIsLoader(false);
    }
    const [whoData, setWhoData] = useState([]);
    async function getWhoDetails() {
        const response = await getD1WhoDDL(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, props?.rowData[0].complaintNumber);
        if (response && response.length > 0) {
            setWhoData(
                response.map((item, key) => ({ value: key, label: response[key] }))
            );
        } else {
            setWhoData([]);
        }
    }
    function FinalSubmit8D() {
        if (!disicplinesData.discipline1) {
            handleSnackOpen("Please Fill Discipline1 Details", "info");
        }
        else if (!disicplinesData.discipline2) {
            handleSnackOpen("Please Fill and save Discipline2 Details", "info");
        }
        else if (!disicplinesData.discipline3) {
            handleSnackOpen("Please Fill Discipline3 Details", "info");
        }
        else if (!disicplinesData.disciplineStatus3) {
            handleSnackOpen("Discipline3 Status is still Open", "info");
        }
        else if (!disicplinesData.discipline4) {
            handleSnackOpen("Please Fill Discipline4 Details", "info");
        }
        else if (!disicplinesData.discipline41 || !disicplinesData.discipline42) {
            handleSnackOpen("Please Fill  Discipline4 WHY Details", "info");
        }
        else if (!disicplinesData.discipline5) {
            handleSnackOpen("Please Fill Discipline5 Details", "info");
        }
        else if (!disicplinesData.discipline51) {
            handleSnackOpen("Please Fill Discipline5 Details", "info");
        }
        else if (!disicplinesData.disciplineStatus51) {
            handleSnackOpen("Discipline5 Status is still Open", "info");
        }
        else if (!disicplinesData.disciplineStatus52) {
            handleSnackOpen("Discipline5 Status is still Open", "info");
        }
        else if (!disicplinesData.discipline6) {
            handleSnackOpen("Please Fill Discipline6 Details", "info");
        }
        else if (!disicplinesData.disciplineStatus6) {
            handleSnackOpen("Discipline6 Status is still Open", "info");
        }
        else if (!disicplinesData.discipline7) {
            handleSnackOpen("Please Fill Discipline7 Details", "info");
        }
        else if (!disicplinesData.disciplineStatus7) {
            handleSnackOpen("Discipline7 Status is still Open", "info");
        }
        else if (!disicplinesData.discipline8) {
            handleSnackOpen("Please Fill and save Discipline8 Details", "info");
        }
        else if (!disicplinesData.disciplineStatus8) {
            handleSnackOpen("Discipline8 Status is still Open", "info");
        }
        else {
            submit8DBySupplier();
        }
    }
    const AddD8Details = () => {
        if
            (!inputValues.Approved8Did || !inputValues.Remarks || !inputValues.Date || !inputValues.StatusId) {
            if (!inputValues.Approved8Did && !inputValues.Remarks && !inputValues.Date && !inputValues.StatusId) {
                setErrorTexts({
                    ...errorTexts,
                    Approved8Did: "*Mandatory",
                    Remarks: "*Mandatory",
                    Date: "*Mandatory",
                    StatusId: "*Mandatory"
                })
            }
            else if (!inputValues.Approved8Did) {
                setErrorTexts({
                    ...errorTexts,
                    Approved8Did: "*Mandatory",
                })
            }
            else if (!inputValues.Remarks) {
                setErrorTexts({
                    ...errorTexts,
                    Remarks: "*Mandatory",
                })
            }
            else if (!inputValues.Date) {
                setErrorTexts({
                    ...errorTexts,
                    Date: "*Mandatory",
                })
            }
            else if (!inputValues.StatusId) {
                setErrorTexts({
                    ...errorTexts,
                    StatusId: "*Mandatory",
                })
            }
        }
        else {
            setIsLoader(true);
            if (props?.d8UpdateResponse?.length > 0) {
                var updateId = props.d8UpdateResponse[0].discipline8Id;
                const updateValues = {
                    ...inputValues,
                    discipline8Id: updateId
                }
                UpdateD8(siteToken.token,
                    azureToken.token,
                    UID.id,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.role,
                    userDetails.roleId, updateValues)
                    .then((response) => {
                        if (response.response) {
                            handleSnackOpen(response.responseMsg, "success");
                            setIsLoader(false);
                            props.getD8Details();

                        } else {
                            handleSnackOpen(response.responseMsg, "error");
                            setIsLoader(false);
                        }
                    })
                    .catch((error) => {
                        handleSnackOpen("Exception : " + error, "error");
                        setIsLoader(false);
                    });
            }
            else {
                setIsLoader(true);
                PostD8Details(siteToken.token,
                    azureToken.token,
                    UID.id,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.role,
                    userDetails.roleId, inputValues)
                    .then((response) => {
                        if (response.response) {
                            handleSnackOpen(response.responseMsg, "success");
                            setIsLoader(false);
                            props.getD8Details();
                        } else {
                            handleSnackOpen(response.responseMsg, "error");
                            setIsLoader(false);
                        }
                    })
                    .catch((error) => {
                        handleSnackOpen("Exception : " + error, "error");
                        setIsLoader(false);
                    });
            }
        }
    };


    const [statusData, setStatusData] = useState([]);
    async function getStatusDetails() {
        const response = await getStatusDDL(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setStatusData(
                response.map((item) => ({ id: item.id, name: item.name }))
            );
        } else {
            setStatusData([]);
        }
    }
    const submit8DBySupplier = () => {
        let complaintNumber = props?.rowData[0].complaintNumber;
        setIsLoader(true);
        Status8DBySupplier(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, complaintNumber)
            .then((response) => {
                if (response.response) {
                    handleClickOpenSubmit8D(response.responseMsg);
                    setIsLoader(false);

                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });

    }
    const Approve8DStatusBySiemens = () => {
        let complaintNumber = props?.rowData[0].complaintNumber;
        setIsLoader(true);
        Status8DApproveBySiemens(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, complaintNumber)
            .then((response) => {
                handleClickOpenSubmit8D(response.responseMsg);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });

    }
    const handleReject = () => {
        let complaintNumber = props?.rowData[0].complaintNumber;
        if (!remarks) {
            setErrorTexts({
                ...errorTexts,
                remarks: "*Required"
            })
        }
        else {
            setIsLoader(true);
            RejectRemarksBySiemens(siteToken.token,
                azureToken.token,
                UID.id,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.role,
                userDetails.roleId, complaintNumber, remarks)
                .then((response) => {
                    if (response.response) {
                        handleCloseDialog();
                        handleClickOpenSubmit8D(response.responseMsg)
                        setIsLoader(false);
                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        setIsLoader(false);
                    }
                })
                .catch((error) => {
                    handleSnackOpen("Exception : " + error, "error");
                    setIsLoader(false);
                });
        }
    }
    useEffect(() => {
        console.log(disicplinesData)
        if (props) {
            setIsLoader(true);
            getTableDataD1()
            props.getD1Details();
            props.getD3Details();
            props.getD41Details();
            props.getD42Details();
            props.getD51Details();
            props.getD52Details();
            props.getD6Details();
            props.getD7Details();
            props.getD4Details();
            props.getD2Details();
            props.getD8Details();
            getStatusDetails();
            getWhoDetails();
            setIsLoader(false);
        }
    }, [])
    return (

        <Grid container spacing={2} md={12}>
            <Grid item xs={12} className="details-center">
                <h3 className="title-heading">Individual and Team Recognition</h3>
            </Grid>
            <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                <span>8D Approved By Whom</span>
            </Grid>
            <Grid item xs={12} sm={3} md={5}>
                <TextField
                    className="mt-1"
                    select
                    fullWidth
                    disabled={userDetails.roleId !== 3 || props?.rowData[0]?.status === "8D Completed" || props?.rowData[0]?.status === "Approved"}
                    value={
                        userDetails.roleId !== 3 ?
                            d8Values.Approved8Did :
                            inputValues.Approved8Did
                    }
                    error={
                        errorTexts.Approved8Did && errorTexts.Approved8Did.length > 0
                    }
                    helperText={errorTexts.Approved8Did}
                    onChange={handleChange}
                    name="Approved8Did"
                    type="text"
                    id="rejectQuantity"

                >{
                        whoData.map((item) => (
                            <MenuItem key={item.value} value={item.label}>
                                {item.label}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </Grid>
            <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                <span>Remarks</span>
            </Grid>
            <Grid item xs={12} sm={3} md={5}>
                <TextField
                    className="mt-1"
                    name="Remarks"
                    maxRows={2}
                    disabled={userDetails.roleId !== 3 || props?.rowData[0]?.status === "8D Completed" || props?.rowData[0]?.status === "Approved"}
                    error={
                        errorTexts.Remarks && errorTexts.Remarks.length > 0
                    }
                    helperText={errorTexts.Remarks}
                    onChange={handleChange}
                    value={userDetails.roleId !== 3 ?
                        d8Values.Remarks :
                        inputValues.Remarks}
                    fullWidth
                    id="Remarks"
                    multiline
                />
            </Grid>
            <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                <span>Date</span>
            </Grid>
            <Grid item xs={12} sm={3} md={5}>
                <TextField
                    className="mt-1"
                    value={userDetails.roleId !== 3 ?
                        moment(d8Values.Date).format('YYYY-MM-DD') :
                        moment(inputValues.Date).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    error={
                        errorTexts.Date && errorTexts.Date.length > 0
                    }
                    inputProps={{ max: getTodayDateMoment() }}
                    helperText={errorTexts.Date}
                    disabled={userDetails.roleId !== 3 || props?.rowData[0]?.status === "8D Completed" || props?.rowData[0]?.status === "Approved"}
                    type="date"
                    name="Date"
                    fullWidth
                    id="rejectQuantity"

                />
            </Grid>
            <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                <span>Status</span>
            </Grid>
            <Grid item xs={12} sm={3} md={5}>
                <TextField
                    className="mt-1"
                    select
                    disabled={userDetails.roleId !== 3 || props?.rowData[0]?.status === "8D Completed" || props?.rowData[0]?.status === "Approved"}
                    onChange={handleChange}
                    fullWidth
                    value={userDetails.roleId !== 3 ?
                        d8Values.StatusId :
                        inputValues.StatusId}
                    error={
                        errorTexts.StatusId && errorTexts.StatusId.length > 0
                    }
                    helperText={errorTexts.StatusId}
                    name="StatusId"
                    type="text"
                    id="StatusId"

                >{
                        statusData.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </Grid>

            <Grid container spacing={3} className="submit-btn" style={{ margin: "25px" }}>

                {userDetails.roleId === 3 ?
                    <>
                        <Grid item>
                            <Button className="pt-button--primary button-submit" disabled={props?.rowData[0].status === "8D Completed" || props?.rowData[0]?.status === "Approved"} onClick={AddD8Details} variant="contained">
                                Save
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button className="pt-button--primary button-submit"
                                onClick={() => {
                                    FinalSubmit8D()
                                }
                                }
                                disabled={props?.rowData[0].status === "8D Completed" || props?.rowData[0]?.status === "Approved"}
                                variant="contained">
                                Submit 8D
                            </Button>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item>
                            <Button className="pt-button--primary button-submit" onClick={Approve8DStatusBySiemens} disabled={props?.rowData[0].status !== "8D Completed"} variant="contained">
                                Approve
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button className="pt-button--reject button-submit" onClick={handleClickOpen} disabled={props?.rowData[0].status !== "8D Completed"} variant="contained">
                                Reject
                            </Button>
                        </Grid>
                    </>
                }

                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
                <Dialog fullWidth open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <h3 className="margin-d8">Remarks</h3>
                        <Grid>
                            <TextField
                                fullWidth
                                id="standard-multiline-flexible"
                                placeholder=" Enter Remarks"
                                variant="outlined"
                                inputProps={{
                                    style: {
                                        height: "70px",
                                    },
                                }}
                                maxRows={5}
                                value={remarks}
                                error={
                                    errorTexts.remarks && errorTexts.remarks.length > 0
                                }
                                helperText={errorTexts.remarks}
                                onChange={handleChangeRemarks}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>

                        <Button className="pt-button--primary button-submit" variant="contained" onClick={handleReject} color="primary">
                            Submit
                        </Button>
                        <Button className="pt-button--secondary button-submit" variant="contained" onClick={handleCloseDialog} color="primary">
                            Cancel
                        </Button>

                    </DialogActions>
                </Dialog>
                <Dialog fullWidth open={openDialogSubmit} onClose={handleCloseDialogSubmit8D} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <h3 style={{ textAlign: "center" }}>Complaint Number :<span style={{ background: "var(--btn-hero-bg-hover)" }}>{
                            props?.rowData[0].complaintNumber
                        }</span></h3>
                        <div style={{ textAlign: "center", padding: "10px" }}>{dialogContent8D}</div>

                    </DialogContent>
                    <DialogActions>

                        <Button className="pt-button--primary button-submit" variant="contained" onClick={handleCloseDialogSubmit8D} color="primary">
                            Ok
                        </Button>

                    </DialogActions>
                </Dialog>
                <>
                    {isLoader ? <Loading loaderText="Loading...." /> : <></>}
                </>
            </Grid>
            {props?.rowData[0]?.remarksbysiemens ?
                <Grid container>
                    <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                        <span>Rejected Remarks</span>
                    </Grid>
                    <Grid className="pd-btm" item xs={12} sm={3} md={5}>
                        <TextField
                            className="mt-1"
                            name="Remarks"
                            multiline
                            variant="outlined"
                            disabled
                            maxRows={10}
                            onChange={handleChange}
                            value={props?.rowData[0]?.remarksbysiemens}
                            fullWidth
                            id="Remarks"
                        />
                    </Grid>
                </Grid>
                : <></>
            }
        </Grid>
    )
}