import React, { useEffect } from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import CreateComplaintDetails from "./CreateComplaint/CreateComplaintDetails";
import NonConformityList from "./CreateComplaint/NonConformityList";
import Upload from "./CreateComplaint/UploadAttachment";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { baseApiURL } from "../../Utilities/Utility";
import Loading from "../../Components/Loader/Loader";
import { PostComplaintDetails, SaveDraft } from "../../Redux/APIs/api_Complaint";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { useLocation } from "react-router-dom";
import DialogContent from '@material-ui/core/DialogContent';
import { AcknowlegeStatus, RejectStatus, StatusRecallBySiemens } from "../../Redux/APIs/api_status";
export default function CreateComplaintRoot() {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const location = useLocation();
    const complaintListData = useState(location?.state?.rowData ? location.state.rowData : "");
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const azureToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [dialogErrorText, setDialogErrorText] = useState("");
    const [open, setOpen] = useState(false);
    const [openValidationDialog, setOpenValidationDialog] = useState("");
    const [openAckDialog, setOpenAckDialog] = useState("");
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClickOpen = (text) => {
        setDialogContent(text);
        setOpenDialog(true);
    };
    const handleClickOpenAck = (text) => {
        setDialogContent(text);
        setOpenAckDialog(true);
    };
    const handleClickOpenDialogValidation = (text) => {
        setDialogErrorText(text);
        setOpenValidationDialog(true);
    };
    const handleClose = (e) => {
        setOpen(false);
        navigate("/complaintsList");
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate("/complaintsList");
    };
    const handleCloseDialogRemarks = () => {
        setOpenDialog(false);
        setOpenValidationDialog(false);
        setOpenAckDialog(false);
    };
    const [remarks, setRmarks] = useState("");
    const handleChange = (e) => {
        setRmarks(e.target.value);
        setErrorTexts({
            remarks: ""
        })
    }
    const [ackRemarks, setAckRemarks] = useState("");
    const handleChangeAckRemarks = (e) => {
        setAckRemarks(e.target.value);
    }
    const [complaintDetails, setComplaintDetails] = useState("");
    const generateComplaintID = () => {
        setIsLoader(true);
        const options = {
            method: "get",
            headers: {
                Authorization: "Bearer " + siteToken.token,
                AzureToken: azureToken.token,
                UserEmail: userDetails.userEmail,
                RoleName: userDetails.role,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };
        return fetch(baseApiURL + "/Admin/GenerateComplaintNumber", options)
            .then((response) => response.json())
            .then((data) => {
                setComplaintDetails(data.result.objResult);
                if (complaintListData[0]?.name) {
                    setDraftInputs({
                        ...draftInputs,
                        complaintNumber: data.result.objResult.complaintNumber
                    })
                }
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
                setIsLoader(false);
            });
    };

    const detailDescriptionInfo = [
        {
            NonConformityId: 1,
            DetailedDescription: "",
            complaintNumber: complaintDetails?.complaintNumber,
        },
        {
            NonConformityId: 2,
            DetailedDescription: "",
            complaintNumber: complaintDetails?.complaintNumber,
        },
        {
            NonConformityId: 3,
            DetailedDescription: "",
            complaintNumber: complaintDetails?.complaintNumber,
        },
        {
            NonConformityId: 4,
            DetailedDescription: "",
            complaintNumber: complaintDetails?.complaintNumber,
        },
        {
            NonConformityId: 6,
            DetailedDescription: "",
            complaintNumber: complaintDetails?.complaintNumber,
        },
    ]
    const [createComplaintInputs, setCreateComplaintInputs] = useState({
        supplierEmail: null,
        cclist: [],
        partNumber: null,
        partDescriptionAuto: "",
        supplierNameAuto: "",
        supplierNumberAuto: "",
        supplierPo: "",
        supplierInvoice: "",
        deliveredQuantity: 0,
        detailDescription: detailDescriptionInfo,
        severity: 0,
        whereitisdetected: 0,
        issueReportedOn: null,
        defectiveParts: 0,
        estimatedDefectiveRate: "",
        potentialTotalQty: 0,
        isSortingrework: null,
        inspectedQty: 0,
        isRma: null,
        rejectedRMAQty: 0,
        rejectedQty: 0,
        priorityId: 0,
        targetdate: null,
        ncc: "",
        required8D: null,
        dateCodeBatchLot: null,
        frequency: 0
    });
    const [draftInputs, setDraftInputs] = useState({
        complaintNumber: complaintListData[0] ? complaintListData[0].complaintNumber : "",
        senderName: userDetails.userFirstName,
        supplierId: complaintListData ? complaintListData[0].supplierId : null,
        supplierUserId: complaintListData ? complaintListData[0].supplierUserId : null,
        partId: complaintListData ? complaintListData[0].partId : null,
        partNumber: complaintListData ? complaintListData[0].partNumber : null,
        cclist: complaintListData ? complaintListData[0].cclist : null,
        partNo: complaintListData ? { partId: complaintListData[0].partId, partNumber: complaintListData[0].partNumber } : null,
        id: complaintListData ? complaintListData[0].id : null,
        partDescription: complaintListData ? complaintListData[0].partDescription : "",
        supplierName: complaintListData ? complaintListData[0].supplierName : null,
        suppliernumber: complaintListData ? complaintListData[0].suppliernumber : "",
        supplierPo: complaintListData ? complaintListData[0].supplierPo : "",
        supplierInvoice: complaintListData ? complaintListData[0].supplierInvoice : null,
        deliveredQty: complaintListData ? complaintListData[0].deliveredQty : 0,
        loggedInUserId: userDetails.userId,
        detaildescription: complaintListData
            ? complaintListData[0].detaildescription
            : detailDescriptionInfo,
        severityId: complaintListData ? complaintListData[0].severityId : null,
        whereitisdetected: complaintListData ? complaintListData[0].whereitisdetected : null,
        issueReportedOn: complaintListData ? complaintListData[0].issueReportedOn : null,
        defectivePartsCount: complaintListData ? parseInt(complaintListData[0].defectivePartsCount) : null,
        estimatedDefectiveRate: complaintListData ? complaintListData[0].estimatedDefectiveRate : "",
        potentialTotalQty: complaintListData ? complaintListData[0].potentialTotalQty : null,
        isSortingrework: complaintListData ? complaintListData[0].isSortingrework : null,
        inspectedQuantity: complaintListData ? parseInt(complaintListData[0].inspectedQuantity) : null,
        isRma: complaintListData ? complaintListData[0].isRma : null,
        rejectedQty: complaintListData ? parseInt(complaintListData[0].rejectedQty) : null,
        rmarejectedQty: complaintListData ? parseInt(complaintListData[0].rmarejectedQty) : null,
        priorityId: complaintListData ? complaintListData[0].priorityId : null,
        targetdate: complaintListData ? complaintListData[0].targetdate : null,
        ncc: complaintListData ? complaintListData[0].ncc : null,
        required8D: complaintListData ? complaintListData[0].required8D : null,
        dateCodeBatchLot: complaintListData ? complaintListData[0].dateCodeBatchLot : null,
        frequency: complaintListData ? complaintListData[0].frequency : null
    });

    let inputs = {
        "partId": complaintListData[0]
            ? draftInputs.partId
            : createComplaintInputs?.partNumber,
        "nonConformityId": null,
        "senderName": complaintListData[0] ? draftInputs.senderName : userDetails.userFirstName,
        "supplierId": complaintListData[0] ? draftInputs.supplierId : createComplaintInputs?.supplierId,
        "supplierUserId": complaintListData[0] ? draftInputs.supplierUserId : createComplaintInputs?.id,
        "targetdate": complaintListData[0] ? draftInputs.targetdate : createComplaintInputs?.targetdate,
        "companyName": complaintListData[0] ? draftInputs.supplierName : createComplaintInputs?.supplierNameAuto,
        "userId": userDetails.userId,
        "id": complaintListData[0] ? draftInputs.id : createComplaintInputs?.id,
        "loggedInUserId": userDetails.userId,
        "whereitisdetected": complaintListData[0] ? draftInputs.whereitisdetected : parseInt(createComplaintInputs?.whereitisdetected),
        "supplierPo": complaintListData[0] ? draftInputs.supplierPo : createComplaintInputs?.supplierPo,
        "supplierInvoice": complaintListData[0] ? draftInputs.supplierInvoice : createComplaintInputs?.supplierInvoice,
        "dateCodeBatchLot": complaintListData[0] ? draftInputs.dateCodeBatchLot : createComplaintInputs?.dateCodeBatchLot,
        "deliveredQty": complaintListData[0] ? parseInt(draftInputs.deliveredQty) : parseInt(createComplaintInputs?.deliveredQuantity),
        "defectivePartsCount": complaintListData[0] ? parseInt(draftInputs.defectivePartsCount) : parseInt(createComplaintInputs?.defectiveParts),
        "inspectedQuantity": complaintListData[0] ? parseInt(draftInputs.inspectedQuantity) : parseInt(createComplaintInputs?.inspectedQty),
        "estimatedDefectiveRate": complaintListData[0] ? draftInputs.estimatedDefectiveRate : createComplaintInputs?.estimatedDefectiveRate,
        "potentialTotalQty": complaintListData[0] ? parseInt(draftInputs.potentialTotalQty) : parseInt(createComplaintInputs?.potentialTotalQty),
        "issueReportedOn": complaintListData[0] ? draftInputs.issueReportedOn : createComplaintInputs?.issueReportedOn,
        "isSortingrework": complaintListData[0] ? draftInputs.isSortingrework : createComplaintInputs?.isSortingrework,
        "isRma": complaintListData[0] ? draftInputs.isRma : createComplaintInputs?.isRma,
        "severityId": complaintListData[0] ? draftInputs.severityId : createComplaintInputs?.severity,
        "priorityId": complaintListData[0] ? draftInputs.priorityId : createComplaintInputs?.priorityId,
        "complaintNumber":
            complaintListData[0] ?
                complaintListData[0]?.name ?
                    complaintDetails.complaintNumber :
                    draftInputs.complaintNumber :
                complaintDetails.complaintNumber,
        "cclist": complaintListData[0] ? draftInputs.cclist : createComplaintInputs?.cclist,
        "detaildescription": complaintListData[0] ? draftInputs.detaildescription : createComplaintInputs.detailDescription,
        "ncc": complaintListData[0] ? draftInputs.ncc : createComplaintInputs?.ncc,
        "required8D": complaintListData[0] ? draftInputs.required8D : createComplaintInputs?.required8D,
        "rmarejectedQty": complaintListData[0] ? parseInt(draftInputs.rmarejectedQty) : parseInt(createComplaintInputs?.rejectedRMAQty),
        "rejectedQty": complaintListData[0] ? parseInt(draftInputs.rejectedQty) : parseInt(createComplaintInputs?.rejectedQty),
        "frequency": complaintListData[0] ? parseInt(draftInputs.frequency) : parseInt(createComplaintInputs?.frequency)
    };
    const [errorTexts, setErrorTexts] = useState({
        Remarks: ""
    })
    const [isLoader, setIsLoader] = useState(false);
    const InsertComplaintDetails = () => {
        let required8DCM = (createComplaintInputs.required8D === true || createComplaintInputs.required8D === false) ? true : false;
        let required8DDM = (inputs.required8D === true || inputs.required8D === false) ? true : false
        if (!(complaintListData[0]) ? !createComplaintInputs.supplierEmail : !inputs.supplierId) {
            handleClickOpenDialogValidation("Please Fill SupplierEmail !!")
        }
        else if (!(complaintListData[0]) ? !createComplaintInputs.partNumber : !inputs.partId) {
            handleClickOpenDialogValidation("Please Fill PartNumber !!")
        }
        else if ((!(complaintListData[0]) ? createComplaintInputs.detailDescription.length < 5 || createComplaintInputs.detailDescription[0].DetailedDescription === "" ||
            createComplaintInputs.detailDescription[1].DetailedDescription === "" ||
            createComplaintInputs.detailDescription[2].DetailedDescription === "" ||
            createComplaintInputs.detailDescription[3].DetailedDescription === "" ||
            createComplaintInputs.detailDescription[4].DetailedDescription === "" :
            draftInputs.detaildescription.length < 5 || draftInputs.detaildescription[0].detailedDescription === "" ||
            draftInputs.detaildescription[1].detailedDescription === "" ||
            draftInputs.detaildescription[2].detailedDescription === "" ||
            draftInputs.detaildescription[3].detailedDescription === "" ||
            draftInputs.detaildescription[4].detailedDescription === ""
        )
        ) {
            handleClickOpenDialogValidation("Please Fill Descriptions !!")
        }
        else if ((!(complaintListData[0]) ? !createComplaintInputs.severity : !inputs.severityId)) {
            handleClickOpenDialogValidation("Please Fill Severity !!");
        }
        else if (!(complaintListData[0]) ? !createComplaintInputs.issueReportedOn : !inputs.issueReportedOn) {
            handleClickOpenDialogValidation("Please Fill Issue Reported Date !!");
        }
        else if (!(complaintListData[0]) ? !createComplaintInputs.whereitisdetected : !inputs.whereitisdetected) {
            handleClickOpenDialogValidation("Please Fill Where Issue is Detected !!");
        }
        else if (!(complaintListData[0]) ? !createComplaintInputs.priorityId : !inputs.priorityId) {
            handleClickOpenDialogValidation("Please Fill Priority !!");
        }
        else if (!(complaintListData[0]) ? !createComplaintInputs.targetdate : !inputs.targetdate) {
            handleClickOpenDialogValidation("Please Fill Target Date for Closure !!");
        }
        else if (!(complaintListData[0]) ? !createComplaintInputs.frequency : !inputs.frequency) {
            handleClickOpenDialogValidation("Please Fill Frequency !!");
        }
        else if (!(complaintListData[0]) ? !required8DCM : !required8DDM) {
            handleClickOpenDialogValidation("Please Fill 8D Required !!");
        }
        else {
            setIsLoader(true);
            PostComplaintDetails(siteToken.token,
                azureToken.token,
                UID.id,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.role,
                userDetails.roleId,
                 inputs)
                .then((response) => {
                    handleClickOpen(response.responseMsg ? response.responseMsg : "");
                    setIsLoader(false);
                })
                .catch((error) => {
                    handleClickOpen("Exception : " + error, "error");
                    setIsLoader(false);
                });
        }
    };

    const InsertSaveDraft = () => {
        let SaveDraftInputs = complaintListData[0] ? draftInputs : inputs;
        console.log("draftInputs", SaveDraftInputs);
        if (complaintListData[0] ? !(draftInputs.supplierId) : !(inputs.supplierId)) {
            handleClickOpenDialogValidation("Please Fill SupplierEmail !!")
        }
        else {
            setIsLoader(true);
            SaveDraft(siteToken.token,
                azureToken.token,
                UID.id,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.role,
                userDetails.roleId, SaveDraftInputs)
                .then((response) => {
                    handleClickOpen(response.responseMsg);
                    setIsLoader(false);
                })
                .catch((error) => {
                    handleClickOpen("Exception : " + error, "error");
                    setIsLoader(false);
                });
        }
    };
    const AddAcknowlegeStatus = () => {
        let complaintNumber = complaintListData[0] ? complaintListData[0].complaintNumber : ""
        setIsLoader(true);
        AcknowlegeStatus(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, complaintNumber, ackRemarks)
            .then((response) => {
                if (response.response) {
                    setOpenAckDialog(false);
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoader(false);
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });
    }
    const RecallComplaint = () => {
        let complaintNumber = complaintListData[0] ? complaintListData[0].complaintNumber : ""
        setIsLoader(true);
        StatusRecallBySiemens(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, complaintNumber)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoader(false);
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });

    }

    const
        handleReject = () => {
            let complaintNumber = complaintListData[0] ? complaintListData[0].complaintNumber : ""
            if (!remarks) {
                setErrorTexts({
                    remarks: "Required **"
                })
            }
            else {
                setIsLoader(true);
                RejectStatus(siteToken.token,
                    azureToken.token,
                    UID.id,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.role,
                    userDetails.roleId, complaintNumber, remarks)
                    .then((response) => {
                        if (response.response) {
                            handleSnackOpen(response.responseMsg, "success");
                            setIsLoader(false);

                        } else {
                            handleSnackOpen(response.responseMsg, "error");
                            setIsLoader(false);
                        }
                    })
                    .catch((error) => {
                        handleSnackOpen("Exception : " + error, "error");
                        setIsLoader(false);
                    });
            }
        }
    useEffect(() => {
        console.log("cl", complaintListData)
        if (userDetails.roleId !== 3 || complaintListData[0]?.status !== "Draft" || complaintListData.length < 0 || complaintListData[0]?.name) {
            generateComplaintID()
        }
    }, []);
    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);
    const navigate = useNavigate();
    return (
        <>
            <div>
                <Grid className="complaint-root">
                    <Grid item xs={12} className="inner-block">
                        <CreateComplaintDetails
                            createComplaintInputs={createComplaintInputs}
                            setCreateComplaintInputs={setCreateComplaintInputs}
                            complaintDetails={complaintDetails}
                            draftInputs={draftInputs}
                            setDraftInputs={setDraftInputs}
                            rowData={location?.state?.rowData ? location.state.rowData : ""}
                        />
                    </Grid>
                    <Grid item xs={12} className="inner-mb">
                        <NonConformityList
                            createComplaintInputs={createComplaintInputs}
                            setCreateComplaintInputs={setCreateComplaintInputs}
                            complaintDetails={complaintDetails}
                            draftInputs={draftInputs}
                            setDraftInputs={setDraftInputs}
                            rowData={location?.state?.rowData ? location.state.rowData : ""}
                        />
                    </Grid>

                    <Grid item xs={12} className="inner-mb">
                        <Upload
                            createComplaintInputs={createComplaintInputs}
                            setCreateComplaintInputs={setCreateComplaintInputs}
                            complaintDetails={complaintDetails}
                            generateComplaintID={generateComplaintID}
                            draftInputs={draftInputs}
                            setDraftInputs={setDraftInputs}
                            rowData={location?.state?.rowData ? location.state.rowData : ""}
                        />
                    </Grid>
                    <Grid container spacing={3} className="submit-btn">
                        <Grid item>
                            <Button className="pt-button--primary button-submit"
                                onClick={() => {
                                    navigate("/complaintsList")
                                }
                                }
                                variant="contained">
                                Back
                            </Button>
                        </Grid>
                        {userDetails.roleId === 1 || userDetails.roleId === 2 || userDetails.roleId === 6 ?
                            <>
                                <Grid item>
                                    <Button className="pt-button--primary button-submit"
                                        disabled={!(complaintListData[0] === "" || complaintListData[0]?.status === "Draft" || complaintListData[0]?.status === "Recall"
                                            || complaintListData[0]?.status === "Sent back to Siemens"
                                        )}
                                        onClick={InsertComplaintDetails} variant="contained">
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className="pt-button--primary button-submit"
                                        disabled={!(complaintListData[0] === "" || complaintListData[0]?.status === "Draft")}
                                        onClick={InsertSaveDraft} variant="contained">
                                        Save As Draft
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className="pt-button--primary button-submit"
                                        disabled={!(complaintListData[0]?.status === "Pending by Supplier" || complaintListData[0].status === "Submitted")}
                                        onClick={RecallComplaint} variant="contained">
                                        Recall
                                    </Button>
                                </Grid>

                            </>
                            :
                            <>

                                <Grid item>
                                    <Button className="pt-button--primary button-submit" disabled={complaintListData[0].status !== "Submitted"} onClick={handleClickOpenAck} variant="contained">
                                        Acknowledge
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className="pt-button--reject button-submit" disabled={complaintListData[0].status !== "Submitted"} onClick={handleClickOpen} variant="contained">
                                        Reject
                                    </Button>
                                </Grid>


                            </>
                        }
                    </Grid>
                    <Dialog fullWidth open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                        {userDetails.roleId === 1 || userDetails.roleId === 2 || userDetails.roleId == 6 ?
                            <DialogContent>

                                <h3 style={{ textAlign: "center" }}>Complaint Number :<span style={{ background: "var(--btn-hero-bg-hover)" }}>{
                                    complaintListData[0] ?
                                        complaintListData[0]?.name ?
                                            complaintDetails.complaintNumber :
                                            complaintListData[0].complaintNumber :
                                        complaintDetails.complaintNumber}
                                </span></h3>
                                <div style={{ textAlign: "center", padding: "10px" }}>{dialogContent}</div>

                            </DialogContent>
                            :
                            <DialogContent>
                                <h3 style={{ textAlign: "center" }}>Remarks</h3>
                                <Grid>
                                    <TextField
                                        fullWidth
                                        id="standard-multiline-flexible"
                                        placeholder=" Enter Remarks"
                                        variant="outlined"
                                        multiline
                                        inputProps={{
                                            style: {
                                                height: "70px",
                                            },
                                        }}
                                        maxRows={5}
                                        value={remarks}
                                        error={
                                            errorTexts.remarks && errorTexts.remarks.length > 0
                                        }
                                        helperText={errorTexts.remarks}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </DialogContent>
                        }

                        <DialogActions>
                            {userDetails.roleId === 3 ?
                                <Button className="pt-button--primary button-submit" variant="contained" onClick={handleReject} color="primary">
                                    Submit
                                </Button>
                                : ""
                            }
                            {userDetails.roleId === 3 ?
                                <Button className="pt-button--secondary button-submit" variant="contained" onClick={handleCloseDialogRemarks} color="primary">
                                    Cancel
                                </Button>
                                : ""
                            }
                            {userDetails.roleId !== 3 ?
                                <Button className="pt-button--secondary button-submit" variant="contained" onClick={handleCloseDialog} color="primary">
                                    Ok
                                </Button>
                                : ""
                            }
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openValidationDialog}>

                        <DialogContent className="content-center">
                            {dialogErrorText}
                        </DialogContent>
                        <DialogActions>
                            <Button className="pt-button--secondary button-submit" variant="contained" onClick={handleCloseDialogRemarks} color="primary">
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog fullWidth open={openAckDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <h3 style={{ textAlign: "center" }}>Remarks</h3>
                            <Grid>
                                <TextField
                                    fullWidth
                                    id="standard-multiline-flexible"
                                    placeholder=" Enter Remarks"
                                    variant="outlined"
                                    multiline
                                    inputProps={{
                                        style: {
                                            height: "70px",
                                        },
                                    }}
                                    maxRows={5}
                                    value={ackRemarks}
                                    onChange={handleChangeAckRemarks}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button className="pt-button--primary button-submit" variant="contained" onClick={AddAcknowlegeStatus} color="primary">
                                Submit
                            </Button>

                            <Button className="pt-button--secondary button-submit" variant="contained" onClick={handleCloseDialogRemarks} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <SnackbarCustom
                        open={open}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleClose}
                    />
                </Grid>

            </div>
            {isLoader ? <Loading loaderText="Loading...." /> : <></>}
        </>
    );
}