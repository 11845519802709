import axios from "axios";
import { baseApiURL } from "../../Utilities/Utility";
import React, { useState, useEffect } from "react";
import Loader from "../../Components/Loader/Loader";
import FileSaver from "file-saver";
export const baseApiURLS = window.location.origin;

export default function GetCalibCertDetails() {

    const [isLoader, setIsLoader] = useState(false);
    function getSRFParameter() {
        const searchPageURL = window.location.search.substring(21, 31);
        return searchPageURL;
    }
    function getType() {
        const searchPage = window.location.search.substring(50);
        return searchPage;
    }
    var AutomatedIsirnumber = getSRFParameter();
    var Uploadfiletype = getType()
    var ft = parseInt(Uploadfiletype)

    const displayCalibCert = (fileName) => {
        setIsLoader(true);
        const options = {
            responseType: "arraybuffer",
        };
        axios.get(baseApiURLS + "/api/Admin/ISIRdownloadfile?AutomatedIsirnumber=" + AutomatedIsirnumber + "&Uploadfiletype=" + ft, options)
            .then((response) => {
                setIsLoader(false);
                console.log("file", response)
                if (response.status === 200) {
                    console.log("data", response)
                    //let fileNameVar = response.headers["content-disposition"];
                    //var regExp = /filename=(.*?)\;/;
                    //let fileName = regExp.exec(fileNameVar);
                    if (response.data) {
                        var blob = new Blob([response.data], {
                            type: "application/octet-stream",
                        });
                        FileSaver.saveAs(blob, fileName !== "" ? fileName : "sample");
                        setIsLoader(false);
                        alert("File downloaded.");
                    } else {
                        setIsLoader(false);
                        alert("No Records are Found");
                    }
                }
                else {
                    console.log("PDF File Not Found");
                    alert("File Not Found !!");
                }
            })
            .catch((err) => {
                setIsLoader(false);
                alert("Error !!");
            });

    }
    function getFileName() {
        const options = {
            method: "get",
            url: baseApiURL + "/Admin/ISIRdownloadfileName",
         
            params: {
                AutomatedIsirnumber: AutomatedIsirnumber,
                Uploadfiletype: ft
            },
        };
        return (
            axios(options)
                .then((response) => {
                    console.log("Filename", response);
                    let fileName = response.data;
                   
                    if (fileName !== "" && fileName) {
                        displayCalibCert(fileName);
                    }
                    return response.data.result;
                })
                .catch((error) => {
                    console.log("Error : ", error);
                })
        );
    }
    useEffect(() => {
        //displayCalibCert();
        getFileName();
    }, [AutomatedIsirnumber, ft]);

    return (
        <>
            {isLoader ? <Loader loaderText="Fetching Certificate ...." /> : <></>}
        </>
    );
}

