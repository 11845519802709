import React from "react";
import { Link } from "react-router-dom";

export default function Error() {
    return (
        <div className="master-layout-block">
            <h2>This is Error 404. Possibly you landed on unknown URL</h2>
            <h4 className='d-flex'>Please click&nbsp;<Link to="/" className="clr-white" style={{color:"#00bedc"} }>here</Link>&nbsp;to go back to homepage.</h4>
        </div>
    );
}