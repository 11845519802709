import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/breakpoint.scss";
import "./App.css";
import { LoginRoute, ResetPasswordRoute } from "./Routes/LoginRoute";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import {
    authUserAction,
    storeUserDetailsAction,
    saveAccessTokenAction,
    errorMessageAction,
    saveUserIdAction,
} from "../src/Redux/Actions/actions";
import { MasterRoute, SQMEngineerRoute, SupplierRoute, CERoute, RDRoute, SQERoute, SPRRoute, OPRRoute } from "./Routes/MasterRoute";
import Header from "./Components/Header/Header";
import Loader from "./Components/Loader/Loader";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import UnauthorizedAccess from "./Components/UnauthorizedAccess/UnauthorizedAccess";
/*import IdleTimer from "react-idle-timer";*/
import {
    authenticateUserBasedOnValidToken,
    authenticateUserWithMyID,
    generateJwtForAPI,
} from "./Redux/APIs/api_login";
import { validateTheUserBasedOnApi } from "./Redux/APIs/api_login";

import { useMsal } from '@azure/msal-react';
import { useAuth0 } from "@auth0/auth0-react";
export default function App() {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    const allDetails = useAuth0();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const isOTPVerified = useSelector((state) => state.isVerifiedOTPReducer);
    const isForgotPassword = useSelector((state) => state.isForgotPasswordReducer);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [isLoader, setIsLoader] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [unAuthText, setUnAuthText] = useState("");

    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [isAuthorized, setIsAuthorized] = useState(true);

    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDialogOpen(false);
    };
    //function sessionExpire(auth, dispatch) {
    //    var newurl =
    //        window.location.protocol +
    //        "//" +
    //        window.location.host +
    //        window.location.pathname;
    //    window.history.pushState({ path: newurl }, "", `/`);
    //    localStorage.clear();
    //    sessionStorage.clear();
    //    if (auth !== undefined) {
    //        dispatch(storeUserDetailsAction(""));

    //        caches.keys().then((names) => {
    //            names.forEach((name) => {
    //                caches.delete(name);
    //            });
    //        });
    //    }
    //    window.location.href = "/";
    //}
    //function authenticateUserWithGID(gid) {
    //  let data = { gid: gid };
    //  authenticateUserWithMyID(dispatch, token, data)
    //    .then((response) => {
    //      if (response.response) {
    //        setUnAuthText("");
    //        setDialogOpen(false);
    //        setIsLoader(false);
    //      } else {
    //        setIsLoader(false);
    //        setUnAuthText(response.responseMsg);
    //        setDialogOpen(true);
    //      }
    //    })
    //    .catch((error) => {
    //      setIsLoader(false);
    //      setUnAuthText("Exception occured while autheniticating user.");
    //      setDialogOpen(true);
    //    });
    //}
    function btoaSecure(value) {
        const { btoa } = require("abab");
        let currDate = new Date().toDateString();
        let valueModified = value + " " + currDate;
        let valueSecured = btoa(btoa(valueModified));
        return valueSecured;

    }

    const getToken = async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            dispatch(saveAccessTokenAction(accessToken));
            console.log("accessToken", accessToken);
            authenticateUserWithGID(accessToken);
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    };

    function authenticateUserWithGID(accessToken) {
        setIsLoader(true);
        authenticateUserBasedOnValidToken(dispatch, accessToken)
            .then((response) => {
                if (response) {
                    console.log("app", response)
                    //dispatch(saveAccessTokenAction(activeAccount.idToken));
                    setUnAuthText("");
                    setIsLoader(false);
                    setIsAuthorized(false);
                } else {
                    setIsLoader(false);
                    setUnAuthText(response.responseMsg);
                    setIsAuthorized(false);
                    setDialogOpen(true);
                }
            })
            .catch((error) => {
                //setDialogOpen(true);
                setIsLoader(false);
                setUnAuthText("Exception occured while autheniticating user.");
                setDialogOpen(true);
            });
    }

    //function authenticateUserWithGID(gid) {
    //    authenticateUserBasedOnValidToken(dispatch, activeAccount.idToken, gid)
    //        .then((response) => {
    //            if (response) {
    //                console.log("app", response)
    //                // dispatch(saveAccessTokenAction(activeAccount.idToken));
    //                setIsLoader(false);
    //                setIsAuthorized(false);
    //            } else {
    //                setIsLoader(false);
    //                setIsAuthorized(false);
    //                setDialogOpen(true);
    //            }
    //        })
    //        .catch((error) => {
    //            //setDialogOpen(true);
    //            setIsLoader(false);
    //        });
    //}
    //useEffect(() => {
    //  if (auth.user) {
    //    setIsLoader(true);
    //    if (token) {
    //      const arr = auth.user.profile.sub.split("|");
    //      let gid = arr && arr.length ? arr[1] : "";
    //      authenticateUserWithGID(gid);
    //    } else {
    //      generateJwtForAPI(dispatch)
    //        .then((response) => setIsLoader(false))
    //        .catch((error) => setIsLoader(false));
    //    }
    //  }
    //  if (auth.error) {
    //    setDialogOpen(true);
    //  }
    //}, [auth, token, dispatch]);

    //function getUserMetadata() {
    //    const domain = "qa-op.siemens.auth0app.com";

    //    return getAccessTokenSilently({
    //        authorizationParams: {
    //            audience: `https://${domain}/api/v2/`,
    //            scope: "read:current_user",
    //        },
    //    })

    //        console.log("accessToken", accessToken);
    //        //.then(accessToken => {
    //        //    console.log("accessToken", accessToken);
    //        //    const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

    //        //    return fetch(userDetailsByIdUrl, {
    //        //        headers: {
    //        //            Authorization: `Bearer ${accessToken}`,
    //        //        },
    //        //    });
    //        //})
    //        //.then(metadataResponse => metadataResponse.json())
    //        //.then(data => {
    //        //    const { user_metadata } = data;
    //        //    console.log("user_metadata", user_metadata);
    //        //})
    //        //.catch(e => {
    //        //    console.log(e.message);
    //        //});
    //};



    async function getUserMetadata() {
        const domain = "prd-op.siemens.auth0app.com";

        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: `https://${domain}/api/v2/`,
                    scope: "read:current_user",
                },
            });

            console.log("accessToken", accessToken);


        } catch (e) {
            console.log(e.message);
        }
    }



    useEffect(() => {
        if (!isLoading && isAuthenticated && !userDetails.userId) {
            console.log("userdetails", allDetails);
            console.log(user);


            getToken();
            /* getUserMetadata();*/
            console.log("token", getAccessTokenSilently);
            setIsLoader(true);
            //dispatch(saveAccessTokenAction(activeAccount.idToken));
            //dispatch(saveAzureTokenAction(activeAccount.idToken));
            /* authenticateUserWithGID();*/
        }
        else {

            setIsLoader(false);

        }


    }, [isLoading, isAuthenticated, getAccessTokenSilently, user?.sub]);

    return (
        <BrowserRouter>
            {userDetails && userDetails.roleId > 0 ? (
                <>
                    <div className="ssiat-master-block">
                        <Header>Header Component</Header>
                        <main className="main-component">{userDetails.roleId === 1 ? MasterRoute : userDetails.roleId === 2 ? SQMEngineerRoute : userDetails.roleId === 3 ? SupplierRoute : userDetails.roleId === 6 ? CERoute : userDetails.roleId === 7 ? RDRoute : userDetails.roleId === 8 ? SQERoute : userDetails.roleId === 9 ? SPRRoute : userDetails.roleId === 10 ? OPRRoute : <Loader />}</main>
                    </div>
                </>
            ) : (
                LoginRoute
            )

            }

            {/*<div>*/}
            {/*    <IdleTimer*/}
            {/*        timeout={1000 * 60 * 15}*/}
            {/*        onIdle={sessionExpire}*/}
            {/*        debounce={250}*/}
            {/*    />*/}
            {/*</div>*/}
            <>
                <Dialog
                    disableEscapeKeyDown
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Authentication Error"}
                    </DialogTitle>
                    <DialogContent>
                        <UnauthorizedAccess
                        /*errorText={*/
                        /*    error ? "MyID Error :" + error?.stack : unAuthText*/
                        /*}*/
                        />
                    </DialogContent>
                </Dialog>
            </>
            {isLoader === true ? <Loader /> : <></>}
        </BrowserRouter>
    );
}
