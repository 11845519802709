import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";

export function getRoleDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
  const options = {
    method: "get",
    url: baseApiURL + "/Common/GetRolesDDL",
    headers: {
        Authorization: "Bearer " + siteToken,
        AzureToken: azureToken,
        UserEmail: userEmail,
        RoleName: roleName,
        Gid: gid,
        Roleid: roleId,
        userId: userId,
    },
   /* params: { userId: userId },*/
  };
  return (
    axios(options)
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      })
  );
}
export function getSupplierDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
  const options = {
    method: "get",
    url: baseApiURL + "/Common/GetSupplierDDL",
    headers: {
        Authorization: "Bearer " + siteToken,
        AzureToken: azureToken,
        UserEmail: userEmail,
        RoleName: roleName,
        Gid: gid,
        Roleid: roleId,
        userId: userId,
    },
    //params: { userId: userId },
  };
  return (
    axios(options)
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      })
  );
}

export function GetSupplierEmailDDl(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
      method: "get",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
    };
    return fetch(
      baseApiURL + "/Common/GetSupplierEmailDDL",
      options
    )
      .then((response) => response.json())
        .then((data) => {
            console.log("email objects", data.result);
        return data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }
export function GetPartDDl(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId){
  const options = {
    method: "get",
      headers: {
          Authorization: "Bearer " + siteToken,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId,
          userId: userId,
      },

  };
  return fetch(
    baseApiURL + "/Common/GetPartsDDL",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function GetSeverityDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId){
    const options={
        method:"get",
        url:baseApiURL+"/Common/GetSeverityDDL",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
       /* params:{userId:userId},*/
    };
     return(
         axios(options)
         .then((response)=>{
             return response.data.result;
         })
         .catch((error)=>{
             console.log("Error :",error);
         })
     );
}

export function GetDetectedDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
      method: "get",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
 },
    };
    return fetch(baseApiURL + "/Common/GetDetectedDDL", options)
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }

export function GetPriorityDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
      method: "get",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId, },
    };
    return fetch(baseApiURL + "/Common/GetPriorityDDL", options)
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }
export function getTeamRoleDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Common/GetTeamRoleDDL",
      headers: {
          Authorization: "Bearer " + siteToken,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId,
          userId: userId,

      },
      /*params: { userId: userId },*/
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
export function getStockDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Common/GetStockdetailsDDL", 
      headers: {
          Authorization: "Bearer " + siteToken,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId,
          userId: userId,
      },
     /* params: { userId: userId },*/
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
export function getActionOwnerDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,cn) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetD3DDL",
      headers: {
          Authorization: "Bearer " + siteToken,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId,
          userId: userId,
      },
        /*params: { userId: userId, complaintNumber: cn },*/
        params: { complaintNumber: cn },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
export function getStatusDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Common/GetStatusDDL",
      headers: {
          Authorization: "Bearer " + siteToken,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId,
          userId: userId,
      },
     /* params: { userId: userId },*/
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
export function getD1WhoDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,cn) {
   
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetD1DDL",
      headers: {
          Authorization: "Bearer " + siteToken,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId,
          userId: userId,
      },
        /* params: { userId: userId,complaintNumber:cn },*/
         params: { complaintNumber:cn },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function GetApprovedDDL(siteToken,userId){
    const options={
        method:"get",
        url:baseApiURL+"/Common/GetApprovedDDL",
        headers:{Authorization:"Bearer "+siteToken,
        },
        params:{userId:userId},
    };
     return(
         axios(options)
         .then((response)=>{
             return response.data.result;
         })
         .catch((error)=>{
             console.log("Error :",error);
         })
     );
}
export function GetSupplierMasterDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId){
  const options={
      method:"get",
      url:baseApiURL+"/Admin/GetSupplierMasterDDL",
      headers: {
          Authorization: "Bearer " + siteToken,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId,
          userId: userId,

      },
      /*params:{userId:userId},*/
  };
   return(
       axios(options)
       .then((response)=>{
           return response.data.result;
       })
       .catch((error)=>{
           console.log("Error :",error);
       })
   );
}
export function GetStatusMasterDDL(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId){
  const options={
      method:"get",
      url:baseApiURL+"/Admin/GetComplaintStatusDDL",
      headers: {
          Authorization: "Bearer " + siteToken,
          AzureToken: azureToken,
          UserEmail: userEmail,
          RoleName: roleName,
          Gid: gid,
          Roleid: roleId,
          userId: userId,


      },
   /*   params:{userId:userId},*/
  };
   return(
       axios(options)
       .then((response)=>{
           return response.data.result;
       })
       .catch((error)=>{
           console.log("Error :",error);
       })
   );
}
export function getRole(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,roleuserid) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetRolesMapperDetails",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        /* params: { userId: userId ,roleuserid:roleuserid},*/
         params: { roleuserid:roleuserid},
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}

export function getAllRoles(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },

    };
    return fetch(baseApiURL + "/Common/GetLandingMenuData",
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}