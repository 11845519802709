import React, { useState, useEffect, useRef } from "react";
import { Grid, MenuItem, TextField, Button, Tooltip } from "@material-ui/core";
//import "./AddSir.scss";
//import Select, { SelectChangeEvent } from '@mui/material/Select';
//import 'date-fns';
//import DateFnsUtils from '@date-io/date-fns';
import * as FileSaver from 'file-saver';
import axios from "axios";

import InputLabel from "@material-ui/core/InputLabel";
import { baseApiURL, baseURL } from "../../Utilities/Utility";
import FormControl from "@material-ui/core/FormControl";
import "./Addisir.scss";

import { BootstrapInput, getTodayDate, getTodayDateMoment } from "../../Utilities/Utility";
import { useLocation } from "react-router-dom";

import Select from '@material-ui/core/Select';
//import DialogActions from "@material-ui/core/DialogActions";
//import Dialog from '@material-ui/core/Dialog';
//import DialogContent from '@material-ui/core/DialogContent
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { downloadFile, getAllIsirDetails, getAutomatedWorkFlowNumber, getDevelopment, getGroupcategory, getRDLocation, getrolesForSupplier, getSeverity, getStatus, getSupplierEmail, getSupplierName, getuploadedDatainDownLoadFile, SaveasDraft, submit, uploadTemplateFileData, uploadTemplateFileDataTab2, addPOTextDetails, getSqe, getSpr, getOpr, addClosureDate, getPOText, getCheckboxesDetails, getRdUsers, getWholeRDemailId, getMasterAttachmentApiData } from "../../Redux/APIs/api_view";
//import uploadTemplateFileDataTab2





import moment from "moment";
import Loading from "../../Components/Loader/Loader"
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
//import Customer_Template from "../../Common/CustomerMaster Template.xlsx";
import Isir_template from "./../../Common/NewFolder/Isir_template.xlsx";




//import SnackbarCustom from "../../../Components/Snackbar/Snackbar";


import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
//this is new tab creation data
//import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MaterialTable from "material-table";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { authenticateUserWithMyID } from "../../Redux/APIs/api_login";
import AddIsirRoot from "./AddIsirRoot";
import { uploadFileValidationAction, is3DUploadedAction, isIsirPdfuploadedAction, tfcAction, dfmAction, trmAction, ppapAction, iipAction, poTextAction, draftTfcAction, draftTrmAction, draftDfmAction, draftPpapAction, hideTb1, drawingUpload, threeDUpload, isirUpload, ppapxlsmAction, incomingQualityReportAction, componentApprovalFormAction, closeBtnAction, afterClosingIsirAction, justCloseBtnPress } from "../../Redux/Actions/actions";
import Remark from "./remark";



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginTop: `15px`,
        //paddingTop: `65px`,
    },
}));

const PartOne = ({ isirNumberPassed, groupData, prevDataAdd, developmentData }) => {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const fileUploadDetails = useSelector(
        (state) => state.uploadFileValidationReducer.files
    );
    const tfcActiondataState = useSelector((state) => state.istfcCheckedorNotReducer.tfcCheckedValue)

    const trmActionDataState = useSelector((state) => state.istrmCheckedorNotReducer.trmCheckedValue)

    const ppapActionDataState = useSelector((state) => state.isppapCheckedorNotReducer.ppapCheckedValue)

    const dfmActionDataState = useSelector((state) => state.isDfmCheckedorNotReducer.dfmCheckedValue)
    const hideTabData2ActionState = useSelector((state) => state.hideTabPart2Reducer.hidetb2Value);

    const uploadDrawingActionState = useSelector((state) => state.drawingUploadReducer.drawingUploadVlaue);

    const upload3DActionState = useSelector((state) => state.threeDUploadReducer.threeDUploadValue);

    const uploadIsirActionState = useSelector((state) => state.isirUploadReducer.isirUploadValue);
    const afterClosingIsirActionDataState = useSelector((state) => state.afterClosingIsirReducer.afterClosingIsirValue);
    console.log("afterClosingIsirActionDataState", afterClosingIsirActionDataState);
    //const justCloseBtnPressActionDataState = useSelector((state) => state.justClosebtnPressReducer.justClosebtnPressValue);
    //console.log("justCloseBtnPressActionDataState", justCloseBtnPressActionDataState);

    const dispatch = useDispatch();
    const classes = useStyles();
    const [file, setFile] = useState();
    const [inputFileValueASMTDoc, setInputFileValueASMTDoc] = useState(Date.now);

    const [drawingpdf, setDrawingPDF] = useState(Date.now);
    const [threeDpdf, setThreeDpdf] = useState("");
    const [toolspecificationPdf, setToolSpecificationPdf] = useState("");

    //new one after writing function
    const [fileData, setFileData] = useState();
    const [selectedFileDept, setSelectedFileDept] = useState(null);//firstfileupload
    const [drawingpdfKey, setDrawingpdf] = useState(Date.now);
    const [selectedFileSecond, setSelectedFileSecond] = useState(null);//secondfileupload
    const [secondFileKey, setSecondFileKey] = useState(Date.now);
    const [selectedFileThird, setSelectedFileThird] = useState(null);//secondfileupload
    const [thirdFileKey, setThirdFileKey] = useState(Date.now);
    const [selectedFileFourth, setSelectedFileFourth] = useState(null);//secondfileupload
    const [fourthFileKey, setFourthdFileKey] = useState(Date.now);
    const [selectedFileFifth, setSelectedFileFifth] = useState(null);//secondfileupload
    const [fifthFileKey, setFifthdFileKey] = useState(Date.now);
    const [selectedFileSix, setSelectedFileSix] = useState(null);//secondfileupload
    const [sixFileKey, setSixFileKey] = useState(Date.now);
    const [selectedFileSeven, setSelectedFileSeven] = useState(null);//secondfileupload
    const [sevenFileKey, setSevenxFileKey] = useState(Date.now);
    const [selectedFil122dfm, setSelectedFil122dfm] = useState(null);//secondfileupload  
    const [dfm22FileKey, setDfm22FileKey] = useState(Date.now);

    //setSelectedFileSecond
    //for supplier tab1 dwonload uploadtype


    //states for downloadocx
    const [uploadedData, setuploadData] = useState();
    const [downloadDocxFirst, setDownloadDocxFirst] = useState(""); ///downloadFileData[0].fileurl
    const [downloadDocxSecond, setDownloadDocxSecond] = useState("");
    const [downloadDocxThird, setDownloadDocxThird] = useState("");
    const [downloadDocxFourth, setDownloadDocxFourth] = useState("");

    const [downloadFileData, setDownloadFileData] = useState([]);
    const [attachmentResponse, setAttachmentRespence] = useState();

    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [fileUrl, setFileUrl] = useState("");

    //helperTextfor uploaded files
    const [firstNameHelperText, setFirstNameHelperText] = useState("");

    const downloadDocxFirstHandle = (e) => {
        setDownloadDocxFirst(downloadFileData[0].fileUrl);
    }
    const downloadDocxSecondHandle = (e) => {
        setDownloadDocxSecond(downloadFileData[1].fileUrl);
    }
    const downloadDocxThirdHandle = (e) => {
        setDownloadDocxThird(downloadFileData[2].fileUrl);
    }
    const downloadDocxFourthHandle = (e) => {
        setDownloadDocxFourth(downloadFileData[3].fileUrl);
    }
    const downloadDocxFifthHandle = (e) => {
        setDownloadDocxFifth(downloadFileData[4].fileUrl);
    }
    const downloadDocxSixHandle = (e) => {
        setDownloadDocxSix(downloadFileData[5].fileUrl);
    }
    const downloadDocxSevenHandle = (e) => {
        setDownloadDocxSeven(downloadFileData[6].fileUrl);
    }
    const [openDialog, setOpenDialog] = React.useState(false);
    const [snapshotFile, setSnapshotFile] = useState(null);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClickOpen = () => {
        setOpenDialog(true);
    };
    //const [uploadImageData, setUploadImageData] = useState("");
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    let [inputFileValue, setInputFileValue] = useState(Date.now);
    const [isLoader, setIsLoader] = useState(false);
    const handleClose = (e) => {
        setOpen(false);
    };
    const [isLoaderFeedback, setIsLoaderFeedback] = useState(false);
    //const handleCloseDialog = () => {
    // setOpenDialog(false);
    // };

    //azure ad
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const azureToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    //see role data
    const [roleBasedData, setRoleBasedData] = useState([]);

    var columns = [
        {
            title: "ISIR ID.",
            field: "isirNumber",
            ender: (rowData) => rowData.automatedIsirnumber,
            //render: (rowData) => navigateToComplaintsPage(rowData),
        },

        {
            title: "file Name",
            field: "filename",
            render: (rowData) => rowData.fileName,
        },
        {
            title: "fileurl",
            field: "fileurl",
            render: (rowData) => rowData.fileUrl,
        },
        {
            title: "filetype",
            field: "fileurl",
            render: (rowData) => rowData.filetype,
        },
        {
            title: "attachmentid",
            field: "attachmentid",
            render: (rowData) => rowData.isirattachmentId,
        },
        {
            title: "Release Date",
            field: "targetdate",
            //render: (rowData) => <span>{renderDate(rowData.targetdate)}</span>
        },

        {
            title: "Status",
            field: "status",
        },
    ]



    const [getCheckValues, setGetCheckValues] = useState();
    function getMasterCheckboxD() {
        getCheckboxesDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                console.log("masterChckbox", response);
                setGetCheckValues(response);
                let MasterAttachment = response;


            })
            .catch((error) => {
                console.log(error);

            });
    };
    //DownloadFilemaster1


    useEffect(() => {
        getMasterCheckboxD();
    }, [])
    //filename from getapi
    const [fileFour, setFileFour] = useState();
    const [fileFive, setFileFive] = useState();
    const [fileSix, setFileSix] = useState();

    const [fileSeven, setFileSeven] = useState();
    const [file22, setFile22] = useState();
    ///downloadable files url
    const [downloadDocxFifth, setDownloadDocxFifth] = useState("");
    const [downloadDocxSix, setDownloadDocxSix] = useState("");
    const [downloadDocxSeven, setDownloadDocxSeven] = useState("");
    const [downLoadDocx22dfm, setDownloadDocx22dfm] = useState("");
    function DownloadFilemaster1() {
        console.log("sinya");
    }
    const [masterType1, setMasterType1] = useState();
    const [masterType2, setMasterType2] = useState();
    const [masterType3, setMasterType3] = useState();
    const [masterType4, setMasterType4] = useState();
    const [masterType5, setMasterType5] = useState();

    useEffect(() => {
        dispatch(isIsirPdfuploadedAction(false));
        if (getCheckValues != undefined && getCheckValues.length > 0) {
            getCheckValues.map((item) => {
                switch (item.masterType) {
                    case 5:
                        console.log("masterId5", item.masterType);
                        setMasterType5(item.masterType);
                        setDownloadDocxFourth(item.fileUrlWithSasToken);
                        setFileFour(item.filename);

                        dispatch(isIsirPdfuploadedAction(true));
                        break;
                    case 1:
                        console.log("masterId1", item.masterType);
                        setMasterType1(item.masterType);
                        setDownloadDocxFifth(item.fileUrlWithSasToken);
                        setFileFive(item.filename);
                        break;
                    case 3:
                        console.log("masterId3", item.masterType);
                        setMasterType3(item.masterType);
                        setDownloadDocxSix(item.fileUrlWithSasToken);
                        setFileSix(item.filename)
                        break;
                    case 4:
                        console.log("masterId4", item.masterType);
                        setMasterType4(item.masterType);
                        setDownloadDocxSeven(item.fileUrlWithSasToken);
                        setFileSeven(item.filename)
                        break;

                    case 2:
                        console.log("masterId2", item.masterType);
                        setMasterType2(item.masterType);
                        setDownloadDocx22dfm(item.fileUrlWithSasToken);
                        setFile22(item.filename)
                        break;
                    default:

                }
            })
        }

    }, [getCheckValues]);

    //DownloadFilemaster1
    // getMasterAttachmentApiData
    function getMasterFileName1() {
        const options = {
            method: "get",
            url: baseApiURL + "/Admin/MasterAttachmentFileName",

            params: {
                masterid: masterType1,
                userId: userDetails.userId
            },
        };
        return (
            axios(options)
                .then((response) => {
                    console.log("Filename", response);
                    let fileName = response.data;

                    if (fileName !== "" && fileName) {
                        getMastersFileData1(fileName);
                    }
                    return response.data.result;
                })
                .catch((error) => {
                    console.log("Error : ", error);
                })
        );
    }
    function getMastersFileData1(fileName) {
        setIsLoader(true);
        const options = {
            responseType: "arraybuffer",
        };
        axios.get(baseURL + "/api/Admin/GetMasterAttachmentsBasedOnId?masterid=" + masterType1 + "&userId=" + userDetails.userId, options)
            .then((response) => {
                setIsLoader(false);
                console.log("file", response)
                if (response.status === 200) {
                    console.log("data", response)
                    //let fileNameVar = response.headers["content-disposition"];
                    //var regExp = /filename=(.*?)\;/;
                    //let fileName = regExp.exec(fileNameVar);
                    if (response.data) {
                        var blob = new Blob([response.data], {
                            type: "application/octet-stream",
                        });
                        FileSaver.saveAs(blob, fileName !== null ? fileName : "sample");
                        setIsLoader(false);
                        alert("File downloaded.");
                    } else {
                        setIsLoader(false);
                        alert("No Records are Found");
                    }
                }
                else {
                    console.log("PDF File Not Found");
                    alert("File Not Found !!");
                }
            })
            .catch((err) => {
                setIsLoader(false);
                alert("Error !!");
            });



        //getMasterAttachmentApiData(masterType1, userDetails.userId)
        //    .then((response) => {
        //       console.log("masterFiles", response)

        //        //setRdGetRDemail(response);

        //    })
        //   .catch((error) => {
        //        console.log(error);

        //   });
    };
    function getMasterFileName2() {
        const options = {
            method: "get",
            url: baseApiURL + "/Admin/MasterAttachmentFileName",

            params: {
                masterid: masterType2,
                userId: userDetails.userId
            },
        };
        return (
            axios(options)
                .then((response) => {
                    console.log("Filename", response);
                    let fileName = response.data;

                    if (fileName !== "" && fileName) {
                        getMastersFileData2(fileName);
                    }
                    return response.data.result;
                })
                .catch((error) => {
                    console.log("Error : ", error);
                })
        );
    }
    function getMastersFileData2(filename) {
        setIsLoader(true);
        const options = {
            responseType: "arraybuffer",
        };
        axios.get(baseURL + "/api/Admin/GetMasterAttachmentsBasedOnId?masterid=" + masterType2 + "&userId=" + userDetails.userId, options)
            .then((response) => {
                setIsLoader(false);
                console.log("file", response)
                if (response.status === 200) {
                    console.log("data", response)
                    //let fileNameVar = response.headers["content-disposition"];
                    //var regExp = /filename=(.*?)\;/;
                    //let fileName = regExp.exec(fileNameVar);
                    if (response.data) {
                        var blob = new Blob([response.data], {
                            type: "application/octet-stream",
                        });
                        FileSaver.saveAs(blob, filename !== null ? filename : "sample");
                        setIsLoader(false);
                        alert("File downloaded.");
                    } else {
                        setIsLoader(false);
                        alert("No Records are Found");
                    }
                }
                else {
                    console.log("PDF File Not Found");
                    alert("File Not Found !!");
                }
            })
            .catch((err) => {
                setIsLoader(false);
                alert("Error !!");
            });



        //getMasterAttachmentApiData(masterType1, userDetails.userId)
        //    .then((response) => {
        //       console.log("masterFiles", response)

        //        //setRdGetRDemail(response);

        //    })
        //   .catch((error) => {
        //        console.log(error);

        //   });
    };
    function getMasterFileName3() {
        const options = {
            method: "get",
            url: baseApiURL + "/Admin/MasterAttachmentFileName",

            params: {
                masterid: masterType3,
                userId: userDetails.userId
            },
        };
        return (
            axios(options)
                .then((response) => {
                    console.log("Filename", response);
                    let fileName = response.data;

                    if (fileName !== "" && fileName) {
                        getMastersFileData3(fileName);
                    }
                    return response.data.result;
                })
                .catch((error) => {
                    console.log("Error : ", error);
                })
        );
    }
    function getMastersFileData3(filename) {
        setIsLoader(true);
        const options = {
            responseType: "arraybuffer",
        };
        axios.get(baseURL + "/api/Admin/GetMasterAttachmentsBasedOnId?masterid=" + masterType3 + "&userId=" + userDetails.userId, options)
            .then((response) => {
                setIsLoader(false);
                console.log("file", response)
                if (response.status === 200) {
                    console.log("data", response)
                    //let fileNameVar = response.headers["content-disposition"];
                    //var regExp = /filename=(.*?)\;/;
                    //let fileName = regExp.exec(fileNameVar);
                    if (response.data) {
                        var blob = new Blob([response.data], {
                            type: "application/octet-stream",
                        });
                        FileSaver.saveAs(blob, filename !== null ? filename : "sample");
                        setIsLoader(false);
                        alert("File downloaded.");
                    } else {
                        setIsLoader(false);
                        alert("No Records are Found");
                    }
                }
                else {
                    console.log("PDF File Not Found");
                    alert("File Not Found !!");
                }
            })
            .catch((err) => {
                setIsLoader(false);
                alert("Error !!");
            });



        //getMasterAttachmentApiData(masterType1, userDetails.userId)
        //    .then((response) => {
        //       console.log("masterFiles", response)

        //        //setRdGetRDemail(response);

        //    })
        //   .catch((error) => {
        //        console.log(error);

        //   });
    };
    function getMasterFileName4() {
        const options = {
            method: "get",
            url: baseApiURL + "/Admin/MasterAttachmentFileName",

            params: {
                masterid: masterType4,
                userId: userDetails.userId
            },
        };
        return (
            axios(options)
                .then((response) => {
                    console.log("Filename", response);
                    let fileName = response.data;

                    if (fileName !== "" && fileName) {
                        getMastersFileData4(fileName);
                    }
                    return response.data.result;
                })
                .catch((error) => {
                    console.log("Error : ", error);
                })
        );
    }
    function getMastersFileData4(filename) {
        setIsLoader(true);
        const options = {
            responseType: "arraybuffer",
        };
        axios.get(baseURL + "/api/Admin/GetMasterAttachmentsBasedOnId?masterid=" + masterType4 + "&userId=" + userDetails.userId, options)
            .then((response) => {
                setIsLoader(false);
                console.log("file", response)
                if (response.status === 200) {
                    console.log("data", response)
                    //let fileNameVar = response.headers["content-disposition"];
                    //var regExp = /filename=(.*?)\;/;
                    //let fileName = regExp.exec(fileNameVar);
                    if (response.data) {
                        var blob = new Blob([response.data], {
                            type: "application/octet-stream",
                        });
                        FileSaver.saveAs(blob, filename !== null ? filename : "sample");
                        setIsLoader(false);
                        alert("File downloaded.");
                    } else {
                        setIsLoader(false);
                        alert("No Records are Found");
                    }
                }
                else {
                    console.log("PDF File Not Found");
                    alert("File Not Found !!");
                }
            })
            .catch((err) => {
                setIsLoader(false);
                alert("Error !!");
            });



        //getMasterAttachmentApiData(masterType1, userDetails.userId)
        //    .then((response) => {
        //       console.log("masterFiles", response)

        //        //setRdGetRDemail(response);

        //    })
        //   .catch((error) => {
        //        console.log(error);

        //   });
    };
    function getMasterFileName5() {
        const options = {
            method: "get",
            url: baseApiURL + "/Admin/MasterAttachmentFileName",

            params: {
                masterid: masterType5,
                userId: userDetails.userId
            },
        };
        return (
            axios(options)
                .then((response) => {
                    console.log("Filename", response);
                    let fileName = response.data;

                    if (fileName !== "" && fileName) {
                        getMastersFileData5(fileName);
                    }
                    return response.data.result;
                })
                .catch((error) => {
                    console.log("Error : ", error);
                })
        );
    }
    function getMastersFileData5(filename) {
        setIsLoader(true);
        const options = {
            responseType: "arraybuffer",
        };
        axios.get(baseURL + "/api/Admin/GetMasterAttachmentsBasedOnId?masterid=" + masterType5 + "&userId=" + userDetails.userId, options)
            .then((response) => {
                setIsLoader(false);
                console.log("file", response)
                if (response.status === 200) {
                    console.log("data", response)
                    //let fileNameVar = response.headers["content-disposition"];
                    //var regExp = /filename=(.*?)\;/;
                    //let fileName = regExp.exec(fileNameVar);
                    if (response.data) {
                        var blob = new Blob([response.data], {
                            type: "application/octet-stream",
                        });
                        FileSaver.saveAs(blob, filename !== null ? filename : "sample");
                        setIsLoader(false);
                        alert("File downloaded.");
                    } else {
                        setIsLoader(false);
                        alert("No Records are Found");
                    }
                }
                else {
                    console.log("PDF File Not Found");
                    alert("File Not Found !!");
                }
            })
            .catch((err) => {
                setIsLoader(false);
                alert("Error !!");
            });



        //getMasterAttachmentApiData(masterType1, userDetails.userId)
        //    .then((response) => {
        //       console.log("masterFiles", response)

        //        //setRdGetRDemail(response);

        //    })
        //   .catch((error) => {
        //        console.log(error);

        //   });
    };

    //const [color, setColor] = useState("grey");
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const handleChangeFileASMT = (e) => {
        setDrawingPDF(e.target.value);
    };

    const [tfcdres, setTCFdres] = useState(false);//initially it was "" in useState("")
    const [trmdres, setTRMdres] = useState(false);
    const [ppapdres, setPPAPdres] = useState(false);
    const [dfmdres, setDFMdres] = useState(false);

    const [getisirDetailsData, setGetisirDetailsData] = useState([]);
    function getDetailsForIsir() {
        setIsLoader(true);

        getAllIsirDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {

                setGetisirDetailsData(response);
                let particularUserDraft = response;
                let getRes = particularUserDraft.filter((item) => item.automatedIsirnumber == prevDataAdd.automatedIsirnumber);


                if (getRes.length === 1) {

                    let tfcDraftgetRes = getRes.map((x) => (x.tfmcheckbox));

                    setTCFdres(tfcDraftgetRes[0]);
                    let ppapDraftgetRes = getRes.map((x) => (x.ppapcheckbox));
                    setPPAPdres(ppapDraftgetRes[0]);
                    let trmDraftgetRes = getRes.map((x) => (x.trmcheckbox));

                    setTRMdres(trmDraftgetRes[0]);
                    let dfmDraftgetRes = getRes.map((x) => (x.dfmcheckbox));
                    setDFMdres(dfmDraftgetRes[0]);
                }


                setIsLoader(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoader(false);


            });

    };
    const [tfcPrevD, setTfcPrevD] = useState();


    useEffect(() => {
        getDetailsForIsir();
    }, []);


    const [trmChecked, setTrmChecked] = useState(true);
    const [ppapChecked, setppapChecked] = useState(true);
    const [tfcChecked, setTfcChecked] = useState(true);
    const [dfmChecked, setDfmChecked] = useState(true);

    const handleDfmChecked = (e) => {

        setDfmChecked(e.target.checked);
        dispatch(dfmAction(e.target.checked));
    }
    const handleTfcChecked = (e) => {

        setTfcChecked(e.target.checked);

        dispatch(tfcAction(e.target.checked));

    }

    const handlePpapChecked = (e) => {

        setppapChecked(e.target.checked);
        dispatch(ppapAction(e.target.checked));

    }
    const handleTrmChecked = (e) => {

        setTrmChecked(e.target.checked);
        dispatch(trmAction(e.target.checked));

    }



    const handlefirstfileUpload = (e) => {
        setSelectedFileDept(e.target.files[0]);

    };
    const handleSecondFileupload = (e) => {
        setSelectedFileSecond(e.target.files[0]);
    }
    const handleChangeFileThird = (e) => {
        setSelectedFileThird(e.target.files[0]);
    }
    const handleChangeFileFourth = (e) => {
        setSelectedFileFourth(e.target.files[0]);

    }
    const handleChangeFileFifth = (e) => {
        setSelectedFileFifth(e.target.files[0]);

    }
    const handleChangeFileSix = (e) => {
        setSelectedFileSix(e.target.files[0]);

    }
    const handleChangeFileSeven = (e) => {
        setSelectedFileSeven(e.target.files[0]);

    }
    const handleFile22Dfm = (e) => {
        setSelectedFil122dfm(e.target.files[0]);

    }

    const [getAttchRes, segetAttchRest] = useState([]);

    const handleUploadFileData = (e) => {

        console.log("isirnumfromupload", isirNumberPassed);

        let tabData1 = new FormData();

        tabData1.append("userId", userDetails.userId);
        tabData1.append("AutomatedISIRNumber", isirNumberPassed);
        tabData1.append("fileDrawings", selectedFileDept);

        tabData1.append("file3DStp", selectedFileSecond);
        tabData1.append("fileToolSpecification", selectedFileThird);
        tabData1.append("fileISIRTemplate", selectedFileFourth);
        tabData1.append("fileTFM", selectedFileFifth);
        tabData1.append("fileTRM", selectedFileSix);
        tabData1.append("filePPAP", selectedFileSeven);
        tabData1.append("fileDFM", selectedFil122dfm);


        setIsLoaderFeedback(true);

        uploadTemplateFileData(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
            , tabData1)
            .then((response) => {
                console.log("res", response);
                if (response.response) {
                    const resattch = response.responseAttachments;
                    segetAttchRest(resattch);
                    resattch.map((item) => {
                        switch (item.uploadfiletype) {
                            case 1:

                                //setDownloadDocxFirst(item.fileUrl);
                                //dispatch(uploadFileValidationAction(true));
                                // console.log("upload1", item.uploadfiletype)
                                //setUploadTypeValue1(item.uploadfiletype)
                                setFileOne(item.fileName)
                                break;
                            case 2:

                                //setDownloadDocxFirst(item.fileUrl);
                                //dispatch(uploadFileValidationAction(true));
                                setFileTwo(item.fileName)
                                break;
                            case 3:
                                setFileThree(item.fileName);
                                //setDownloadDocxFirst(item.fileUrl);
                                //dispatch(uploadFileValidationAction(true));
                                break;
                            default:
                        }

                    })


                    setIsLoaderFeedback(false);

                    handleSnackOpen(response.responseMsg, "success");
                    dispatch(hideTb1(true));



                } else {
                    setIsLoaderFeedback(false);
                    handleSnackOpen(response.responseMsg, "error");
                }
                setAttachmentRespence(response.responseAttachments);

                let attachmentResp = response.responseAttachments;
                console.log("attachmentResp1", attachmentResp);
                if (attachmentResp != undefined && attachmentResp.length > 0) {
                    attachmentResp.map((item) => {

                        switch (item.uploadfiletype) {
                            case 1:
                                console.log("uploadtab1", item.uploadfiletype)
                                setDownloadDocxFirst(item.fileUrl);
                                dispatch(uploadFileValidationAction(true));
                                break;
                            case 2:
                                setDownloadDocxSecond(item.fileUrl);
                                dispatch(is3DUploadedAction(true));
                                break;
                            case 3:
                                setDownloadDocxThird(item.fileUrl);
                                break;
                            default:

                        }
                    });
                }

            })

    }
    const [uploadTypeValue1, setUploadTypeValue1] = useState();
    const [uploadTypeValue2, setUploadTypeValue2] = useState();
    const [uploadTypeValue3, setUploadTypeValue3] = useState();

    function handleDownloadTb1Data(isirNumberPassed) {

        getuploadedDatainDownLoadFile(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, isirNumberPassed)
            .then((response) => {
                console.log("uploadType", response);
                setDownloadFileData(response);
                let attachementDatatab1 = response;
                if (attachementDatatab1 != undefined && attachementDatatab1.length > 0) {
                    attachementDatatab1.map((item) => {
                        switch (item.uploadfiletype) {
                            case 1:
                                console.log("upload1", item.uploadfiletype)
                                setUploadTypeValue1(item.uploadfiletype);
                                //setDownload1Docx(item.fileUrl);
                                break;
                            case 2:
                                console.log("upload2", item.uploadfiletype)
                                setUploadTypeValue2(item.uploadfiletype)
                                // setDownload2Docx(item.fileUrl);
                                break;
                            case 3:
                                setUploadTypeValue3(item.uploadfiletype)
                                console.log("upload3", item.uploadfiletype)
                                // setDownload3Docx(item.fileUrl);
                                break;
                            default:
                                break;

                        }
                    })
                }

            })
            .catch((error) => {
                console.log(error);

            });
    }
    function downloadBallon1() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue1,
        }


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue1, '_blank')

    }
    function downloadBallontb1sec() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue2,
        }


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue2, '_blank')

    }
    function downloadBallontb1third() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue3,
        }


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue3, '_blank')

    }
    const [masterData, setMasterData] = useState([]);
    //getMasterAttachmentApiData
    //function getMastersFileData() {

    //    getMasterAttachmentApiData(masterId, userDetails.userId)
    //        .then((response) => {
    //            console.log("masterFiles", response)

    //            //setRdGetRDemail(response);

    //        })
    //        .catch((error) => {
    //            console.log(error);

    //        });
    //};


    useEffect(() => {

        if (prevDataAdd) {
            handleDownloadTb1Data(prevDataAdd.automatedIsirnumber);
        }
        else {
            handleDownloadTb1Data(isirNumberPassed);
        }
    }, [])


    const [fileOne, setFileOne] = useState();
    const [fileTwo, setFileTwo] = useState();
    const [fileThree, setFileThree] = useState();

    useEffect(() => {
        dispatch(uploadFileValidationAction(false));
        dispatch(is3DUploadedAction(false));
        ////// dispatch(isIsirPdfuploadedAction(false));

        dispatch(tfcAction(false));
        dispatch(trmAction(false));
        dispatch(dfmAction(false));
        dispatch(ppapAction(false));
        dispatch(hideTb1(false));
        dispatch(drawingUpload(false));
        dispatch(threeDUpload(false));
        dispatch(isirUpload(false));



        downloadFileData.map((item) => {

            switch (item.uploadfiletype) {
                case 1:

                    setFileOne(item.fileName);

                    setDownloadDocxFirst(item.fileUrl);

                    dispatch(drawingUpload(true));

                    break;
                case 2:

                    setFileTwo(item.fileName);
                    setDownloadDocxSecond(item.fileUrl);

                    dispatch(threeDUpload(true));
                    break;
                case 3:

                    setFileThree(item.fileName);
                    setDownloadDocxThird(item.fileUrl);
                    break;
                case 4:

                    setFileFour(item.fileName);
                    setDownloadDocxFourth(item.fileUrl);
                    dispatch(isirUpload(true));

                    break;
                case 5:

                    setFileFive(item.fileName);
                    setDownloadDocxFifth(item.fileUrl);
                    //dispatch(isIsirPdfuploadedAction(true));
                    break;
                case 6:

                    setFileSix(item.fileName);
                    setDownloadDocxSix(item.fileUrl);
                    dispatch(dfmAction(true));
                    break;
                case 7:

                    setFileSeven(item.fileName);
                    setDownloadDocxSeven(item.fileUrl);
                    dispatch(trmAction(true));
                    break;
                case 22:

                    setFile22(item.fileName);
                    setDownloadDocx22dfm(item.fileUrl);
                    dispatch(ppapAction(true));
                    break;

            }
        });

    }, [downloadFileData])


    return (

        <div className="upload-section">

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />

            <Grid >
                <Grid container spacing={2} className="seperator">
                    {userDetails.roleId === 1 || userDetails.roleId === 6 || userDetails.roleId === 2 ?
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="Drawings.pdf*"
                                type="file"
                                id="desiredOPInKW"
                                name="desiredOPInKW"
                                className="label-font"
                                fullWidth
                                key={drawingpdfKey}
                                onChange={handlefirstfileUpload}
                                helperText={firstNameHelperText}
                                disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    accept: "application/pdf/*", multiple: false,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                className="p-0"
                                                target="_blank"
                                                href={downloadDocxFirst}

                                                disabled={userDetails.roleId === 8 || userDetails.roleId === 9 || userDetails.roleId === 10 ? true : false}
                                            >
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}></TextField>
                            {downloadFileData.length >= 2 ? <span className="fname">{fileOne}</span> : " "}
                            {getAttchRes.length > 0 ? <span className="fname">{fileOne}</span> : ""}
                        </Grid> : ((developmentData == 2 || prevDataAdd.developmentTypeId == 2) && userDetails.roleId == 3 && uploadTypeValue1 && ((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd.groupId == 1 || prevDataAdd.groupId == 2 || prevDataAdd.groupId == 4 || prevDataAdd.groupId == 5))) ?
                            <Grid item xs={12} sm={6} md={4}> <button className="link-filed1" onClick={downloadBallon1}>Download Drawing.pdf</button></Grid> : ((developmentData == 1 || prevDataAdd.developmentTypeId == 1) && userDetails.roleId == 3 && ((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd.groupId == 1 || prevDataAdd.groupId == 2 || prevDataAdd.groupId == 4 || prevDataAdd.groupId == 5))) ? <Grid item xs={12} sm={6} md={4}> <button className="dis-filed1" disabled>Download Drawing.pdf</button></Grid> : ((developmentData == 1 || prevDataAdd.developmentTypeId == 1) || (prevDataAdd.developmentTypeId == 2 || developmentData == 2)) && userDetails.roleId == 3 && ((groupData == 3 || prevDataAdd.groupId == 3)) ? <Grid item xs={12} sm={6} md={4}> <button className="dis-filed1" disabled>Download Drawing.pdf</button></Grid> : <Grid item xs={12} sm={6} md={4}>
                                {downloadDocxFirst ? <a target="_blank" href={downloadDocxFirst}><span className="link-filed">Download Drawings</span></a> : <></>}

                            </Grid>}


                    {userDetails.roleId === 1 || userDetails.roleId === 6 || userDetails.roleId === 2 ?
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="3D.stp/.cdr/.igs* (Zip file only!)"
                                type="file"
                                id="desiredOPInKW"
                                name="desiredOPInKW"
                                disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}

                                className=""
                                fullWidth
                                key={secondFileKey}
                                onChange={handleSecondFileupload}
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    accept: "application/stp/cdr/igs/*", multiple: false,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                className="p-0"
                                                target="_blank"
                                                href={downloadDocxSecond}
                                            >
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}></TextField>
                            {downloadFileData.length >= 2 ? <span className="fname">{fileTwo}</span> : " "}
                            {getAttchRes.length > 0 ? <span className="fname">{fileTwo}</span> : ""}
                        </Grid> : ((developmentData == 2 || prevDataAdd.developmentTypeId == 2) && userDetails.roleId == 3 && uploadTypeValue2 && ((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd.groupId == 1 || prevDataAdd.groupId == 2 || prevDataAdd.groupId == 4 || prevDataAdd.groupId == 5))) ?
                            <Grid item xs={12} sm={6} md={4}><button className="link-filed1" onClick={downloadBallontb1sec}>Download 3D</button></Grid> : ((developmentData == 1 || prevDataAdd.developmentTypeId == 1) && userDetails.roleId == 3 && ((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd.groupId == 1 || prevDataAdd.groupId == 2 || prevDataAdd.groupId == 4 || prevDataAdd.groupId == 5))) ? <Grid item xs={12} sm={6} md={4}><button className="dis-filed1" disabled >Download 3D</button></Grid> : ((developmentData == 1 || prevDataAdd.developmentTypeId == 1) || (developmentData == 2 || prevDataAdd.developmentTypeId == 2)) && userDetails.roleId == 3 && ((groupData == 3 || prevDataAdd.groupId == 3))
                                ? <Grid item xs={12} sm={6} md={4}><button className="dis-filed1" disabled>Download 3D</button></Grid> : < Grid item xs={12} sm={6} md={4}>
                                    {downloadDocxSecond ? <a target="_blank" href={downloadDocxSecond}><span className="link-filed">Download 3D</span></a> : <></>}

                                </Grid>}

                    {userDetails.roleId === 1 || userDetails.roleId === 6 || userDetails.roleId === 2 ?
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="Tool Specification for Tool.docx"
                                type="file"
                                id="desiredOPInKW"
                                name="desiredOPInKW"
                                className=""
                                fullWidth
                                disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}
                                key={thirdFileKey}
                                onChange={handleChangeFileThird}
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    accept: ".doc,.docx,application/.document/*", multiple: false,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                className="p-0"
                                                target="_blank"
                                                href={downloadDocxThird}

                                            >
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}></TextField>
                            {downloadFileData.length >= 2 ? <span className="fname">{fileThree}</span> : " "}
                            {getAttchRes.length > 0 ? <span className="fname">{fileThree}</span> : ""}
                        </Grid> : ((developmentData == 2 || prevDataAdd.developmentTypeId == 2) && userDetails.roleId == 3 && uploadTypeValue3 && ((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd.groupId == 1 || prevDataAdd.groupId == 2 || prevDataAdd.groupId == 4 || prevDataAdd.groupId == 5))) ?
                            <Grid item xs={12} sm={6} md={4}> <button className="link-filed1" onClick={downloadBallontb1third}>Download Tool Specification</button></Grid> : ((developmentData == 1 || prevDataAdd.developmentTypeId == 1) && userDetails.roleId == 3 && ((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd.groupId == 1 || prevDataAdd.groupId == 2 || prevDataAdd.groupId == 4 || prevDataAdd.groupId == 5))) ? <Grid item xs={12} sm={6} md={4}> <button className="dis-filed1" disabled>Download Tool Specification</button></Grid> : ((developmentData == 1 || prevDataAdd.developmentTypeId == 1) || (developmentData == 2 || prevDataAdd.developmentTypeId == 2)) && userDetails.roleId == 3 && ((groupData == 3 || prevDataAdd.groupId == 3)) ? <Grid item xs={12} sm={6} md={4}> <button className="dis-filed1" disabled>Download Tool Specification</button></Grid> : <Grid item xs={12} sm={6} md={4}>
                                {downloadDocxThird ? <a target="_blank" href={downloadDocxThird}><span className="link-filed">Download Tool Specification</span></a> : <></>}

                            </Grid>}

                </Grid>


                <Grid container spacing={2} className="supplier-fields">
                    {userDetails.roleId === 1 || userDetails.roleId === 6 || userDetails.roleId === 2 ?
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="ISIR Template*"
                                type="file"
                                id="desiredOPInKW"
                                name="desiredOPInKW"
                                className=""
                                fullWidth
                                disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}
                                key={fourthFileKey}
                                onChange={handleChangeFileFourth}
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                className="p-0"
                                                target="_blank"
                                                href={downloadDocxFourth}
                                                disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}

                                            >
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}></TextField>
                            {fileFour ? <span className="fname">{fileFour}</span> : " "}  {/*//disabled={true}*/}
                        </Grid> : <Grid item xs={12} sm={6} md={4}>
                            {/* {(groupData == 3 || prevDataAdd.groupId == 3 || developmentData == 1 || prevDataAdd.developmentTypeId == 1 ||  userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10) ? <a><span className="link-filed"><button className="disabled-link" disabled>Download ISIR template</button></span></a> : <a target="_blank" href={downloadDocxFourth}><span className="link-filed">Download ISIR template</span></a>}*/}
                            {/* {downloadDocxFourth ? <a target="_blank"  href={downloadDocxFourth}><span className="link-filed">Download ISIR template</span></a> : <></>}*/}
                            {((groupData == 3 || prevDataAdd.groupId == 3) && (userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10)) ? <a><span className="link-filed"><button className="disabled-link" disabled>Download ISIR template</button></span></a> :
                                userDetails.roleId === 3 ? <button className="link-filed1" onClick={getMasterFileName5} >Download ISIR</button>

                                    : ((developmentData == 2 || prevDataAdd.developmentTypeId == 2) && (userDetails.roleId == 3 || userDetails.roleId == 7) && ((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd.groupId == 1 || prevDataAdd.groupId == 2 || prevDataAdd.groupId == 4 || prevDataAdd.groupId == 5))) ? <a target="_blank" href={downloadDocxFourth}><span className="link-filed">Download ISIR template</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download ISIR Template</button></span></a>}

                        </Grid>}





                    {userDetails.roleId === 1 || userDetails.roleId === 6 || userDetails.roleId === 2 ?
                        <Grid item xs={12} sm={6} md={4} >  {/* className="check-box"*/}
                            <Grid className="check-box"> <TextField

                                label="TFC"
                                type="file"
                                id="desiredOPInKW"
                                name="desiredOPInKW"
                                disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}

                                className=""
                                fullWidth
                                key={fifthFileKey}
                                onChange={handleChangeFileFifth}
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                className="p-0"
                                                target="_blank"
                                                href={downloadDocxFifth}
                                                disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}
                                            >
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}></TextField>
                                <input
                                    className="hide-hidden"
                                    type="checkbox"
                                    label="TFC"
                                    checked={tfcChecked}
                                    onChange={handleTfcChecked}


                                /></Grid>
                            <h6>
                                {fileFive ? <span className="fname">{fileFive}</span> : " "}
                            </h6>



                        </Grid> : <Grid item xs={12} sm={6} md={4}>

                            {(groupData == 3 || prevDataAdd.groupId == 3 || developmentData == 1 || prevDataAdd.developmentTypeId == 1 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10) ? <a><span className="link-filed"><button className="disabled-link" disabled>Download TFC Template</button></span></a> : userDetails.roleId === 3 ? <button className="link-filed1" onClick={getMasterFileName1}>Download TFC Template</button> : <a target="_blank" href={downloadDocxFifth}><span className="link-filed">Download TFC Template</span></a>}
                            {/*  {downloadDocxFifth ? <a target="_blank"  href={downloadDocxFifth}><span className="link-filed">Download TFC</span></a> : <a href={" "}><span className="link-filed"><button className="disabled-link" disabled>Download TFC</button></span></a>}*/}
                        </Grid>

                    }

                    {userDetails.roleId === 1 || userDetails.roleId === 6 || userDetails.roleId === 2 ?
                        <Grid item xs={12} sm={6} md={4} >
                            <Grid className="check-box"> <TextField

                                label="DFM"
                                type="file"
                                id="dfmdesiredOPInKW"
                                name="dfmdesiredOPInKW"
                                disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}

                                className=""
                                fullWidth
                                key={dfm22FileKey}
                                onChange={handleFile22Dfm}
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                className="p-0"
                                                target="_blank"
                                                href={downLoadDocx22dfm}
                                                disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}
                                            >
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}></TextField>
                                <input
                                    className="hide-hidden"
                                    type="checkbox"
                                    label="DFM"
                                    checked={dfmChecked}

                                    onChange={handleDfmChecked}
                                /></Grid>


                            <h6>
                                {file22 ? <span className="fname">{file22}</span> : " "}
                            </h6>



                        </Grid> : <Grid item xs={12} sm={6} md={4}>
                            {(groupData == 3 || prevDataAdd.groupId == 3 || developmentData == 1 || prevDataAdd.developmentTypeId == 1 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10) ? <a><span className="link-filed"><button className="disabled-link" disabled>Download DFM Template</button></span></a> : userDetails.roleId === 3 ? <button className="link-filed1" onClick={getMasterFileName2}>Download DFM Template</button> : <a target="_blank" href={downLoadDocx22dfm}><span className="link-filed">Download DFM Template</span></a>}
                            {/*{downLoadDocx22dfm ? <a target="_blank" href={downLoadDocx22dfm}><span className="link-filed">Download DFM template</span></a> : <a href={" "}><span className="link-filed"><button className="disabled-link" disabled>Download DFM template</button></span></a>}*/}
                        </Grid>

                    }

                    {userDetails.roleId === 1 || userDetails.roleId === 6 || userDetails.roleId === 2 ?
                        <Grid item xs={12} sm={6} md={4} >
                            <Grid className="check-box">
                                <TextField
                                    label="TRM"
                                    type="file"
                                    id="desiredOPInKW"
                                    name="desiredOPInKW"
                                    className=""
                                    fullWidth
                                    disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}
                                    key={sixFileKey}
                                    onChange={handleChangeFileSix}
                                    variant="filled"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    className="p-0"
                                                    target="_blank"
                                                    href={downloadDocxSix}
                                                    disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}
                                                >
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}></TextField>

                                <input
                                    className="hide-hidden"
                                    type="checkbox"
                                    label="Trm"
                                    checked={trmChecked}

                                    onChange={handleTrmChecked}


                                />
                            </Grid>
                            <h6>
                                {fileSix ? <span className="fname">{fileSix}</span> : " "}
                            </h6>
                        </Grid> : <Grid item xs={12} sm={6} md={4}>
                            {(groupData == 3 || prevDataAdd.groupId == 3 || developmentData == 1 || prevDataAdd.developmentTypeId == 1 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10) ? <a><span className="link-filed"><button className="disabled-link" disabled>Download TRM Template</button></span></a> : userDetails.roleId === 3 ? <button className="link-filed1" onClick={getMasterFileName3}>Download TRM Template</button> : < a target="_blank" href={downloadDocxSix}><span className="link-filed">Download TRM Template</span></a>}
                            {/*{downloadDocxSix ? <a target="_blank" href={downloadDocxSix}><span className="link-filed">Download TRM template</span></a> : <a href={" "}><span className="link-filed"><button className="disabled-link" disabled>Download TRM template</button></span></a>}*/}

                        </Grid>}

                    {userDetails.roleId === 1 || userDetails.roleId === 6 || userDetails.roleId === 2 ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Grid className="check-box">
                                <TextField

                                    label="PPAP"
                                    type="file"
                                    id="desiredOPInKW"
                                    name="desiredOPInKW"
                                    className=""
                                    fullWidth
                                    disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}
                                    key={sevenFileKey}
                                    onChange={handleChangeFileSeven}
                                    variant="filled"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    className="p-0"
                                                    target="_blank"
                                                    href={downloadDocxSeven}
                                                    disabled={groupData === 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1}
                                                >
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}></TextField>


                                <input
                                    className="hide-hidden"
                                    type="checkbox"
                                    label="PPAP"

                                    checked={ppapChecked}
                                    onChange={handlePpapChecked}


                                />
                            </Grid>

                            <h6>
                                {fileSeven ? <span className="fname">{fileSeven}</span> : " "}</h6>
                        </Grid> : <Grid item xs={12} sm={6} md={4}>
                            {/* {downloadDocxSeven ? <a target="_blank" href={downloadDocxSeven}><span className="link-filed">Download PPAP template</span></a> : <a href={" "}><span className="link-filed"><button className="disabled-link" disabled>Download PPAP template</button></span></a>}*/}
                            {(groupData == 3 || prevDataAdd.groupId == 3 || developmentData == 1 || prevDataAdd.developmentTypeId == 1 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10) ? <a><span className="link-filed"><button className="disabled-link" disabled>Download PPAP Template</button></span></a> : userDetails.roleId === 3 ? <button className="link-filed1" onClick={getMasterFileName4}>Download PPAP Template</button> : <a target="_blank" href={downloadDocxSeven}><span className="link-filed">Download PPAP Template</span></a>}
                        </Grid>}

                    {userDetails.roleId == 6 || userDetails.roleId == 1 || userDetails.roleId === 2 ?
                        <Grid item xs={12} sm={6} md={4} >
                            <Button
                                className="pt-button--hero myid-login-btn-block"
                                onClick={handleUploadFileData}
                                disabled={afterClosingIsirActionDataState || groupData == 3 || developmentData == 1 || prevDataAdd.groupId === 3 || prevDataAdd.developmentTypeId == 1 || (prevDataAdd.statusName == "ISIR Closed" && prevDataAdd.statusId == 7 && prevDataAdd.massReleasetoSQE !== null)}
                            >
                                Upload
                            </Button>
                        </Grid> : <></>
                    }


                </Grid>


            </Grid>
            {isLoaderFeedback ? <Loading loaderText="Loading...." /> : <></>}

        </div>

    );
}
const PartTwo = ({ isirNumberPassed, prevDataAdd2, groupData, developmentData }) => {
    const [loader, setIsLoader] = useState(false);
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const fileUploadDetails = useSelector(
        (state) => state.uploadFileValidationReducer.files
    );
    const poTextActionDataState = useSelector((state) => state.isPoTextReducer.poTextValue);
    const afterClosingIsirActionDataState = useSelector((state) => state.afterClosingIsirReducer.afterClosingIsirValue);
    console.log("afterClosingIsirActionDataState", afterClosingIsirActionDataState);
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const azureToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const dispatch = useDispatch();

    const [siemensTeam, setSiemensTeam] = useState(false);
    const handleDisabled = () => {
        setSiemensTeam(true);
    }
    const handleClose = (e) => {
        setOpen(false);
    };
    const hideTabData2ActionState = useSelector((state) => state.hideTabPart2Reducer.hidetb2Value);


    const [balloningDrawing, setBalloningDrawing] = useState(null);
    const [balloningDrawingpdfKey, setBalloningDrawingpdfKey] = useState(Date.now);
    const [fileFilledandSignedtfc, setFileFilledandSignedtfc] = useState(null);
    const [fileFilledandSignedtfcKey, setfileFilledandSignedtfcKey] = useState(Date.now);
    const [fileFilledandSignedtrm, setFileFilledandSignedtrm] = useState(null);
    const [fileFilledandSignedtrmKey, setFileFilledandSignedtrmKey] = useState(Date.now);
    const [filedfm, setFiledfm] = useState(null);
    const [filedfmKey, setFiledfmKey] = useState(Date.now);
    const [fileToolDesignstp, setFileToolDesignstp] = useState(null);
    const [fileToolDesignStpKey, setFileToolDesignStpKey] = useState(Date.now);
    const [fileEAEM, setfileEAEM] = useState(null);
    const [fileEAEMKey, setFileEAEMKey] = useState(Date.now);
    const [fileEAEMPDF, setfileEAEMPDF] = useState(null);
    const [fileEAEMPDFKey, setFileEAEMPDFKey] = useState(Date.now);
    //new additional upload by supplier newly added
    const [fileAdditionalUploadKey, setFileAdditionalUploadKey] = useState(Date.now);
    const [fileAdditionalUpload, setFileAdditionalUpload] = useState(null);

    const [fileAdditionalUploadByCeKey, setFileAdditionalUploadByCeKey] = useState(Date.now);
    const [fileAdditionalUploadByCompCe, setFileAdditionalUploadByCompCe] = useState(null);

    const [fileUploadByCeForHebbrnKey, setFileUploadByCeForHebbrnKey] = useState(Date.now);
    const [fileUploadByCeForHebbrn, setFileUploadByCeForHebbrn] = useState(null);

    // fileUploadByCeForHebbrn

    const [fileEAEM_QualityPlan, setfileEAEM_QualityPlan] = useState(null);
    const [fileEAEM_QualityPlanKey, setfileEAEM_QualityPlanKey] = useState(Date.now);
    const [filePPAPReview1, setfilePPAPReview1] = useState(null);
    const [filePPAPReview1Key, setfilePPAPReview1Key] = useState(Date.now);
    const [filePPAPSnoff, setfilePPAPSnoff] = useState(null);
    const [filePPAPSnoffKey, setfilePPAPSnoffKey] = useState(Date.now);
    const [fileIncomingQreport, setfileIncomingQreport] = useState(null);
    const [fileIncomingQreportKey, setfileIncomingQreportKey] = useState(Date.now);
    const [fileCrer, setfileCrer] = useState(null);
    const [fileCrerKey, setfileCrerKey] = useState(Date.now);
    const [fileComponentAppr, setfileComponentAppr] = useState(null);
    const [fileComponentApprKey, setfileComponentApprKey] = useState(Date.now);
    const [attachmentRespOfTabTwo, setAttachmentRespenceOftabtwo] = useState([]);


    //download states
    const [download1Docx, setDownload1Docx] = useState("");
    const [download2Docx, setDownload2Docx] = useState("");
    const [download3Docx, setDownload3Docx] = useState("");
    const [download4Docx, setDownload4Docx] = useState("");
    const [download5Docx, setDownload5Docx] = useState("");
    const [download6Docx, setDownload6Docx] = useState("");
    const [download7Docx, setDownload7Docx] = useState("");
    const [download8Docx, setDownload8Docx] = useState("");
    const [download9Docx, setDownload9Docx] = useState("");
    const [download10Docx, setDownload10Docx] = useState("");
    const [download11Docx, setDownload11Docx] = useState("");
    const [download12Docx, setDownload12Docx] = useState("");
    const [download13Docx, setDownload13Docx] = useState("");
    const [download24Docx, setDownload24Docx] = useState("");
    const [download38Docx, setDownload38Docx] = useState("");
    const [download39Docx, setDownload39Docx] = useState("");
    const [downloadTab2Data, setDownloadTab2Data] = useState([]);


    const handleballoning = (e) => {
        setBalloningDrawing(e.target.files[0]);

    };
    const handleHandlesignedtfc = (e) => {
        setFileFilledandSignedtfc(e.target.files[0]);


    }
    const handleTrm = (e) => {
        setFileFilledandSignedtrm(e.target.files[0]);


    }
    const handleFM = (e) => {
        setFiledfm(e.target.files[0]);


    }
    const handleToolDesign = (e) => {
        setFileToolDesignstp(e.target.files[0]);


    }
    const handleIsirxlsm = (e) => {
        setfileEAEM(e.target.files[0]);


    }
    const handleAdditionalUploads = (e) => {
        setFileAdditionalUpload(e.target.files[0]);

    }
    const handleAdditionalUploadsByComponentEngineer = (e) => {
        setFileAdditionalUploadByCompCe(e.target.files[0]);
    }
    const handleUploadByCeForHerren = (e) => {
        setFileUploadByCeForHebbrn(e.target.files[0]);
    }
    const handleIsirPdf = (e) => {
        setfileEAEMPDF(e.target.files[0]);


    }
    const handleQualityPlan = (e) => {
        setfileEAEM_QualityPlan(e.target.files[0]);


    }
    const handlePpapReview = (e) => {
        setfilePPAPReview1(e.target.files[0]);


    }
    const handlePPAPSnoff = (e) => {
        setfilePPAPSnoff(e.target.files[0]);


    }
    const handleIqualityReport = (e) => {
        setfileIncomingQreport(e.target.files[0]);


    }
    const handleCrer = (e) => {
        setfileCrer(e.target.files[0]);


    }
    const handleCompoAppr = (e) => {
        setfileComponentAppr(e.target.files[0]);
    }
    const [openSnackbarInternal, setOpenSnackbarInternal] = useState(false);

    const [snackMessageInternal, setSnackMessageInternal] = useState("");

    const [alertTypeInternal, setAlertTypeInternal] = useState("");

    const handleSnackOpenInternal = (text, type) => {

        setSnackMessageInternal(text);

        setAlertTypeInternal(type);

        setOpenSnackbarInternal(true);

    };
    const handleCloseSnackbarInternal = (e) => {

        setOpenSnackbarInternal(false);

    };


    const [openDialog, setOpenDialog] = React.useState(false);
    const [snapshotFile, setSnapshotFile] = useState(null);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClickOpen = () => {
        setOpenDialog(true);
    };
    //const [uploadImageData, setUploadImageData] = useState("");
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };

    let [inputFileValue, setInputFileValue] = useState(Date.now);

    const updateSnapShotHandler = (e) => {
        let type = e.target.files[0].type.replace("image/", "");
        if (type.toLowerCase() !== "jpg" && type.toLowerCase() !== "jpeg" && type.toLowerCase() !== "png") {
            handleSnackOpen("Only jpg,jpeg and pdf format are supported", "info");
        }
        else {
            setSnapshotFile(e.target.files[0]);
        }
    };
    ///let isirNum = isirNumberPassed;
    const [poRes, setPoRes] = useState("");
    function poTextResp() {
        console.log("poRes", poRes);
    }
    function poText(isirNumberPassed) {

        getPOText(userDetails.userId, isirNumberPassed)
            .then((response) => {
                console.log("poget", response);
                setPoRes(response.objResult);

                // setUploadImageData(response.objResult);
                console.log("res", response.objResult);
                if (response.objResult) {
                    dispatch(poTextAction(true));
                }
                //dispatch(poTextAction(true));

            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    //const [poImg, setPoImg] = useState("");
    const [poImgUrl, setPoImgUrl] = useState("");
    const [poImgName, setPoImgName] = useState();
    const handleUpload = (e) => {
        if (!snapshotFile) {
            handleSnackOpen("Please Select a File", "info");
            setIsLoader(false);
        }
        else {
            setIsLoader(true);
            const formData = new FormData();
            formData.append("file", snapshotFile);
            formData.append("UserId", userDetails.userId);
            formData.append("AutomatedIsirnumber", isirNumberPassed);
            addPOTextDetails(siteToken.token,
                azureToken.token,
                UID.id,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.role,
                userDetails.roleId, formData)
                .then((response) => {
                    console.log("response", response);
                    if (response.response) {
                        // setIsLoader(true);


                        localStorage.setItem("ApplicationId", 1);
                        dispatch(poTextAction(true));
                        setPoImgUrl(response.responseimage)
                        setPoImgName(response.imagename)
                        //setUploadImageData(response.objResult);
                        setIsLoader(false);
                        handleSnackOpen(response.responseMsg, "success");
                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        setIsLoader(false);
                    }
                })
                .catch((error) => {
                    setIsLoader(false);
                    setSnapshotFile(null)
                    setInputFileValue(Date.now);
                    handleSnackOpen("Exception : " + error, "error");
                });
        }
    }

    const [keepItClose, setKeepItClose] = useState(false);
    const [tab1FilesData, setTab1FilesData] = useState();
    function handleDownloadTb1Data(isirNumberPassed) {

        getuploadedDatainDownLoadFile(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, isirNumberPassed)
            .then((response) => {



                setTab1FilesData(response);
                if (response.uploadfiletype == 1 && response.uploadfiletype == 2 && response.uploadfiletype == 4) {
                    setKeepItClose(true);
                }




            })
            .catch((error) => {
                console.log(error);

            });
    }



    //getallisirdetailsgetapi call
    const [tfcdres2, setTCFdres2] = useState("");
    const [trmdres2, setTRMdres2] = useState("");
    const [ppapdres2, setPPAPdres2] = useState("");
    const [dfmdres2, setDFMdres2] = useState("");
    const [isPoUplodaded, setIsPouploaded] = useState();
    const [getAllisirDatat2, setGetAllisirDatat2] = useState([]);

    function getDetailsForIsir() {
        setIsLoader(true);

        getAllIsirDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {

                setGetAllisirDatat2(response);
                let particularUserDraft2 = response;
                let getRes2 = particularUserDraft2.filter((item) => item.automatedIsirnumber == prevDataAdd2.automatedIsirnumber);

                if (getRes2.length === 1) {


                    let tfcDraftgetRes2 = getRes2.map((x) => (x.tfmcheckbox));

                    setTCFdres2(tfcDraftgetRes2[0]);
                    let ppapDraftgetRes2 = getRes2.map((x) => (x.ppapcheckbox));

                    setPPAPdres2(ppapDraftgetRes2[0]);
                    let trmDraftgetRes2 = getRes2.map((x) => (x.trmcheckbox));

                    setTRMdres2(trmDraftgetRes2[0]);
                    let dfmDraftgetRes2 = getRes2.map((x) => (x.dfmcheckbox));

                    setDFMdres2(dfmDraftgetRes2[0]);

                    let poDraftgetRes2 = getRes2.map((x) => (x.isPotextUploaded[0]));

                    setIsPouploaded(poDraftgetRes2[0]);


                }

                setIsLoader(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoader(false);


            });

    };



    useEffect(() => {
        getDetailsForIsir();
    }, [])

    const [snackMsg, setSnackMsg] = useState("");
    const [isLoaderFeedback, setIsLoaderFeedback] = useState(false);
    //const handleUploadFileDataTabtwo = (e) => {
    //    handleSnackOpenInternal("Data Uploaded Successfully.", "success");
    //}
    const [openSnack, setOpenSnack] = useState(false)
    const handleOpenSnack = () => {
        setOpenSnack(true)
    }
    const handleCloseSnack = () => {
        setOpenSnack(false)
    }
    const [openSnackRed, setOpenSnackRed] = useState(false)
    const handleOpenSnackRed = () => {
        setOpenSnackRed(true)
    }
    const handleCloseSnackRed = () => {
        setOpenSnackRed(false)
    }
    const [uploadTypeValue, setUploadTypeValue] = useState();
    const [uploadTypeValue9, setUploadTypeValue9] = useState();
    const [uploadTypeValue10, setUploadTypeValue10] = useState();
    const [uploadTypeValue11, setUploadTypeValue11] = useState();
    const [uploadTypeValue12, setUploadTypeValue12] = useState();
    const [uploadTypeValue13, setUploadTypeValue13] = useState();
    const [uploadTypeValue14, setUploadTypeValue14] = useState();
    const [uploadTypeValue15, setUploadTypeValue15] = useState();//isir.pdf
    const [uploadTypeValue16, setUploadTypeValue16] = useState();//quality plan
    const [uploadTypeValue17, setUploadTypeValue17] = useState();///ppap review
    const [uploadTypeValue18, setUploadTypeValue18] = useState();//ppap sigoff
    const [uploadTypeValue19, setUploadTypeValue19] = useState();//incoming quality report
    const [uploadTypeValue20, setUploadTypeValue20] = useState();//crer
    const [uploadTypeValue21, setUploadTypeValue21] = useState();//component approver
    const [uploadTypeValue39, setUploadTypeValue39] = useState();//heburn
    const [uploadTypeValue38, setUploadTypeValue38] = useState();//Additional upload by ce

    console.log("uploadTypeValue", uploadTypeValue);
    const handleUploadFileDataTabtwo = (e) => {

        e.preventDefault();


        let tabData2 = new FormData();

        tabData2.append("userId", userDetails.userId);
        tabData2.append("AutomatedISIRNumber", isirNumberPassed);
        tabData2.append("fileBallonningDrawing", balloningDrawing);

        tabData2.append("fileFilledandSignedTFC", fileFilledandSignedtfc);
        tabData2.append("fileFilledandSignedTRM", fileFilledandSignedtrm);
        tabData2.append("fileDFMppt", filedfm);
        tabData2.append("fileToolDesignStp", fileToolDesignstp);
        tabData2.append("fileEAEM_ISIR_XLSM", fileEAEM);
        tabData2.append("additionalUploadsBySupplier", fileAdditionalUpload);
        tabData2.append("AdditionalFileUploadByCompCe", fileAdditionalUploadByCompCe);
        tabData2.append("Uploadfileforheburn", fileUploadByCeForHebbrn);

        tabData2.append("fileEAEM_ISIR_PDF", fileEAEMPDF);
        tabData2.append("fileEAEM_QualityPlan_PDF", fileEAEM_QualityPlan);


        tabData2.append("filePPAPReview", filePPAPReview1);
        tabData2.append("filePPAPSignOff", filePPAPSnoff);
        tabData2.append("fileIncomingQualityReport", fileIncomingQreport);
        tabData2.append("fileCRER", fileCrer);
        tabData2.append("fileComponentApproval", fileComponentAppr);
        handleCloseDialog();


        if (userDetails.roleId == 3) {
            if (fileEAEM == null && download6Docx == null && prevDataAdd2.developmentTypeId !== 1 && prevDataAdd2.groupId !== 3) {
                setIsLoaderFeedback(false);
                alert("please upload isir.xlsm");
                return;
            }

        }
        else if (userDetails.roleId == 6 || userDetails.roleId === 2) {
            if (fileEAEM_QualityPlan == null && download8Docx == null) {
                setIsLoaderFeedback(false);
                alert("please upload Quality Plan(IIP)");
                return;
            }
        }
        else if (userDetails.roleId == 7) {
            if (fileEAEMPDF == null && download7Docx == null) {
                setIsLoaderFeedback(false);
                alert("please upload ISIR.Pdf");
                return;
            }
        }
        else if ((userDetails.roleId == 6 || userDetails.roleId === 2) && ((fileEAEM_QualityPlan || download8Docx) && prevDataAdd2.developmentTypeId !== 1 && prevDataAdd2.groupId !== 3)) {
            if (fileCrer == null && download12Docx == null) {
                setIsLoaderFeedback(false);
                alert("please upload ISIR.Pdf");
                return;
            }
        }


        setIsLoader(true);
        uploadTemplateFileDataTab2(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, tabData2)
            .then((response) => {


                if (response.response) {
                    setAttachmentRespenceOftabtwo(response.responseAttachments);
                    const uploadedAttchments = response.responseAttachments;
                    for (let item in uploadedAttchments) {
                        if (item.uploadfiletype == 15) {

                            dispatch(iipAction(true));
                            break;
                        }
                        if (item.uploadfiletype == 16) {
                            dispatch(ppapxlsmAction(true));
                            break;
                        }
                        if (item.uploadfiletype == 18) {
                            dispatch(incomingQualityReportAction(true));
                            break;
                        }
                        if (item.uploadfiletype == 20) {
                            dispatch(componentApprovalFormAction(true));
                            break;
                        }
                    }


                    setIsLoader(false);
                    //handleSnackOpen(response.responseMsg, "success");
                    // handleSnackOpenInternal(response.responseMsg, "success");
                    handleSnackOpenInternal("Data Uploaded Successfully.", "success");
                    setSnackMsg(response.responseMsg);
                    handleOpenSnack();


                } else {

                    setIsLoaderFeedback(false);
                    handleSnackOpen(response.responseMsg, "error");
                    handleOpenSnackRed();

                }

                setAttachmentRespenceOftabtwo(response.responseAttachments);
                let attachmentRespt2 = response.responseAttachments;
                console.log("attachmentRespt2", attachmentRespt2);
                if (attachmentRespt2 != undefined && attachmentRespt2.length > 0) {
                    attachmentRespt2.map((item) => {
                        switch (item.uploadfiletype) {
                            case 8:

                                setUploadTypeValue(item.uploadfiletype);
                                setDownload1Docx(item.fileUrl);
                                break;
                            case 9:
                                setUploadTypeValue9(item.uploadfiletype)
                                setDownload2Docx(item.fileUrl);
                                break;
                            case 10:
                                setUploadTypeValue10(item.uploadfiletype)
                                console.log("upload10", item.uploadfiletype)
                                setDownload3Docx(item.fileUrl);
                                break;
                            case 11:
                                setUploadTypeValue11(item.uploadfiletype)
                                console.log("upload11", item.uploadfiletype);
                                setDownload4Docx(item.fileUrl);
                                break;
                            case 12:
                                setUploadTypeValue12(item.uploadfiletype)
                                setDownload5Docx(item.fileUrl);
                                break;
                            case 13:
                                setUploadTypeValue13(item.uploadfiletype)
                                setDownload6Docx(item.fileUrl);
                                break;
                            case 14:
                                //setUploadTypeValue14(item.uploadfiletype)

                                setUploadTypeValue15(item.uploadfiletype);//isir.pdf
                                setDownload7Docx(item.fileUrl);
                                break;
                            case 15:
                                setDownload8Docx(item.fileUrl);
                                setUploadTypeValue16(item.uploadfiletype);//quality plan
                                break;
                            case 16:
                                setUploadTypeValue17(item.uploadfiletype);
                                setDownload9Docx(item.fileUrl);
                                break;
                            case 17:
                                setUploadTypeValue18(item.uploadfiletype)
                                setDownload10Docx(item.fileUrl);
                                break;
                            case 18:
                                setUploadTypeValue19(item.uploadfiletype);
                                setDownload11Docx(item.fileUrl);
                                break;
                            case 19:
                                setUploadTypeValue20(item.uploadfiletype);
                                setDownload12Docx(item.fileUrl);
                                break;
                            case 20:
                                setUploadTypeValue21(item.uploadfiletype);
                                setDownload13Docx(item.fileUrl);

                                break;
                            case 24:
                                setDownload24Docx(item.fileUrl);
                                break;
                            case 38:
                                setDownload38Docx(item.fileUrl);
                                setUploadTypeValue38(item.uploadfiletype);
                                break;
                            case 39:
                                setDownload39Docx(item.fileUrl);
                                setUploadTypeValue39(item.uploadfiletype);
                                break;
                            default:

                        }
                    });
                }



            })


    }




    const [showModal, setShowModal] = useState(false);
    const createRemark = (e) => {
        setShowModal(true)
    }

    //get API call for Download //to get all uploaded attachement data
    function handleDownloadTb2Data(isirNumberPassed) {

        getuploadedDatainDownLoadFile(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, isirNumberPassed)
            .then((response) => {
                console.log("getallpo", response);

                setDownloadTab2Data(response);
            })
            .catch((error) => {
                console.log(error);

            });
    }
    function handleDownloadTb1Data(isirNumberPassed) {

        getuploadedDatainDownLoadFile(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, isirNumberPassed)
            .then((response) => {

                setDownloadTab2Data(response);


            })
            .catch((error) => {
                console.log(error);

            });
    }
    //const [uploadImageData, setUploadImageData] = useState();
    //const [poImg, setPoImg] = useState("");
    useEffect(() => {


        poText(isirNumberPassed);
        if (prevDataAdd2) {

            handleDownloadTb2Data(prevDataAdd2.automatedIsirnumber);//item.automatedIsirnumber === isirWorkFlowNumber
            //let sakshi = downloadTab2Data.filter((x) => x.automatedIsirnumber === prevDataAdd2.automatedIsirnumber);

            //console.log("sakshi", sakshi);
            //sakshi.map((y) => {
            //    if (y.uploadfiletype == 21) {
            //        console.log("filename", y.fileName);
            //        console.log("pourl", y.fileUrl);
            //        setPoImg(y.fileUrl)
            //    }
            //})


        }
        else {
            handleDownloadTb2Data(isirNumberPassed);

        }

    }, [attachmentRespOfTabTwo, isirNumberPassed, prevDataAdd2.automatedIsirnumber])
    //attachmentRespOfTabTwo, isirNumberPassed, prevDataAdd2.automatedIsirnumber
    const [fileT2Data1, setFileT2Data1] = useState();
    const [fileT2Data2, setFileT2Data2] = useState();
    const [fileT2Data3, setFileT2Data3] = useState();
    const [fileT2Data4, setFileT2Data4] = useState();
    const [fileT2Data5, setFileT2Data5] = useState();
    const [fileT2Data6, setFileT2Data6] = useState();
    const [fileT2Data7, setFileT2Data7] = useState();
    const [fileT2Data8, setFileT2Data8] = useState();
    const [fileT2Data9, setFileT2Data9] = useState();
    const [fileT2Data10, setFileT2Data10] = useState();
    const [fileT2Data11, setFileT2Data11] = useState();
    const [fileT2Data12, setFileT2Data12] = useState();
    const [fileT2Data13, setFileT2Data13] = useState();
    const [fileT2Data24, setFileT2Data24] = useState();
    const [fileT2Databyce38, setFileT2Databyce38] = useState();
    const [fileT2Databyce39, setFileT2Databyce39] = useState();

    const [isBalloning, setisBalloning] = useState();




    useEffect(() => {
        dispatch(iipAction(false));
        dispatch(ppapxlsmAction(false));
        dispatch(incomingQualityReportAction(false));
        dispatch(componentApprovalFormAction(false))
        console.log("downloadTab2Data", downloadTab2Data);

        downloadTab2Data.map((item) => {

            switch (item.uploadfiletype) {
                case 8:

                    setUploadTypeValue(item.uploadfiletype);
                    console.log("uploadType", item.uploadfiletype);
                    setFileT2Data1(item.fileName.trim());
                    setDownload1Docx(item.fileUrl);

                    break;
                case 9:
                    setUploadTypeValue9(item.uploadfiletype)
                    setFileT2Data2(item.fileName);
                    setDownload2Docx(item.fileUrl);
                    break;
                case 10:
                    setUploadTypeValue10(item.uploadfiletype)
                    console.log("upload10", item.uploadfiletype);
                    setFileT2Data3(item.fileName);
                    setDownload3Docx(item.fileUrl);
                    break;
                case 11:
                    setUploadTypeValue11(item.uploadfiletype)
                    console.log("upload11", item.uploadfiletype);
                    setFileT2Data4(item.fileName);
                    setDownload4Docx(item.fileUrl);
                    break;
                case 12:
                    setUploadTypeValue12(item.uploadfiletype)
                    setFileT2Data5(item.fileName);
                    setDownload5Docx(item.fileUrl);
                    break;
                case 13:
                    setUploadTypeValue13(item.uploadfiletype)
                    setFileT2Data6(item.fileName);
                    setDownload6Docx(item.fileUrl);
                    break;
                case 14:
                    setUploadTypeValue15(item.uploadfiletype);
                    console.log("upload15", item.uploadfiletype);
                    setFileT2Data7(item.fileName);
                    setDownload7Docx(item.fileUrl);
                    break;
                case 15:
                    setUploadTypeValue16(item.uploadfiletype)
                    setFileT2Data8(item.fileName);
                    setDownload8Docx(item.fileUrl);

                    dispatch(iipAction(true));
                    break;
                case 16:
                    setUploadTypeValue17(item.uploadfiletype);
                    setFileT2Data9(item.fileName);
                    setDownload9Docx(item.fileUrl);
                    dispatch(ppapxlsmAction(true));
                    break;
                case 17:
                    setUploadTypeValue18(item.uploadfiletype)
                    setFileT2Data10(item.fileName);
                    setDownload10Docx(item.fileUrl);
                    break;
                case 18:
                    setUploadTypeValue19(item.uploadfiletype);
                    setFileT2Data11(item.fileName);
                    setDownload11Docx(item.fileUrl);
                    dispatch(incomingQualityReportAction(true));
                    break;
                case 19:
                    setUploadTypeValue20(item.uploadfiletype);
                    setFileT2Data12(item.fileName);
                    setDownload12Docx(item.fileUrl);
                    break;
                case 20:
                    setUploadTypeValue21(item.uploadfiletype);
                    setFileT2Data13(item.fileName);
                    setDownload13Docx(item.fileUrl);
                    dispatch(componentApprovalFormAction(true));
                    break;
                case 24:
                    console.log("uploadType14", item.uploadfiletype);//additional upload by supplier
                    setUploadTypeValue14(item.uploadfiletype)
                    setFileT2Data24(item.fileName);
                    setDownload24Docx(item.fileUrl);
                    break;
                case 38:
                    setFileT2Databyce38(item.fileName);
                    setDownload38Docx(item.fileUrl);
                    setUploadTypeValue38(item.uploadfiletype);
                    break;
                case 39:
                    setUploadTypeValue39(item.uploadfiletype)
                    setDownload39Docx(item.fileUrl);
                    setFileT2Databyce39(item.fileName);
                case 21:

                    setPoImgUrl(item.fileUrl);
                    setPoImgName(item.fileName);

                default:



            }
        });


    }, [downloadTab2Data])

    const [getDinAddisirTab2, setGetDinAddisirTab2] = useState([]);
    const [hebburnRndLoc, setHebburnRndLoc] = useState(false);
    function getDinAddInTab2() {

        setIsLoader(true);

        getAllIsirDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {

                setGetDinAddisirTab2(response);


                setIsLoader(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoader(false);


            });

    };
    useEffect(() => {

        getDinAddInTab2();


    }, []);

    useEffect(() => {

        let datainTab2 = getDinAddisirTab2.filter((item) => item.automatedIsirnumber == prevDataAdd2.automatedIsirnumber);
        console.log("datainTab2", datainTab2);
        datainTab2.map((x) => {
            if (x.rdLocationId == 4) {
                setHebburnRndLoc(true);

            }

        })

    }, [getDinAddisirTab2])
    //function downloadBallon() {
    //    //let x = downloadtab2data.filter((x) => x.uploadfiletype === 8);
    //    //console.log("x", x);
    //    let attachmentdata = {

    //        AutomatedIsirnumber: isirNumberPassed,
    //        Uploadfiletype: uploadTypeValue,
    //    }

    //    const fetchpromise = fetch(baseApiURL + "/Admin/ISIRdownloadfile",
    //        {
    //            method: "post",
    //            headers: {
    //                "content-type": "application/json; charset=utf-8",
    //                "authorization": "bearer " + token,
    //            },
    //            body: JSON.stringify(attachmentdata),
    //        });
    //    fetchpromise.then(response => {
    //      console.log("file",response)
    //        response.blob().then(blob => {
    //            const link = document.createelement('a');
    //            const url = url.createobjecturl(blob);
    //            link.href = url;
    //            link.download = "download.pdf"
    //            //link.click();


    //            document.body.appendChild(link);
    //            link.click();
    //            document.body.removeChild(link);
    //            window.URL.revokeObjectURL(url);
    //        })
    //    })
    //}



    function downloadBallon() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue,
        }


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue, '_blank')

    }
    function downloadBallon2() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue,
        }


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue9, '_blank')

    }
    function downloadBallon3() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue10,
        }


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue10, '_blank')

    }
    function downloadBallon4() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue11,
        }


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue11, '_blank')

    }

    function downloadBallon5() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue12,
        }


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue12, '_blank')

    }

    function downloadBallon6() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue13,
        }


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue13, '_blank')

    }

    function downloadBallon7() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue14,
        }


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue14, '_blank')

    }
    function downloadBallon8isirPdf() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue15,
        }
        console.log("attachmentData", attachmentData);


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue15, '_blank')

    }

    function downloadBallon9qualityPlan() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue16,
        }
        console.log("attachmentData", attachmentData);


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue16, '_blank')

    }

    function downloadBallon10PpapReview() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue17,
        }
        console.log("attachmentData", attachmentData);


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue17, '_blank')

    }
    function downloadBallon11PpapSignoff() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue18,
        }
        console.log("attachmentData", attachmentData);


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue18, '_blank')

    }
    function downloadBallon12IncomingQualityReport() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue19,
        }
        console.log("attachmentData", attachmentData);


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue19, '_blank')

    }
    //downloadBallon8isircrer
    function downloadBallon13isircrer() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue20,
        }
        console.log("attachmentData", attachmentData);


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue20, '_blank')

    }

    function downloadBallon14ComponentApprover() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue21,
        }
        console.log("attachmentData", attachmentData);


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue21, '_blank')

    }
    //uploadTypeValue39
    function downloadBallon15Heburn() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue39,
        }
        console.log("attachmentData", attachmentData);


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue39, '_blank')

    }
    //uploadTypeValue38
    function downloadBallon16AddituionalUploadByce() {
        //let x = downloadTab2Data.filter((x) => x.uploadfiletype === 8);
        //console.log("x", x);
        let attachmentData = {

            AutomatedIsirnumber: isirNumberPassed,
            Uploadfiletype: uploadTypeValue38,
        }
        console.log("attachmentData", attachmentData);


        window.open(baseURL + "/ISIRdownloadfile?AutomatedIsirnumber=" + isirNumberPassed + "& Uploadfiletype=" + uploadTypeValue38, '_blank')

    }

    const [openDialogPo, setOpenDialogPo] = React.useState(false);

    const handleClickOpenPo = () => {
        setOpenDialogPo(true);
    };

    const handleCloseDialogPo = () => {
        setOpenDialogPo(false);
    };



    return (
        <div>


            <Grid container spacing={2} className="part-two" >
                {userDetails.roleId === 1 || userDetails.roleId == 2 || userDetails.roleId === 6 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Ballooning Drawing.pdf"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            fullWidth
                            disabled={groupData === 3 || prevDataAdd2.groupId === 3}
                            key={balloningDrawingpdfKey}
                            onChange={handleballoning}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            href={download1Docx}
                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data1}</span> : " "}
                    </Grid> : userDetails.roleId === 3 ?
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="Ballooning Drawing.pdf"
                                type="file"
                                id="desiredOPInKW"
                                name="desiredOPInKW"

                                fullWidth
                                disabled={groupData === 3 || prevDataAdd2.groupId === 3}
                                key={balloningDrawingpdfKey}
                                onChange={handleballoning}
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                className="p-0"
                                                target="_blank"
                                                onClick={downloadBallon}
                                            //href={download1Docx}
                                            >
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}></TextField>
                            {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data1}</span> : " "}
                        </Grid>
                        :
                        <Grid item xs={12} sm={6} md={4}>
                            {download1Docx ? <a target="_blank" href={download1Docx}><span className="link-filed">Download Ballooning Drawing template</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download Ballooning Drawing template</button></span></a>}
                        </Grid>}





                {userDetails.roleId == 3 ?
                    <Grid item xs={12} sm={8} md={4}>
                        <TextField
                            label="Filled and Signed EM EA GF QM FR 033_TFC.pdf"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={groupData === 3 || developmentData == 1 || prevDataAdd2.groupId == 3 || prevDataAdd2.developmentTypeId == 1}
                            key={fileFilledandSignedtfcKey}
                            onChange={handleHandlesignedtfc}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            onClick={downloadBallon2}
                                        //href={download2Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data2}</span> : " "}
                    </Grid> : userDetails.roleId == 1 || userDetails.roleId === 2 || userDetails.roleId === 6 ? <Grid item xs={12} sm={8} md={4}>
                        <TextField
                            label="Filled and Signed EM EA GF QM FR 033_TFC.pdf"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={groupData === 3 || developmentData == 1 || prevDataAdd2.groupId == 3 || prevDataAdd2.developmentTypeId == 1}
                            key={fileFilledandSignedtfcKey}
                            onChange={handleHandlesignedtfc}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            //onClick={downloadBallon2}
                                            href={download2Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data2}</span> : " "}
                    </Grid> :

                        <Grid item xs={12} sm={6} md={4}>
                            {download2Docx ? <a target="_blank" href={download2Docx}><span className="link-filed">Download TFC.pdf</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download TFC.pdf</button></span></a>}

                        </Grid>
                }

                {userDetails.roleId == 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={8} md={4}>
                        <TextField
                            label="Filled and Signed EM EA GF QM FR 046_TRM.pdf"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            //disabled={((((groupData == 1 ||groupData == 2|| groupData == 4||groupData == 5)||(prevDataAdd2.groupId == 1 ||prevDataAdd2.groupId == 2||prevDataAdd2.groupId == 4 ||prevDataAdd2.groupId == 5) && (prevDataAdd2.developmentTypeId==2 || developmentData == 2))&& (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate))||((groupData == 3 || prevDataAdd2.groupId == 3)|| (prevDataAdd2.developmentTypeId == 1 || developmentData == 1)))}
                            disabled={groupData === 3 || developmentData == 1 || prevDataAdd2.groupId == 3 || prevDataAdd2.developmentTypeId == 1}
                            key={fileFilledandSignedtrmKey}
                            onChange={handleTrm}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            // onClick={downloadBallon3}
                                            href={download3Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data3}</span> : " "}
                    </Grid> : userDetails.roleId === 3 ? <Grid item xs={12} sm={8} md={4}>
                        <TextField
                            label="Filled and Signed EM EA GF QM FR 046_TRM.pdf"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            //disabled={((((groupData == 1 ||groupData == 2|| groupData == 4||groupData == 5)||(prevDataAdd2.groupId == 1 ||prevDataAdd2.groupId == 2||prevDataAdd2.groupId == 4 ||prevDataAdd2.groupId == 5) && (prevDataAdd2.developmentTypeId==2 || developmentData == 2))&& (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate))||((groupData == 3 || prevDataAdd2.groupId == 3)|| (prevDataAdd2.developmentTypeId == 1 || developmentData == 1)))}
                            disabled={groupData === 3 || developmentData == 1 || prevDataAdd2.groupId == 3 || prevDataAdd2.developmentTypeId == 1}
                            key={fileFilledandSignedtrmKey}
                            onChange={handleTrm}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            onClick={downloadBallon3}
                                        //href={download3Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data3}</span> : " "}
                    </Grid> : <Grid item xs={12} sm={6} md={4}>
                        {download3Docx ? <a target="_blank" href={download3Docx}><span className="link-filed">Download TRM.pdf</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download TRM.pdf </button></span></a>}

                    </Grid>
                }

                {userDetails.roleId == 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="DFM.ppt"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            //disabled={((((groupData == 1 ||groupData == 2|| groupData == 4||groupData == 5)||(prevDataAdd2.groupId == 1 ||prevDataAdd2.groupId == 2||prevDataAdd2.groupId == 4 ||prevDataAdd2.groupId == 5) && (prevDataAdd2.developmentTypeId==2 || developmentData == 2))&& (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate))||((groupData == 3 || prevDataAdd2.groupId == 3)|| (prevDataAdd2.developmentTypeId == 1 || developmentData == 1)))}
                            disabled={groupData === 3 || developmentData == 1 || prevDataAdd2.groupId == 3 || prevDataAdd2.developmentTypeId == 1}
                            key={filedfmKey}
                            onChange={handleFM}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            // onClick={downloadBallon4}
                                            href={download4Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data4}</span> : " "}
                    </Grid> : userDetails.roleId === 3 ? <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="DFM.ppt"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            //disabled={((((groupData == 1 ||groupData == 2|| groupData == 4||groupData == 5)||(prevDataAdd2.groupId == 1 ||prevDataAdd2.groupId == 2||prevDataAdd2.groupId == 4 ||prevDataAdd2.groupId == 5) && (prevDataAdd2.developmentTypeId==2 || developmentData == 2))&& (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate))||((groupData == 3 || prevDataAdd2.groupId == 3)|| (prevDataAdd2.developmentTypeId == 1 || developmentData == 1)))}
                            disabled={groupData === 3 || developmentData == 1 || prevDataAdd2.groupId == 3 || prevDataAdd2.developmentTypeId == 1}
                            key={filedfmKey}
                            onChange={handleFM}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            onClick={downloadBallon4}
                                        //href={download4Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data4}</span> : " "}
                    </Grid> : <Grid item xs={12} sm={6} md={4}>
                        {download4Docx ? <a target="_blank" href={download4Docx}><span className="link-filed">Download DFM ppt</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download DFM ppt </button></span></a>}

                    </Grid>
                }




                {userDetails.roleId === 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Tool Design.stp / .igs (Zip file only!)"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={groupData === 3 || developmentData == 1 || prevDataAdd2.groupId == 3 || prevDataAdd2.developmentTypeId == 1}
                            key={fileToolDesignStpKey}
                            onChange={handleToolDesign}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            // onClick={downloadBallon5}
                                            href={download5Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data5}</span> : " "}
                    </Grid> : userDetails.roleId === 3 ? <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Tool Design.stp / .igs (Zip file only!)"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={groupData === 3 || developmentData == 1 || prevDataAdd2.groupId == 3 || prevDataAdd2.developmentTypeId == 1}
                            key={fileToolDesignStpKey}
                            onChange={handleToolDesign}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            onClick={downloadBallon5}
                                        //href={download5Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data5}</span> : " "}
                    </Grid> : <Grid item xs={12} sm={6} md={4}>
                        {download5Docx ? <a target="_blank" href={download5Docx}><span className="link-filed">Download Tool Design </span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download Tool Design </button></span></a>}

                    </Grid>
                }



                {userDetails.roleId === 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="EM EA GF QM FR 056_ISIR.xlsm*"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={groupData === 3 || prevDataAdd2.groupId == 3}
                            key={fileEAEMKey}
                            onChange={handleIsirxlsm}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            //onClick={downloadBallon6 }
                                            href={download6Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data6}</span> : " "}
                    </Grid> : userDetails.roleId == 3 ?
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="EM EA GF QM FR 056_ISIR.xlsm*"
                                type="file"
                                id="desiredOPInKW"
                                name="desiredOPInKW"
                                className=""
                                fullWidth
                                disabled={groupData === 3 || prevDataAdd2.groupId == 3}

                                key={fileEAEMKey}
                                onChange={handleIsirxlsm}
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                className="p-0"
                                                target="_blank"
                                                onClick={downloadBallon6}
                                            //href={download6Docx}

                                            >
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}></TextField>
                            {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data6}</span> : " "}
                        </Grid> :
                        <Grid item xs={12} sm={6} md={4}>
                            {download6Docx ? <a target="_blank" href={download6Docx}><span className="link-filed">Download ISIR.xlsm </span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download Tool Design </button></span></a>}

                        </Grid>
                }

                {userDetails.roleId === 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Additional Uploads By Supplier (Zip file only!)"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={groupData === 3 || prevDataAdd2.groupId == 3}
                            key={fileAdditionalUploadKey}
                            onChange={handleAdditionalUploads}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            href={download24Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data24}</span> : " "}
                    </Grid> : userDetails.roleId == 3 ? <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Additional Uploads By Supplier (Zip file only!)"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={groupData === 3 || prevDataAdd2.groupId == 3}
                            key={fileAdditionalUploadKey}
                            onChange={handleAdditionalUploads}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            onClick={downloadBallon7}
                                        //href={download24Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data24}</span> : " "}
                    </Grid> :
                        <Grid item xs={12} sm={6} md={4}>
                            {download24Docx ? <a target="_blank" href={download24Docx}><span className="link-filed">Download Additional upload By Supplier</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download Additional upload by Supplier</button></span></a>}

                        </Grid>
                }

                {/* other than seso*/}
                {(userDetails.roleId === 6 || userDetails.roleId === 1 || userDetails.roleId === 2) && ((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)) ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Additional upload By CE (Zip file only!)"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={((((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)))}
                            // disabled={(groupData === 3 || prevDataAdd2.groupId == 3)  && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)}
                            key={fileAdditionalUploadByCeKey}
                            onChange={handleAdditionalUploadsByComponentEngineer}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            href={download38Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Databyce38}</span> : " "}
                    </Grid> : (userDetails.roleId === 3 && uploadTypeValue38) && ((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)) ?
                        <Grid item xs={12} sm={6} md={4}> <button className="link-filed1" onClick={downloadBallon16AddituionalUploadByce}>Additional upload by CE</button></Grid> :
                        <Grid item xs={12} sm={6} md={4}>

                            {download38Docx && (userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10) && ((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)) ? <a target="_blank" href={download38Docx}><span className="link-filed">Download Additional upload by CE</span></a> : <></>}

                        </Grid>
                }
                {/*for seso*/}
                {(userDetails.roleId === 6 || userDetails.roleId === 1 || userDetails.roleId === 2) && (groupData == 3 || prevDataAdd2.groupId == 3) ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Additional upload By CE(Zip file only!)"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            // disabled={((((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)))}
                            // disabled={(groupData === 3 || prevDataAdd2.groupId == 3)  && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)}
                            key={fileAdditionalUploadByCeKey}
                            onChange={handleAdditionalUploadsByComponentEngineer}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            href={download38Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Databyce38}</span> : " "}
                    </Grid> : ((userDetails.roleId === 3 && uploadTypeValue38) && (groupData == 3 || prevDataAdd2.groupId == 3)) ?
                        <Grid item xs={12} sm={6} md={4}> <button className="link-filed1" onClick={downloadBallon16AddituionalUploadByce}>Additional upload by CE</button></Grid> :
                        <Grid item xs={12} sm={6} md={4}>

                            {download38Docx && (userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10) && (groupData == 3 || prevDataAdd2.groupId == 3) ? <a target="_blank" href={download38Docx}><span className="link-filed">Download Additional upload by CE</span></a> : <></>}

                        </Grid>
                }





                {userDetails.roleId == 7 || userDetails.roleId == 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ? <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        label="EM EA GF QM FR 056_ISIR.pdf*"
                        type="file"
                        id="desiredOPInKW"
                        name="desiredOPInKW"
                        className=""
                        fullWidth
                        disabled={groupData === 3 || prevDataAdd2.groupId == 3}
                        key={fileEAEMPDFKey}
                        onChange={handleIsirPdf}
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        className="p-0"
                                        target="_blank"
                                        href={download7Docx}

                                    >
                                        <CloudDownloadIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}></TextField>
                    {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data7}</span> : " "}
                </Grid> : (userDetails.roleId === 3 && uploadTypeValue15) ? <Grid item xs={12} sm={6} md={4}> <button className="link-filed1" onClick={downloadBallon8isirPdf}>Download ISIR.pdf</button></Grid> : <Grid item xs={12} sm={6} md={4}>
                    {download7Docx ? <a target="_blank" href={download7Docx}><span className="link-filed">Download ISIR.pdf </span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download ISIR.pdf </button></span></a>}

                </Grid>
                }


                {userDetails.roleId === 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="EM EA GF QM FR 060_Quality Plan.pdf (IIP)"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth

                            disabled={groupData === 3 || prevDataAdd2.groupId == 3}
                            key={fileEAEM_QualityPlanKey}
                            onChange={handleQualityPlan}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            href={download8Docx}

                                            disabled={userDetails.roleId === 3 || userDetails.roleId === 4 || userDetails.roleId === 5 || userDetails.roleId === 7 || userDetails.roleId === 8 || userDetails.roleId === 9 || userDetails.roleId === 10 ? true : false}


                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data8}</span> : " "}
                    </Grid> : (userDetails.roleId === 3 && uploadTypeValue16) ? <Grid item xs={12} sm={6} md={4}> <button className="link-filed1" onClick={downloadBallon9qualityPlan}>Download Quality Plan</button></Grid> : <Grid item xs={12} sm={6} md={4}>
                        {download8Docx ? <a target="_blank" href={download8Docx}><span className="link-filed">Download Quality Plan(IIP)</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download Quality Plan(IIP)</button></span></a>}

                    </Grid>}

                {userDetails.roleId === 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="PPAP.xlsx for review"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={((((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5) && (prevDataAdd2.developmentTypeId == 2 || developmentData == 2)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)) || ((groupData == 3 || prevDataAdd2.groupId == 3) || (prevDataAdd2.developmentTypeId == 1 || developmentData == 1)))}
                            // disabled={groupData === 3 || prevDataAdd2.groupId == 3 || developmentData == 1 || prevDataAdd2.developmentTypeId == 1|| (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)}
                            key={filePPAPReview1Key}
                            onChange={handlePpapReview}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            href={download9Docx}
                                            //onClick={downloadBallon10PpapReview}
                                            disabled={userDetails.roleId === 4 || userDetails.roleId === 5 || userDetails.roleId === 7 || userDetails.roleId === 8 || userDetails.roleId === 9 || userDetails.roleId === 10 ? true : false}
                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>

                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data9}</span> : " "}
                    </Grid> : userDetails.roleId == 3 ? <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="PPAP.xlsx for review"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={((((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5) && (prevDataAdd2.developmentTypeId == 2 || developmentData == 2)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)) || ((groupData == 3 || prevDataAdd2.groupId == 3) || (prevDataAdd2.developmentTypeId == 1 || developmentData == 1)))}
                            // disabled={groupData === 3 || prevDataAdd2.groupId == 3 || developmentData == 1 || prevDataAdd2.developmentTypeId == 1|| (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)}
                            key={filePPAPReview1Key}
                            onChange={handlePpapReview}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            //href={download9Docx}
                                            onClick={downloadBallon10PpapReview}
                                            disabled={userDetails.roleId === 1 || userDetails.roleId === 4 || userDetails.roleId === 5 || userDetails.roleId === 7 || userDetails.roleId === 8 || userDetails.roleId === 9 || userDetails.roleId === 10 ? true : false}
                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>

                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data9}</span> : " "}
                    </Grid> : <Grid item xs={12} sm={6} md={4}>
                        {download9Docx ? <a target="_blank" href={download9Docx}><span className="link-filed">Download PPAP xlsx</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download PPAP xlsx</button></span></a>}

                    </Grid>}

                {userDetails.roleId === 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="PPAP.pdf for signoff"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={((((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5) && (prevDataAdd2.developmentTypeId == 2 || developmentData == 2)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)) || ((groupData == 3 || prevDataAdd2.groupId == 3) || (prevDataAdd2.developmentTypeId == 1 || developmentData == 1)))}
                            //disabled={groupData === 3 || prevDataAdd2.groupId == 3|| prevDataAdd2.developmentTypeId==1 ||  developmentData == 1|| (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)}
                            key={filePPAPSnoffKey}
                            onChange={handlePPAPSnoff}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            href={download10Docx}

                                            disabled={userDetails.roleId === 4 || userDetails.roleId === 5 || userDetails.roleId === 7 || userDetails.roleId === 8 || userDetails.roleId === 9 || userDetails.roleId === 10 ? true : false}
                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data10}</span> : " "}
                    </Grid> : userDetails.roleId == 3 ? <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="PPAP.pdf for signoff"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={((((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5) && (prevDataAdd2.developmentTypeId == 2 || developmentData == 2)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)) || ((groupData == 3 || prevDataAdd2.groupId == 3) || (prevDataAdd2.developmentTypeId == 1 || developmentData == 1)))}
                            //disabled={groupData === 3 || prevDataAdd2.groupId == 3|| prevDataAdd2.developmentTypeId==1 ||  developmentData == 1|| (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)}
                            key={filePPAPSnoffKey}
                            onChange={handlePPAPSnoff}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            //href={download10Docx}
                                            onClick={downloadBallon11PpapSignoff}

                                            disabled={userDetails.roleId === 1 || userDetails.roleId === 4 || userDetails.roleId === 5 || userDetails.roleId === 7 || userDetails.roleId === 8 || userDetails.roleId === 9 || userDetails.roleId === 10 ? true : false}
                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data10}</span> : " "}
                    </Grid> : <Grid item xs={12} sm={6} md={4}>
                        {download10Docx ? <a target="_blank" href={download10Docx}><span className="link-filed">Download PPAP signoff pdf</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download PPAP signoff pdf</button></span></a>}

                    </Grid>}

                {userDetails.roleId === 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Incoming Quality Report.pdf (Pilot Batch)"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={((((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)) || (groupData == 3 || prevDataAdd2.groupId == 3))}
                            //disabled={groupData === 3 || prevDataAdd2.groupId == 3  || (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)}
                            key={fileIncomingQreportKey}
                            onChange={handleIqualityReport}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            href={download11Docx}

                                            disabled={userDetails.roleId === 4 || userDetails.roleId === 5 || userDetails.roleId === 7 || userDetails.roleId === 8 || userDetails.roleId === 9 || userDetails.roleId === 10 ? true : false}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data11}</span> : " "}
                    </Grid> : userDetails.roleId == 3 ? <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Incoming Quality Report.pdf (Pilot Batch)"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={((((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)) || (groupData == 3 || prevDataAdd2.groupId == 3))}
                            //disabled={groupData === 3 || prevDataAdd2.groupId == 3  || (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)}
                            key={fileIncomingQreportKey}
                            onChange={handleIqualityReport}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            //href={download11Docx}
                                            onClick={downloadBallon12IncomingQualityReport}

                                            disabled={userDetails.roleId === 4 || userDetails.roleId === 5 || userDetails.roleId === 7 || userDetails.roleId === 8 || userDetails.roleId === 9 || userDetails.roleId === 10 ? true : false}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data11}</span> : " "}
                    </Grid> : <Grid item xs={12} sm={6} md={4}>
                        {download11Docx ? <a target="_blank" href={download11Docx}><span className="link-filed"> Download Quality Report(Pilot Batch)</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download Quality Report(Pilot Batch)</button></span></a>}

                    </Grid>}


                {hebburnRndLoc && userDetails.roleId == 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Approval from SPDL,Hebburn"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={((((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)) || (groupData == 3 || prevDataAdd2.groupId == 3))}
                            // disabled={((((prevgroupId == 1 || prevgroupId == 2 || prevgroupId == 4 || prevgroupId == 5) || (groupId == 1 || groupId == 2) || groupId == 4 || groupId == 5) && (!ponumber && !podate) && (userid == 1 || userid1 === 2)) || (prevgroupId == 3 || groupId == 3)) }
                            // disabled={(groupData == 3 || prevDataAdd2.groupId == 3)  && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate) && (userDetails.roleId == 1 || userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10)}
                            key={fileUploadByCeForHebbrnKey}
                            onChange={handleUploadByCeForHerren}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            href={download39Docx}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Databyce39}</span> : " "}
                    </Grid> : <></>
                }

                {(hebburnRndLoc && userDetails.roleId === 3 && uploadTypeValue39) ? <Grid item xs={12} sm={6} md={4}> <button
                    disabled={((((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)) || (groupData == 3 || prevDataAdd2.groupId == 3))} className="link-filed1" onClick={downloadBallon15Heburn}>Download Hebburn</button></Grid> :

                    hebburnRndLoc && download39Docx && (userDetails.roleId == 1 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10) ?

                        <a target="_blank" href={download39Docx}><span className="link-filed">Download Hebburn </span></a> : <></>
                    //<a><span className="link-filed"><button className="disabled-link" disabled>Download Hebburn template</button></span></a>
                }



                {userDetails.roleId === 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="CRER.pdf"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            disabled={((((groupData == 1 || groupData == 3 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 3 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)) || (groupData == 2 || prevDataAdd2.groupId == 2))}
                            // disabled={(prevDataAdd2.groupId == 2 || groupData === 2) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)}  //disable for npi is selected in group
                            key={fileCrerKey}
                            onChange={handleCrer}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            href={download12Docx}

                                            disabled={userDetails.roleId === 3 || userDetails.roleId === 4 || userDetails.roleId === 5 || userDetails.roleId === 7 || userDetails.roleId === 8 || userDetails.roleId === 9 || userDetails.roleId === 10 ? true : false}
                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data12}</span> : " "}
                    </Grid> : (userDetails.roleId === 3 && uploadTypeValue20 && ((groupData == 1 || groupData == 3 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 3 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5))) ? <Grid item xs={12} sm={6} md={4}> <button className="link-filed1" onClick={downloadBallon13isircrer}>Download CRER</button></Grid> : <Grid item xs={12} sm={6} md={4}>
                        {download12Docx ? <a target="_blank" href={download12Docx}><span className="link-filed">Download CRER</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled>Download CRER</button></span></a>}

                    </Grid>}

                {userDetails.roleId === 6 || userDetails.roleId === 1 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Component Approval form.pdf"
                            type="file"
                            id="desiredOPInKW"
                            name="desiredOPInKW"
                            className=""
                            fullWidth
                            //disabled={((groupData === 3 || prevDataAdd2.groupId === 3) && (!prevDataAdd2.purchaseorderNumber //&& !prevDataAdd2.purchaseorderDate))}
                            //disabled={((((groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) || (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)) && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)))}
                            disabled={(
                                (groupData === 3 || prevDataAdd2.groupId === 3) ||

                                ((
                                    (groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5) ||
                                    (prevDataAdd2.groupId == 1 || prevDataAdd2.groupId == 2 || prevDataAdd2.groupId == 4 || prevDataAdd2.groupId == 5)
                                )
                                    && (!prevDataAdd2.purchaseorderNumber && !prevDataAdd2.purchaseorderDate)

                                ))

                            }

                            key={fileComponentApprKey}
                            onChange={handleCompoAppr}
                            variant="filled"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            className="p-0"
                                            target="_blank"
                                            href={download13Docx}

                                            disabled={userDetails.roleId === 4 || userDetails.roleId === 5 || userDetails.roleId === 7 || userDetails.roleId === 8 || userDetails.roleId === 9 || userDetails.roleId === 10 ? true : false}

                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}></TextField>
                        {downloadTab2Data.length >= 1 ? <span className="fname">{fileT2Data13}</span> : " "}
                    </Grid> : (userDetails.roleId === 3 && uploadTypeValue21) ? <Grid item xs={12} sm={6} md={4}> <button className="link-filed1" onClick={downloadBallon14ComponentApprover}> Component Approval form</button></Grid> : <Grid item xs={12} sm={6} md={4}>
                        {download13Docx ? <a target="_blank" href={download13Docx}><span className="link-filed"> Component Approval form</span></a> : <a><span className="link-filed"><button className="disabled-link" disabled> Component Approval form</button></span></a>}

                    </Grid>}




                {userDetails.roleId == 1 || userDetails.roleId == 6 || userDetails.roleId == 3 || userDetails.roleId == 7 || userDetails.roleId === 2 ?
                    <Grid item xs={12} sm={6} md={4} >
                        <Button
                            className="pt-button--hero myid-login-btn-block"
                            onClick={handleUploadFileDataTabtwo}
                            disabled={afterClosingIsirActionDataState}
                        // disabled={takeCloseDFromApi==false}

                        >
                            Upload
                        </Button>
                    </Grid> : <></>

                }
                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />

            </Grid>





            <Remark isirNumberPassed={isirNumberPassed} prevDataAdd2={prevDataAdd2} afterClosingIsirActionDataStateInRemark={afterClosingIsirActionDataState} />

            <div className="po-upload">
                <Grid item xs={12}  >
                    <h3 className="title-heading">PO Text</h3>
                </Grid>

                <Grid container spacing={3} md={12}>
                    <Grid item xs={12} sm={6} md={3} >
                        <form className="upload-form">
                            <TextField
                                id="snapshotUpload"
                                variant="filled"
                                disabled={userDetails.roleId === 3 || userDetails.roleId === 4 || userDetails.roleId === 5 || userDetails.roleId === 7 || userDetails.roleId == 8 || userDetails.roleId === 9 || userDetails.roleId === 10}
                                label="SnapShot Upload"
                                inputProps={{ accept: "image/*", multiple: false }}
                                fullWidth
                                onChange={updateSnapShotHandler}
                                key={inputFileValue}
                                type="file"
                                InputLabelProps={{ shrink: true }}
                            />
                        </form>
                        <span>*formats allowed are .jpeg, .png, .jpg</span>
                    </Grid>

                    <Grid item xs={12} sm={2} md={1} >
                        <Button
                            onClick={handleUpload}
                            //disabled={!snapshotFile}
                            className="pt-button--primary button-submit" variant="contained">
                            SAVE
                        </Button>
                    </Grid>

                    {/*{*/}
                    {/*    poImgUrl ? <Grid item xs={12} sm={2} md={1}> <a className="pt-button--primary button-submit bord" variant="contained" target="_blank" href={poImgUrl}>view</a></Grid> : <></>*/}
                    {/*    //poText*/}
                    {/*}*/}

                    {userDetails.roleId === 3 ? <Grid item xs={12} sm={2} md={1}> <button onClick={handleClickOpenPo} className="pt-button--primary button-submit" variant="contained" disabled={!poRes} >VIEW</button></Grid> : (poImgUrl && (userDetails.roleId == 1 || userDetails.roleId == 2 || userDetails.roleId == 6 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10)) ? <Grid item xs={12} sm={2} md={1}> <a className="pt-button--primary button-submit bord" variant="contained" target="_blank" href={poImgUrl}>VIEW</a></Grid> : <></>}

                    <Grid item xs={12} sm={2} md={1}> <h4 style={{ margin: "7px" }}>{poImgUrl ? poImgName : ""}</h4></Grid>

                    {/*<Grid item xs={12} md={3}>*/}
                    {/*    <Grid className="view-flex">*/}
                    {/*        <Button*/}
                    {/*            onClick={handleClickOpen} */}
                    {/*            disabled={!uploadImageData}*/}
                    {/*            className="pt-button--secondary button-submit" variant="contained">*/}
                    {/*            View*/}
                    {/*        </Button>*/}
                    {/*         <h4 style={{ margin: "7px" }}>{uploadImageData ? prevDataAdd2?.automatedIsirnumber + "_PO Text" : ""}</h4>*/}
                    {/*    </Grid>*/}

                    {/*</Grid>*/}

                </Grid>






                {loader ? <Loading loaderText="Loading...." /> : <></>}

                {/*<Dialog fullWidth className="dialog-image" open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">*/}
                {/*    <DialogTitle id="form-dialog-title">Image Preview</DialogTitle>*/}
                {/*    <DialogContent>*/}
                {/*        {poImgUrl?.toString()?.length > 0 ? <img className="img-content" src={poImgUrl} alt="no test" /> : "Please Select a File"}*/}
                {/*    </DialogContent>*/}
                {/*    <DialogActions>*/}
                {/*        <Button className="pt-button--primary button-submit" variant="contained" onClick={handleCloseDialog} color="primary">*/}
                {/*            Close*/}
                {/*        </Button>*/}
                {/*    </DialogActions>*/}
                {/*</Dialog>*/}
                <Dialog
                    className="test"
                    open={openSnack}
                    onClose={handleCloseSnack}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <DialogTitle id="alert-dialog-title" onClose={handleCloseSnack}></DialogTitle>
                    <DialogContent >
                        <DialogContentText id="alert-dialog-description">

                            "Data Upload Successfully"

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="pt-button--primary button-submit" variant="contained" onClick={handleCloseSnack} color="primary">
                            Close
                        </Button>
                        {/*<Button onClick={handleClose} color="primary" autoFocus>*/}
                        {/*    Agree*/}
                        {/*</Button>*/}
                    </DialogActions>
                </Dialog>
                <Dialog className="snackgreen"
                    open={openSnackRed}
                    onClose={handleCloseSnackRed}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <DialogTitle id="alert-dialog-title" ></DialogTitle>
                    <DialogContent >
                        <DialogContentText id="alert-dialog-description">

                            "Data Upload Failed"

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="pt-button--primary button-submit" variant="contained" onClick={handleCloseSnackRed} color="primary">
                            Close
                        </Button>
                        {/*<Button onClick={handleClose} color="primary" autoFocus>*/}
                        {/*    Agree*/}
                        {/*</Button>*/}
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth className="dialog-image" open={openDialogPo} onClose={handleCloseDialogPo} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Image Preview</DialogTitle>
                    <DialogContent>
                        {poRes?.toString()?.length > 0 ? <img className="img-content" src={poRes} alt="no test" /> : "Please Select a File"}
                    </DialogContent>
                    <DialogActions>
                        <Button className="pt-button--primary button-submit" variant="contained" onClick={handleCloseDialogPo} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>

            <>

                <SnackbarCustom

                    open={openSnackbarInternal}

                    message={snackMessageInternal}

                    alertType={alertTypeInternal}

                    handleClose={handleCloseSnackbarInternal}

                />

            </>


        </div>



    );
}




const AddIsir = () => {
    //azure ad
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const azureToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);


    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const fileUploadDetails = useSelector(
        (state) => state.uploadFileValidationReducer.isDrawingsUploaded
    );
    const is3DUploaded = useSelector(
        (state) => state.is3DUploadedReducer.is3DUploaded
    );
    const isIsirPdfuploaded = useSelector(
        (state) => state.isIsirPdfuploadedReducer.isIsirPdfuploaded
    );
    console.log("isIsirPdfuploaded", isIsirPdfuploaded);
    const tfcActiondataState = useSelector((state) => state.istfcCheckedorNotReducer.tfcCheckedValue)

    const dfmActionDataState = useSelector((state) => state.isDfmCheckedorNotReducer.dfmCheckedValue)

    const trmActionDataState = useSelector((state) => state.istrmCheckedorNotReducer.trmCheckedValue)

    const ppapActionDataState = useSelector((state) => state.isppapCheckedorNotReducer.ppapCheckedValue)

    const iipActionDataState = useSelector((state) => state.isIipqpReducer.iipQpValue)
    console.log("iipActionDataState", iipActionDataState)

    const ppapXlsmActionDataState = useSelector((state) => state.ppapxlsmReducer.ppapxlsmValue)

    const incomingQualityReportValue = useSelector((state) => state.incomingQualityReportReducer.incomingQualityReportValue)

    const componentApprovalActionDataState = useSelector((state) => state.componentApprovalFormReducer.componentApprovalFormValue)


    const poTextActionDataState = useSelector((state) => state.isPoTextReducer.poTextValue)
    console.log("poTextActionDataState", poTextActionDataState);

    const uploadDrawingActionState = useSelector((state) => state.drawingUploadReducer.drawingUploadVlaue);

    const upload3DActionState = useSelector((state) => state.threeDUploadReducer.threeDUploadValue);

    const uploadIsirActionState = useSelector((state) => state.isirUploadReducer.isirUploadValue);

    const closeBtnActionDataState = useSelector((state) => state.closeBtnValueReducer.closeBtnValue);

    const afterClosingIsirActionDataState = useSelector((state) => state.afterClosingIsirReducer.afterClosingIsirValue);
    console.log("afterClosingIsirActionDataState", afterClosingIsirActionDataState);
    //const justCloseBtnPressActionDataState = useSelector((state) => state.justClosebtnPressReducer.justClosebtnPressValue);
    //console.log("justCloseBtnPressActionDataState", justCloseBtnPressActionDataState);

    const location = useLocation();
    const prevRowData = location?.state?.rowData ? location.state.rowData : "";
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [data, setData] = useState();
    const [submitedData, setsubmittedData] = useState();


    const [status, setStatus] = useState([]);
    const [groupState, setGroupState] = useState([]);
    const [isirWorkFlowNumber, setIsirWorkFlowNumber] = useState("");
    const [isirnoId, setIsirnoId] = useState("");
    const [created, setCreated] = useState("");
    const [modifies, setModified] = useState("");
    const [isirActive, setIsirActive] = useState("");
    const [groupData, setGroupData] = useState();
    const [spiridonNumber, setSpiridonNumber] = useState(0);
    const [drawingNumber, setDrawingNumber] = useState("");
    const [workFlowNumber, setWorkFlowNumber] = useState("");

    const [vendorCodeName, setVendorCodeName] = useState(prevRowData ? prevRowData.supplierCode : "");
    const [emailId, setEmailId] = useState([]);
    const [supplierId, setSupplierId] = useState(prevRowData ? prevRowData.supplierId : 0);
    const [supplierEmailIds, setSupplierEmailIds] = useState([]);
    const [dPemail, setDPemail] = useState("");
    const [newPrevEmail, setNewPrevEmail] = useState();

    const [getPreFe, setGetPreFe] = useState("");

    useEffect(() => {
        setGetPreFe(prevRowData.suppliercontactpersonemailIdanother);
    }, [])



    const [firstsEmailId, setfirstsEmailId] = useState(prevRowData ? prevRowData.suppliercontactpersonemailIdanother : "");
    const [secondsEmailId, setSecondsEmailId] = useState("");
    const [otherccEmails, setOtherccEmails] = useState();
    const [ssiCCemails, setssiCCemails] = useState(prevRowData ? prevRowData.cehod : []);
    const [rndEmailId, setRndEmailId] = useState(prevRowData ? prevRowData.rdcontactPersonId : null);
    const [rndLocation, setRndLocation] = useState([]);
    const [rndLocationData, setRndLocationData] = useState(prevRowData ? prevRowData.rdLocationId : null);
    const [developmentType, setDevelopmentType] = useState([]);
    const [developmentData, setDevelopementData] = useState();
    const [dateInputs, setDateInputs] = useState(null);//new Date()
    const [description, setDescription] = useState();
    const [drawingRevisionNumber, setDrawingRevisionNumber] = useState("");
    const [sesoNo, setSesoNo] = useState(prevRowData ? prevRowData.sesoNo : "");
    const [product, setProduct] = useState("");
    const [supplierName, setSupplierName] = useState(prevRowData ? prevRowData.supplierName : "");
    const [supplier, setSupplier] = useState([]);
    const [ssEmail, setSsEmail] = useState("");
    const [cc, setCc] = useState(prevRowData ? prevRowData.cehodSeso : "");
    const [openDate, setOpenDate] = useState("");
    const [closedDate, setClosedDate] = useState("");

    const [questNumber, setQuestNumber] = useState();
    const [requestRaised, setRequestRaised] = useState(prevRowData ? renderDateY(prevRowData.massReleasetoSQE) : null);
    const [dataTwo, setDataTwo] = useState(false);
    const [qInfo, setqInfo] = useState(prevRowData ? prevRowData.qinfoRequest : "");
    const [sqePerson, setSqePerson] = useState("");
    const [sprPerson, setSPRPerson] = useState("")
    const [oprPerson, setOPRPerson] = useState("");
    const [pilotQuantity, setPilotQunatity] = useState(prevRowData ? prevRowData.pilotQuantity : "");
    const [pilotRelease, setPilotRelease] = useState(prevRowData ? renderDateY(prevRowData.pilotlotrealeasetoSqe) : null);
    const [establishmentDone, setEstablishmentDone] = useState(prevRowData ? prevRowData.supplyChainEstablishmentDone : "false");
    const [supplyChainEstDate, setSupplyChainEstDate] = useState(prevRowData ? renderDateY(prevRowData.supplyChainEstablishmentDate) : null);
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(prevRowData ? prevRowData.purchaseorderNumber : null);
    const [purchaseOrderDate, setPurchaseOrderDate] = useState(prevRowData ? renderDateY(prevRowData.purchaseorderDate) : null);
    const [tabOne, setTabOne] = useState(false);
    const [tabTwo, setTabTwo] = useState(false);
    const [tab1, setTab1] = useState(false);
    const [tab2, setTab2] = useState(false);
    const [active, setActive] = useState(0);


    const [disable, setDisable] = useState(true);
    const handleChange = (e) => {
        setGroupData(e.target.value);

        //if (e.target.value == 3) {
        //    setDisable(false);

        //}


    }
    const [clearQst, setClearQst] = useState(prevRowData ? prevRowData.clearQuestNo : "");
    const handleClearQst = (e) => {
        //console.log("clearquest",e.target.value);
        setClearQst(e.target.value);
    }
    const [prevClearQuest, setprevClearQuest] = useState(prevRowData ? prevRowData.clearQuestNo : "");
    const handlePrevClearq = (e) => {
        console.log("prevclear", e.target.value);
        setprevClearQuest(e.target.value);
    }
    const handleSpiridonNumber = (e) => {
        setSpiridonNumber(parseInt(e.target.value));

    }
    const handleDrawingNumber = (e) => {
        setDrawingNumber(e.target.value);
    }
    const handlewflowNumber = (e) => {
        setWorkFlowNumber(e.target.value);

    }


    const handleEmailId = (e) => {

    }
    const handlerndEmail = (e) => {
        setRndEmailId(e.target.value);

    }

    const handleRndLocation = (e) => {


        setRndLocationData(e.target.value);
        getRdUsersData(e.target.value);

    }

    const handleDateChange = (e) => {
        setDateInputs(e.target.value);
    }
    const handleOpenDate = (e) => {
        setOpenDate(e.target.value);
    }
    const handleClosedDate = (e) => {
        setClosedDate(e.target.value);
    }
    const handleDescription = (e) => {
        setDescription(e.target.value)

    }

    const handleDrawingRebisionNumber = (e) => {
        setDrawingRevisionNumber(e.target.value);
    }
    const handleProduct = (e) => {
        setProduct(e.target.value);
    }
    const handleSESONo = (e) => {

        setSesoNo(e.target.value);
        if (groupData === 3 && e.target.value === null) {
            alert("SESO Number is required");
        }
    }

    if (prevRowData.supplierNameId) {

    }
    const [selectedSupplierCode, setSelectedSupplierCode] = useState(prevRowData.supplierNameId);
    function newHandleSupplierName(e) {

        const enteredVal = e.target.value;
        setSelectedSupplierCode(e.target.value);


        const suppCodeVal = supplier.find((x) => x.id == enteredVal);
        setSupplierName(suppCodeVal.name);


        setVendorCodeName(suppCodeVal != undefined ? suppCodeVal.code : "");



        let allEmails = emailId.filter((y) => { return y.code === suppCodeVal.code });
        setSupplierEmailIds(allEmails);


    }
    const [newVcode, setNewVcode] = useState();




    const handleFirstsEmailId = (e) => {

        setfirstsEmailId(e.target.value);
        let ssi = supplierEmailIds.find((item) => item.email === e.target.value);

        setSupplierId(ssi.supplierId);
        let cnt = ssi.ccEmails.length;
        setssiCCemails(ssi.ccEmails.slice(0, cnt));
        let duplicateEmail = supplierEmailIds.filter((item) => item.email === e.target.value);

        setDPemail(duplicateEmail);

    }

    useEffect(() => {


        if (prevRowData.supplierCode) {
            setSupplierEmailIds(emailId.filter(x => x.code === prevRowData.supplierCode));
        }

    }, [supplier, emailId]);










    const handleSecondsEmailId = (e) => {

        let ssi = supplierEmailIds.find((item) => item.email === e.target.value);


        if (e.target.value === firstsEmailId) {
            setSecondsEmailId(null);
        }
        else {
            setSecondsEmailId(e.target.value);
        }


    }
    const handleSsemail = (e) => {
        setSsEmail(e.target.value);
    }
    const handleCc = (e) => {
        setCc(e.target.value);
        console.log("cchod", e.target.value);

    }


    const handleRequestRaisedBy = (e) => {
        setRequestRaised(e.target.value);

    }


    const appId = localStorage.getItem("ApplicationId");

    const openDataTwo = () => {
        setDataTwo(true);
    }

    const openTabOne = () => {
        setTabOne(true);
        setTabTwo(false);
    }
    const openTabTwo = () => {
        setTabTwo(true);
        setTabOne(false);
    }

    const handleQinfo = (e) => {
        setqInfo(e.target.value)

    }
    const handleEQEPerson = (e) => {
        setSqePerson(e.target.value)
    }
    const handleOPRPerson = (e) => {
        setOPRPerson(e.target.value);
    }
    const handleSPRPerson = (e) => {
        setSPRPerson(e.target.value);
    }

    const handlePilotQunatity = (e) => {
        setPilotQunatity(e.target.value);

    }

    const handlePilotRelease = (e) => {
        setPilotRelease(e.target.value);

    }
    const handleEstablishment = (e) => {
        setEstablishmentDone(e.target.value);

    }
    const handleSupplyChainEstDate = (e) => {

        setSupplyChainEstDate(e.target.value);
    }
    const handlePurchaseOrderNumber = (e) => {
        setPurchaseOrderNumber(e.target.value);
    }
    const handlePurchaseOrderDate = (e) => {
        setPurchaseOrderDate(e.target.value);
    }
    const handleDevelopmentType = (e) => {
        setDevelopementData(e.target.value);

    }
    const [sqepValue, setSqepValue] = useState();
    const handleCsQE = (e) => {
        setSqepValue(e.target.value);
    }


    const [sprpValue, setSprpValue] = useState(prevRowData.sprPersonAssigned);

    const handleChangeSpr = (e) => {
        setSprpValue(e.target.value);

    }
    const [oprpValue, setOprpValue] = useState();
    const handleChangeOpr = (e) => {
        setOprpValue(e.target.value);

    }
    const toggleTab = (id) => {
        setActive(id)
        if (id === 1) {
            openTab1();
        }
        else {
            openTab2();
        }
    }
    const openTab1 = (e) => {

        setTab1(true);
        setTab2(false);
    };

    const handleClose = (e) => {
        setOpen(false);
    };
    const openTab2 = (e) => {

        setTab2(true);
        setTab1(false);
    };


    const [prevSppn, setPrevSPPN] = useState("");

    const [prevsetSNid, setPrevsetSNid] = useState();




    const [prevSupp, setPrevSupp] = useState(prevsetSNid);
    const [realPrevSname, setRealPrevSname] = useState(prevRowData.supplierName);
    const [prevVCode, setPrevVCode] = useState(prevRowData.supplierCode);
    const [prevAllEmailIds, setPrevAllEmailIds] = useState([]);
    const [prevSupId, setPrevSupId] = useState(prevRowData.supplierId);

    const handlePrevSupp = (e) => {
        setPrevSupp(e.target.value);

        const prevSupVal = supplier.find((x) => x.id == e.target.value);

        setRealPrevSname(prevSupVal.name);

        setPrevVCode(prevSupVal != undefined ? prevSupVal.code : "");
        let prevAllEmails = emailId.filter((item) => { return item.code === prevSupVal.code });
        setPrevAllEmailIds(prevAllEmails);

    }
    const [getPrevSupFemailId, setPrevSupFemailId] = useState(prevRowData.suppliercontactpersonemailIdanother);
    const prevSupFemailId = (e) => {
        setPrevSupFemailId(e.target.value);
        let prevSsi = prevAllEmailIds.find((item) => item.email === e.target.value);

        setPrevSupId(prevSsi.supplierId);


    }
    const [prevSSecondEmailId, setPrevSSecondEmailId] = useState(prevRowData ? prevRowData.supplierContactpersonEmailIDnotanother : "")
    const handleSSecondEmail = (e) => {
        setPrevSSecondEmailId(e.target.value);
    }



    const [prevNoId, setPrevNoid] = useState(prevRowData.automatedIsirnoId);
    const [prevModifiedBy, setPrevModifiedBy] = useState(prevRowData.modifiedBy);
    const [prevIsActive, setPrevIsActive] = useState(prevRowData.isActive);

    const [prevGrp, setPrveGrp] = useState(prevRowData.groupId);
    const handleChangeGroup = (e) => {

        setPrveGrp(e.target.value);
    }
    const [prevspirisonNumber, setPrevspirisonNumber] = useState(prevRowData.spiridonNumber);
    const handlePrevSpiri = (e) => {

        setPrevspirisonNumber(e.target.value);
    }
    const [prevNenaNumber, setPrevNenaNumber] = useState(prevRowData.nenaNoDrawingNumber);
    const handlePrevNena = (e) => {

        var data = e.target.value;

        setPrevNenaNumber(data.toString());

    }

    const [prevRevDate, setPrevRevDate] = useState(prevRowData.revisionDate);
    const handlePrevrevDate = (e) => {

        setPrevRevDate(e.target.value);
    }
    const [rdprevcation, setRdLprev] = useState(prevRowData.rdLocationId);
    const handleRDLpre = (e) => {

        setRdLprev(e.target.value);
    }
    const [prevRdCPerson, setPrevRdCPerson] = useState(prevRowData.rdcontactPersonId);
    const handlePrevRDCPerson = (e) => {

        setPrevRdCPerson(e.target.value);
    }


    const [prevDevelopment, setPrevDevelopment] = useState(prevRowData.developmentTypeId);
    const handlePrevDv = (e) => {


        setPrevDevelopment(e.target.value);
    }
    const [prevDescr, setPrevDecr] = useState(prevRowData.description);
    const handlePrevDesc = (e) => {

        setPrevDecr(e.target.value);
    }

    const [prevDrawing, setPrevDrawing] = useState(prevRowData.drawingRevisionNumber);
    const handlePrevDra = (e) => {

        setPrevDrawing(e.target.value);
    }
    const [prevProd, setPrevProd] = useState(prevRowData.product);
    const handlePrevProd = (e) => {

        setPrevProd(e.target.value);
    }
    const [prevSn, setPrevSn] = useState(prevRowData.sesoNo);

    const handlePrevSn = (e) => {
        console.log("hey", e.target.value);
        setPrevSn(e.target.value);
    }
    const [prevCC, setPrevCC] = useState(prevRowData.cehod);
    const handlePrevCC = (e) => {
        setPrevCC(e.target.value);
    }

    const [prevSqe, setPrevSqe] = useState(prevRowData.sqePersonAssigned);
    const handlePrevSqe = (e) => {
        setPrevSqe(e.target.value);
    }
    const [PrevSpr, setPrevSpr] = useState(prevRowData.sprPersonAssigned);
    const handlePrevSpr = (e) => {

        setPrevSpr(e.target.value);
    }

    const [prevOpr, setPrevOpr] = useState(prevRowData.oprPersonAssigned);
    const handlePrevOpr = (e) => {

        setPrevOpr(e.target.value);
    }

    const [prevPQ, setPrevPQ] = useState(prevRowData.pilotQuantity);
    const handlePrevPQ = (e) => {

        setPrevPQ(e.target.value);
    }

    const [prevQInfo, setPrevQInfo] = useState(prevRowData.qinfoRequest);
    const handlePrevQinfo = (e) => {

        setPrevQInfo(e.target.value);
    }

    const [qinfoUdate, setQinfoUdate] = useState(renderDateY(prevRowData.pilotlotrealeasetoSqe));
    const handlePrevQinfoUpdated = (e) => {

        setQinfoUdate(e.target.value);
    }

    const [prevMassReleaseD, setPrevMassReleaseD] = useState(prevRowData.massReleasetoSQE);
    const handlePrevMRsqe = (e) => {

        setPrevMassReleaseD(e.target.value);
    }

    const [supplyChainPrev, setSupplyChainPrev] = useState(prevRowData.supplyChainEstablishmentDone);
    const handlePrevSupplyChain = (e) => {

        setSupplyChainPrev(e.target.value);
    }

    const [prevSupplyEstD, setPrevSupplyEstD] = useState(prevRowData.supplyChainEstablishmentDate);
    const handlePrevSupplyEstDate = (e) => {

        setPrevSupplyEstD(e.target.value);
    }

    const [prevPurchaseN, setPrevPurchaseN] = useState(prevRowData.purchaseorderNumber);
    const handlePrevPurchaseN = (e) => {

        setPrevPurchaseN(e.target.value);
    }

    const [prevPurchaseD, setPrevPurchseD] = useState(prevRowData.purchaseorderDate);
    const handlePurcahseOrderDate = (e) => {

        setPrevPurchseD(e.target.value);
    }
    const [prevSupplierN, setPrevSupplierN] = useState(prevRowData.supplierName);
    const handlePrevSupplierN = (e) => {

        const enteredVal = e.target.value;
        setPrevSupplierN(e.target.value);
    }



    const handleChange1 = (event, newValue) => {
        //&& (prevRowData.statusId == 2 || prevRowData.statusId == 7 || prevRowData.isApprovedByCe==false ||prevRowData.isApprovedByCe==true ||prevRowData.isRejectedByCe==true || prevRowData.isRejectedByCe==false)
        if (newValue == 1) {
            handleDownloadTb1Data(isirWorkFlowNumber);
            if (uploadFt || getD12) { setValue(newValue); }

        }
        if (newValue == 0) {
            setValue(newValue);
        }
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const [getDinAddisir, setGetDinAddisir] = useState([]);
    const [toShowCloseBtn, setToShowCloseBtn] = useState(false);
    const [showCloseDate, setShowCloseDate] = useState(false);
    function getDinAddisirpage() {

        setIsLoader(true);

        getAllIsirDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setGetDinAddisir(response);

                setIsLoader(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoader(false);


            });

    };
    useEffect(() => {

        getDinAddisirpage();


    }, []);

    useEffect(() => {
        dispatch(poTextAction(false));
        dispatch(closeBtnAction(false));
        dispatch(afterClosingIsirAction(false));
        console.log("getDinAddisir", getDinAddisir);


        let filterIsirD = getDinAddisir.filter((item) => item.automatedIsirnumber === isirWorkFlowNumber);
        console.log("datafilter", filterIsirD);
        filterIsirD.map((x) => {
            if (x.isPotextUploaded) {
                console.log("sinya");
                dispatch(poTextAction(true));

            }
        })
        //&& y.isApprovedByCe !==true   y.statusId==4 y.statusName="Approved by CE" y.isApprovedByCe==true y.isRejectedByCe==false
        filterIsirD.map((y) => {
            if ((y.statusName == "Submitted" && y.statusId == 2 && y.massReleasetoSQE !== null) || (y.statusId == 4 && y.statusName == "Approved by CE" && y.isApprovedByCe == true && y.isRejectedByCe == false && y.massReleasetoSQE !== null)) {
                setToShowCloseBtn(true);
                dispatch(closeBtnAction(true));
            }
        })
        filterIsirD.map((y) => {
            if (y.statusName == "ISIR Closed" && y.statusId == 7 && y.massReleasetoSQE !== null) {
                setShowCloseDate(true);
                dispatch(afterClosingIsirAction(true));
            }
        })

    }, [getDinAddisir])

    const [getTb1d, setGetTb1d] = useState();
    const [uploadFt, setUploadFt] = useState(false);
    function handleDownloadTb1Data(isirNumberPassed) {

        getuploadedDatainDownLoadFile(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, isirNumberPassed)
            .then((response) => {

                setGetTb1d(response);
                let resD = response;
                if (resD.length > 0) {
                    setUploadFt(true);
                }

            })
            .catch((error) => {
                console.log(error);

            });
    }
    //handleDownloadTb1Data(isirWorkFlowNumber);
    const [getD12, setGetD12] = useState(false);

    function handleDownloadTb1Data(isirNumberPassed) {

        getuploadedDatainDownLoadFile(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, isirNumberPassed)
            .then((response) => {
                setGetD12(response);
            })
            .catch((error) => {
                console.log(error);

            });
    }
    useEffect(() => {

        handleDownloadTb1Data(isirWorkFlowNumber);

    }, [])



    function groupCategory() {

        getGroupcategory(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {

                setGroupState(response);

            })
            .catch((error) => {
                console.log(error);

            });
    }
    function rdLocation() {

        getRDLocation(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {

                setRndLocation(response);


            })
            .catch((error) => {
                console.log(error);

            });
    };
    function developmenType() {

        getDevelopment(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {

                setDevelopmentType(response);


            })
            .catch((error) => {
                console.log(error);

            });
    };
    function AutomatedWorkflowNumber() {
        getAutomatedWorkFlowNumber(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {

                setIsirWorkFlowNumber(response.objResult.automatedISIRNumber);

                setIsirnoId(response.objResult.automatedIsirnoId);
                setCreated(response.objResult.createdby);
                setModified(response.objResult.modifiedby);
                setIsirActive(response.objResult.isActive);
            })
            .catch((error) => {
                console.log(error);

            });
    };

    function getSName() {


        getSupplierName(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setSupplier(response);
            })
            .catch((error) => {
                console.log(error);

            });
    };
    const [rdGetRDemail, setRdGetRDemail] = useState([]);
    //getMasterAttachmentApiData
    function getWholeRDAllEmailId() {

        getWholeRDemailId(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                setRdGetRDemail(response);

            })
            .catch((error) => {
                console.log(error);

            });
    };
    useEffect(() => {
        getWholeRDAllEmailId();
    }, [])

    function getSEmail() {


        getSupplierEmail(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {

                setEmailId(response);
            })
            .catch((error) => {
                console.log(error);

            });
    };
    function statusDDL() {
        getStatus(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
            , userDetails.userId)
            .then((response) => {

                setStatus(response);

            })
            .catch((error) => {
                console.log(error);

            });
    };
    const [getRdData, setGetRdData] = useState([]);
    function getRdUsersData(rdlocationId) {

        getRdUsers(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, rdlocationId)

            .then((response) => {
                setGetRdData(response);

            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }


    useEffect(() => {

        if (prevRowData.rdLocationId) {

            let newrdemailsidsonPageload = rdGetRDemail.filter((y) => y.rdlocationId === prevRowData.rdLocationId);
            setGetRdData(newrdemailsidsonPageload)

        }
    }, [rdGetRDemail]);

    const [getSqePersonData, setGetEqePersonData] = useState([]);
    const handleGetSqe = (e) => {

        getSqe(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {

                setGetEqePersonData(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const [getSprPersonData, setGetSprPersonData] = useState([]);
    const handleGetSpr = (e) => {

        getSpr(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {

                setGetSprPersonData(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [getOprPersonData, setGetOprPersonData] = useState([]);
    const handleGetOpr = (e) => {

        getOpr(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {

                setGetOprPersonData(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    let closeData = {
        ClearQuestNo: clearQst,
        AutomatedISIRNoId: prevRowData.automatedIsirnoId ? prevNoId : isirnoId,
        automatedIsirnumber: isirWorkFlowNumber,
        CreatedBy: userDetails.userId,
        ModifiedBy: userDetails.userId,
        IsActive: prevRowData.isActive ? prevIsActive : isirActive,
        GroupId: prevRowData.groupId ? prevGrp : groupData,
        SpiridonNumber: prevRowData.spiridonNumber ? prevspirisonNumber : spiridonNumber,
        NENANoDrawingNumber: prevRowData.nenaNoDrawingNumber ? prevNenaNumber : drawingNumber,
        RevisionDate: prevRowData.revisionDate ? prevRevDate : dateInputs,
        SupplierCode: prevRowData.supplierCode ? prevVCode : vendorCodeName,
        SuppliercontactpersonemailIdanother: firstsEmailId,
        RdcontactPersonId: rndEmailId,
        RDLocationId: rndLocationData,
        DevelopmentTypeId: prevRowData.developmentTypeId ? prevDevelopment : developmentData,
        Description: prevRowData.description ? prevDescr : description,
        DrawingRevisionNumber: prevRowData.drawingRevisionNumber ? prevDrawing : drawingRevisionNumber,
        Product: prevRowData.product ? prevProd : product,
        //SESONo: prevRowData.sesoNo ? prevSn : sesoNo,
        SESONo: sesoNo,
        SupplierName: supplierName,
        SupplierContactpersonEmailIDnotanother: prevSSecondEmailId,
        CehodSeso: cc,
        Cehod: ssiCCemails,
        // Cehod: prevRowData.cehod ? prevCC : ssiCCemails,
        ComponentEngineerInitiator: userDetails.userFirstName,
        SQEPersonAssigned: prevSqe,
        SPRPersonAssigned: sprpValue,
        OPRPersonAssigned: prevOpr,
        QinfoRequest: qInfo,
        PilotQuantity: pilotQuantity,
        PilotlotrealeasetoSQE: pilotRelease,
        SupplyChainEstablishmentDone: establishmentDone,
        SupplyChainEstablishmentDate: supplyChainEstDate,
        PurchaseorderNumber: purchaseOrderNumber,
        PurchaseorderDate: purchaseOrderDate,
        MassReleasetoSQE: requestRaised,
        StatusId: 7,
        Requestopenedon: getTodayDateMoment(),
        Requestclosedon: null,
        UserId: userDetails.userId,
        Sender: userDetails.userFirstName,
        SupplierId: supplierId,
        SupplierNameId: selectedSupplierCode,
        Tfmcheckbox: tfcActiondataState,
        Trmcheckbox: trmActionDataState,
        Ppapcheckbox: ppapActionDataState,
        Dfmcheckbox: dfmActionDataState,
    }




    const [getClosure, setGetClosure] = useState(null);
    const [closureCloseDate, setClosureCloseDate] = useState(false);
    const [takeCloseDFromApi, setTakeCloseDFromApi] = useState();
    function getClosureCloseDate() {

        //handleCloseDialog();
        // setIsLoaderFeedback(true);

        addClosureDate(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, closeData)
            .then((response) => {
                console.log("response", response);
                if (response && response.objResult.massReleasetoSQE && response.objResult.requestclosedon) {
                    setClosureCloseDate(true);
                    setTakeCloseDFromApi(response.objResult.requestclosedon);
                    /* dispatch(justCloseBtnPress(true));*/

                }
                if (response.response) {
                    //setIsLoaderFeedback(false);
                    handleSnackOpen(response.responseMsg, "success");
                }

                setGetClosure(response);
            })
            .catch((error) => {
                console.log(error);

            });
    };
    useEffect(() => {
        /* dispatch(justCloseBtnPress(false));*/
        if (takeCloseDFromApi) {
            dispatch(afterClosingIsirAction(true));
        }
    }, [getClosure, takeCloseDFromApi]);


    let formData = {
        ClearQuestNo: clearQst,
        /// ClearQuestNo: prevRowData.clearQuestNo ? prevRowData.clearQuestNo : clearQst, //prevClearQuest  //prevRowData.clearQuestNo
        AutomatedISIRNoId: prevRowData.automatedIsirnoId ? prevNoId : isirnoId,
        automatedIsirnumber: isirWorkFlowNumber,
        CreatedBy: userDetails.userId,
        ModifiedBy: userDetails.userId,
        IsActive: prevRowData.isActive ? prevIsActive : isirActive,
        GroupId: prevRowData.groupId ? prevGrp : groupData,
        SpiridonNumber: prevRowData.spiridonNumber ? prevspirisonNumber : spiridonNumber,
        NENANoDrawingNumber: prevRowData.nenaNoDrawingNumber ? prevNenaNumber : drawingNumber,
        RevisionDate: prevRowData.revisionDate ? prevRevDate : dateInputs,
        SupplierCode: prevRowData.supplierCode ? prevVCode : vendorCodeName,

        SuppliercontactpersonemailIdanother: firstsEmailId,

        RdcontactPersonId: rndEmailId,
        RDLocationId: rndLocationData,
        DevelopmentTypeId: prevRowData.developmentTypeId ? prevDevelopment : developmentData,

        Description: prevRowData.description ? prevDescr : description,
        DrawingRevisionNumber: prevRowData.drawingRevisionNumber ? prevDrawing : drawingRevisionNumber,
        Product: prevRowData.product ? prevProd : product,
        SESONo: sesoNo,


        SupplierName: supplierName,

        SupplierContactpersonEmailIDnotanother: prevSSecondEmailId,

        // Cehod: prevRowData.cehod ? prevCC : ssiCCemails,
        CehodSeso: cc,
        Cehod: ssiCCemails,

        ComponentEngineerInitiator: userDetails.userFirstName,
        SQEPersonAssigned: prevSqe,
        SPRPersonAssigned: sprpValue,

        OPRPersonAssigned: prevOpr,
        QinfoRequest: qInfo,
        PilotQuantity: pilotQuantity,
        PilotlotrealeasetoSQE: pilotRelease,

        SupplyChainEstablishmentDone: establishmentDone,
        SupplyChainEstablishmentDate: supplyChainEstDate,
        PurchaseorderNumber: purchaseOrderNumber,
        PurchaseorderDate: purchaseOrderDate,
        MassReleasetoSQE: requestRaised,
        StatusId: 1,
        Requestopenedon: getTodayDateMoment(),

        Requestclosedon: null,

        UserId: userDetails.userId,
        Sender: userDetails.userFirstName,

        SupplierId: supplierId,
        SupplierNameId: selectedSupplierCode,
        Tfmcheckbox: tfcActiondataState,
        Trmcheckbox: trmActionDataState,
        Ppapcheckbox: ppapActionDataState,
        Dfmcheckbox: dfmActionDataState


    }

    let subData = {

        ClearQuestNo: clearQst, //prevRowData.clearQuestNo
        AutomatedISIRNoId: prevRowData.automatedIsirnoId ? prevNoId : isirnoId,
        automatedIsirnumber: isirWorkFlowNumber,

        CreatedBy: userDetails.userId,
        ModifiedBy: userDetails.userId,

        IsActive: prevRowData.isActive ? prevIsActive : isirActive,
        GroupId: prevRowData ? prevGrp : groupData,
        SpiridonNumber: prevRowData ? prevspirisonNumber : spiridonNumber,
        NENANoDrawingNumber: prevRowData ? prevNenaNumber : drawingNumber,
        RevisionDate: prevRowData ? prevRevDate : dateInputs,
        SupplierCode: prevRowData.supplierCode ? prevVCode : vendorCodeName,

        SuppliercontactpersonemailIdanother: firstsEmailId,
        RdcontactPersonId: rndEmailId,
        RDLocationId: rndLocationData,

        DevelopmentTypeId: prevRowData ? prevDevelopment : developmentData,

        Description: prevRowData ? prevDescr : description,
        DrawingRevisionNumber: prevRowData ? prevDrawing : drawingRevisionNumber,
        Product: prevRowData ? prevProd : product,
        SESONo: sesoNo,
        SupplierName: supplierName,

        SupplierContactpersonEmailIDnotanother: prevSSecondEmailId,
        CehodSeso: cc,
        Cehod: ssiCCemails,

        ComponentEngineerInitiator: userDetails.userFirstName,
        SQEPersonAssigned: prevSqe,
        SPRPersonAssigned: sprpValue,

        OPRPersonAssigned: prevOpr,
        QinfoRequest: qInfo,
        PilotQuantity: pilotQuantity,
        PilotlotrealeasetoSQE: pilotRelease,

        SupplyChainEstablishmentDone: establishmentDone,
        SupplyChainEstablishmentDate: supplyChainEstDate,
        PurchaseorderNumber: purchaseOrderNumber,
        PurchaseorderDate: purchaseOrderDate,
        MassReleasetoSQE: requestRaised,
        StatusId: 2,
        Requestopenedon: getTodayDateMoment(),

        Requestclosedon: null,

        UserId: userDetails.userId,
        Sender: userDetails.userFirstName,

        SupplierId: prevRowData.supplierId ? prevSupId : supplierId,
        SupplierNameId: selectedSupplierCode,
        Tfmcheckbox: tfcActiondataState,
        Trmcheckbox: trmActionDataState,
        Ppapcheckbox: ppapActionDataState,
        Dfmcheckbox: dfmActionDataState


    }






    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };

    const [isLoader, setIsLoader] = useState(false);

    const [dialogErrorText, setDialogErrorText] = useState("");

    const [openValidationDialog, setOpenValidationDialog] = useState("");


    const handleClickOpenDialogValidation = (text) => {
        setDialogErrorText(text);
        setOpenValidationDialog(true);
    };
    const [isLoaderFeedback, setIsLoaderFeedback] = useState(false);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [openDialog, setOpenDialog] = useState(false);
    const handleSavedraftDataOne = (e) => {
        e.preventDefault();
        handleCloseDialog();
        setIsLoader(true);
        //setIsLoaderFeedback(true);
        //if (formData.GroupId === 2 && formData.ClearQuestNo === "") {
        //    setIsLoader(false);
        //    alert("Please Fill Clear Quest  Number");
        //    setIsLoader(false);
        //    return;
        //}


        SaveasDraft(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
            , formData)
            .then((response) => {
                formData = response.objResult;
                if (response.response) {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "success");
                    dispatch(tfcAction(false));
                    dispatch(trmAction(false));
                    dispatch(ppapAction(false));
                    dispatch(dfmAction(false));
                    setIsLoaderFeedback(false);

                }
                else {
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoaderFeedback(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoaderFeedback(false);
            });
    }
    const [showCloseWhileSavingD, setShowCloseWhileSavingD] = useState(false);
    const handleSubmitDataOne = (e) => {
        e.preventDefault();
        handleCloseDialog();
        setIsLoaderFeedback(true);
        if (subData.GroupId == 3 && (subData.SESONo == "" && prevRowData.sesoNo == "")) {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please fill SESO Number", "error");
            return;
        }
        else if (subData.GroupId !== 3 && subData.SuppliercontactpersonemailIdanother == "") {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill Supplier Email Id", "error");
            return;

        }
        else if (subData.GroupId !== 3 && subData.SupplierContactpersonEmailIDnotanother == "") {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill Supplier Email Id", "error");
            return;
        }
        else if (subData.GroupId == null || subData.GroupId == undefined) {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill Group Data", "error");
            return;

        }
        else if (subData.SpiridonNumber == null || subData.SpiridonNumber == undefined || subData.SpiridonNumber == 0) {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill Spiridon Number", "error");
            return;
        }
        else if (subData.NENANoDrawingNumber == null || subData.NENANoDrawingNumber == undefined || subData.NENANoDrawingNumber == 0) {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill NENANo/DrawingNumber Number", "error");
            return;

        }
        else if (subData.GroupId !== 3 && subData.SupplierName === "") {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill Supplier Name", "error");
            return;

        }
        else if (subData.RevisionDate === "" || subData.RevisionDate == null) {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill Revision Date", "error");
            return;

        }
        else if (subData.GroupId !== 3 && (subData.RDLocationId === "" || subData.RDLocationId == null || subData.RDLocationId == undefined)) {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill R&D Location", "error");
            return;

        }
        else if (subData.GroupId !== 3 && (subData.RdcontactPersonId === "" || subData.RdcontactPersonId == null || subData.RdcontactPersonId == undefined)) {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill R&D Contact person Email Id", "error");
            return;

        }
        else if (subData.DevelopmentTypeId == null || subData.DevelopmentTypeId == undefined) {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill Development Type", "error");
            return;

        }
        else if (subData.Description == "" || subData.Description == undefined) {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill Description", "error");
            return;

        }
        else if (subData.DrawingRevisionNumber == null || subData.DrawingRevisionNumber == undefined || subData.DrawingRevisionNumber == "") {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please Fill Drawing Revision Number", "error");
            return;

        }
        else if (subData.GroupId == 2 && (subData.ClearQuestNo == "" && prevClearQuest == "")) {
            setIsLoaderFeedback(false);
            handleSnackOpen("Please fill Clear Quest Number", "error");
            return;

        }
        // else if (subData.GroupId !== 3 && subData.DevelopmentTypeId == 1 ) {
        //if (!prevRowData && !fileUploadDetails && !is3DUploaded) {

        //    setIsLoaderFeedback(false);
        //    handleSnackOpen("Upload Drawing.pdf and 3d  are  mandatory files", "error");
        //    return;
        //}
        //else if (prevRowData && !upload3DActionState && !uploadDrawingActionState) {

        //    setIsLoaderFeedback(false);
        //    handleSnackOpen("Upload Drawing.pdf and 3d  are  mandatory files", "error");
        //    return;
        //}
        // }
        else if (subData.DevelopmentTypeId !== 1 && subData.GroupId !== 3) {
            if (!prevRowData && !fileUploadDetails && !is3DUploaded) {
                setIsLoaderFeedback(false);
                handleSnackOpen("Upload Drawing.pdf and 3d are  mandatory files", "error");
                return;
            }

            else if (prevRowData && !upload3DActionState && !uploadDrawingActionState) {
                setIsLoaderFeedback(false);
                handleSnackOpen("Upload Drawing.pdf and 3d are  mandatory files", "error");
                return;
            }
        }







        submit(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, subData)
            .then((response) => {

                subData = response.objResult;
                if (response && response.objResult.statusId == 2 && response.objResult.massReleasetoSQE != null) {
                    setShowCloseWhileSavingD(true);
                    dispatch(closeBtnAction(true));
                }

                if (response.response && response.objResult.groupId !== 0 && response.objResult.spiridonNumber !== 0) {

                    setIsLoaderFeedback(false);
                    handleSnackOpen(response.responseMsg, "success");
                } else {
                    setIsLoaderFeedback(false);
                    handleSnackOpen(response.responseMsg, "error");

                }
            })
            .catch((error) => {
                setIsLoaderFeedback(false);
                handleSnackOpen("Exception : " + error, "error");

            });

    }

    //console.log("prevRowDatapurchaseorderDate", prevRowData.purchaseorderDate);

    const [dataTb2, setDataTb2] = useState();
    function handleDownloadTb2Data(isirNumberPassed) {

        getuploadedDatainDownLoadFile(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, isirNumberPassed)
            .then((response) => {
                console.log("resp", response);

                setDataTb2(response);
            })
            .catch((error) => {
                console.log(error);

            });
    }
    // const [uploadImageData, setUploadImageData] = useState("");
    // const [poTextImgData, setPoTextImgData] = useState();

    useEffect(() => {
        if (prevRowData) {
            handleDownloadTb2Data(prevRowData.automatedIsirnumber);


        }
        else {
            handleDownloadTb2Data(isirWorkFlowNumber);
        }
    }, [isirWorkFlowNumber, prevRowData.automatedIsirnumber])



    const [ccData, setCCdata] = useState([]);
    const [prevSupplierName, setPrevSupplierName] = useState("");
    const [prevSupplierEmailFirst, setPrevSupplierEmailFirst] = useState("");
    const [prevSupplierEmailIdSecond, setPrevSupplierEmailIdSecond] = useState("");
    const [prevSupplierRevisionDate, setPrevSupplierRevisionDate] = useState("");
    const [prevQinfoUpDate, setPrevQinfoUpDate] = useState("");
    const [prevMassReleasesqe, setprevMassReleasesqe] = useState("");
    const [prevSupplyChainestDate, setPrevSupplyChainestDate] = useState("");
    const [prevSupplyPurchaseDate, setPrevSupplyPurchaseDate] = useState("");

    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(iipAction(false));

        setCCdata(prevRowData.cehod);
        groupCategory();
        rdLocation();
        developmenType();
        getSName();
        getSEmail();
        handleGetSqe();
        handleGetSpr();
        handleGetOpr();
    }, [])

    useEffect(() => {
        if (prevRowData.automatedIsirnumber) {
            setIsirWorkFlowNumber(prevRowData.automatedIsirnumber);

        }
        else {
            AutomatedWorkflowNumber();
        }
    }, []);


    function renderDateY(value) {
        let DateFormat = !value ? null : moment(value).format('YYYY-MM-DD');
        return DateFormat;

    }
    console.log("prevRowData", prevRowData);


    function renderDateYC(value) {
        let DateFormatClosedD = !value ? null : moment(value).format('MM/DD/YYYY');
        return DateFormatClosedD;
    }


    function renderDatePrevYC(value) {
        let DateFormatClosedDprev = !value ? null : moment(value).format('MM/DD/YYYY');
        return DateFormatClosedDprev;
    }




    return (
        <div>
            <div className="main-heading">
                <div className="left-div">
                    <h3 className="heading">Add ISIR fields</h3>

                </div>
                <div className="date">
                    <div container spacing={2} className="request-part">
                        <div item className="date-id" xs={12} sm={5} md={2}>
                            <h4 style={{ background: "var(--btn-hero-bg-hover)" }}>
                                Request Opened On:{getTodayDate()}

                            </h4>
                        </div>
                        <div item className="date-id" xs={12} sm={5} md={2}>
                            <h4 style={{ background: "var(--btn-hero-bg-hover)" }}>
                                Request Closed On:{prevRowData.statusName == "ISIR Closed" && prevRowData.statusId == 7 && prevRowData.massReleasetoSQE != null ? renderDatePrevYC(prevRowData.requestclosedon) : closureCloseDate ? renderDateYC(takeCloseDFromApi) : null}

                            </h4>
                        </div>
                        <div item className="date-id" xs={12} sm={5} md={2}>
                            <h4 style={{ background: "var(--btn-hero-bg-hover)" }}>

                                ISIR NUMBER:  {prevRowData ? prevRowData?.automatedIsirnumber : isirWorkFlowNumber}

                            </h4>
                        </div>




                    </div>

                </div>

            </div>





            <Grid container spacing={2} className="fields">



                {prevRowData ?
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">Group*</InputLabel>
                            <Select
                                labelId="Group"
                                id="group"
                                name="group"
                                onChange={handleChangeGroup}
                                value={prevGrp}
                                disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                input={<BootstrapInput />}
                            >
                                {groupState.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid> :
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">Group*</InputLabel>
                            <Select
                                labelId="Group"
                                id="group"
                                name="group"
                                onChange={handleChange}
                                value={groupData}
                                disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                input={<BootstrapInput />}
                            >
                                {groupState.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}

                            </Select>

                        </FormControl>
                    </Grid>

                }

                {prevRowData ? <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Spiridon Number*
                        </InputLabel>
                        <BootstrapInput
                            name="spirinumber"
                            type="number"
                            onChange={handlePrevSpiri}
                            disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            value={prevspirisonNumber}

                        />
                    </FormControl>
                </Grid> :
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Spiridon Number*
                            </InputLabel>
                            <BootstrapInput
                                name="spirinumber"
                                type="number"
                                onChange={handleSpiridonNumber}
                                disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                value={prevRowData ? prevRowData.spiridonNumber : spiridonNumber}

                            />
                        </FormControl>
                    </Grid>}


                {
                    prevRowData ?
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    NENA No./Drawing Number*
                                </InputLabel>
                                <BootstrapInput id="Sender-Name"
                                    name="senderName"
                                    onChange={handlePrevNena}
                                    value={prevNenaNumber}
                                    disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                    required

                                />
                            </FormControl>
                        </Grid> : <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    NENA No./Drawing Number*
                                </InputLabel>
                                <BootstrapInput id="Sender-Name"
                                    name="senderName"
                                    onChange={handleDrawingNumber}
                                    value={prevRowData ? prevRowData.nenaNoDrawingNumber : drawingNumber}
                                    disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                    required

                                />
                            </FormControl>
                        </Grid>
                }




                {prevRowData ? <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Revision Date*
                        </InputLabel>
                        <BootstrapInput
                            type="date"
                            id="bootstrap-input"
                            name="revisiondate"
                            onChange={handlePrevrevDate}

                            disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}

                            value={renderDateY(prevRevDate)}
                        />
                    </FormControl>
                </Grid> : <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Revision Date*
                        </InputLabel>
                        <BootstrapInput
                            type="date"
                            id="bootstrap-input"
                            name="revisiondate"
                            onChange={handleDateChange}
                            disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            value={dateInputs}
                        />
                    </FormControl>
                </Grid>}


                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">Supplier Name*</InputLabel>
                        <Select
                            labelId="supplier name"
                            id="severity"
                            name="suppliernm"
                            onChange={newHandleSupplierName}

                            value={selectedSupplierCode}
                            disabled={groupData === 3 || prevRowData.groupId == 3 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}

                            input={<BootstrapInput />}
                        >
                            {supplier.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}


                        </Select>
                    </FormControl>

                </Grid>









                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Supplier Code*
                        </InputLabel>

                        <BootstrapInput id="Sender-Name"
                            name="vendorCodeName"
                            disabled

                            value={vendorCodeName}
                            disabled={userDetails.roleId == 2 || prevRowData.groupId == 3 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10 || !disable}
                            required

                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">Supplier contact person email Id*</InputLabel>
                        <Select
                            labelId=" SuppliercontactpersonemailId*"
                            id="scontactpersonemail"
                            name="scontpemail"

                            value={firstsEmailId}
                            disabled={groupData === 3 || prevRowData.groupId == 3 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            onChange={handleFirstsEmailId}
                            input={<BootstrapInput />}
                        >
                            {supplierEmailIds.map((item) => (
                                <MenuItem key={item.id} value={item.email}>
                                    {item.email}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input"> R&D Location*</InputLabel>
                        <Select
                            labelId="developmenttype"
                            id="rdloction"
                            name="rdlocation"
                            onChange={handleRndLocation}
                            value={rndLocationData}
                            disabled={groupData === 3 || prevRowData.groupId == 3 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            input={<BootstrapInput />}
                        >
                            {rndLocation.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">R&D Contact Person EmailId*</InputLabel>
                        <Select
                            labelId="r&dcontactpersonemailid"
                            id="randcontactpersonemailid"
                            name="r&dcontactperson"
                            onChange={handlerndEmail}
                            value={rndEmailId}
                            disabled={groupData === 3 || prevRowData.groupId == 3 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            input={<BootstrapInput />}
                        >
                            {getRdData.map((item) => (
                                <MenuItem key={item.id} value={item.userId}>
                                    {item.userEmail}
                                </MenuItem>
                            ))}

                        </Select>

                    </FormControl>
                </Grid>






                {prevRowData ? <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">Development Type*</InputLabel>
                        <Select
                            labelId="developmenttype"
                            id="developmentType"
                            name="developmenttype"
                            onChange={handlePrevDv}
                            value={prevDevelopment}
                            disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            input={<BootstrapInput />}
                        >
                            {developmentType.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> :
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">Development Type*</InputLabel>
                            <Select
                                labelId="developmenttype"
                                id="developmentType"
                                name="developmenttype"
                                onChange={handleDevelopmentType}
                                value={developmentData}
                                disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                input={<BootstrapInput />}
                            >
                                {developmentType.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }


                {prevRowData ? <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            Description*

                        </InputLabel>
                        <BootstrapInput id="Sender-Name"
                            name="rndLocation"
                            onChange={handlePrevDesc}
                            disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            value={prevDescr}

                        />

                    </FormControl>
                </Grid> : <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            Description*

                        </InputLabel>
                        <BootstrapInput id="Sender-Name"
                            name="rndLocation"
                            onChange={handleDescription}
                            disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            value={prevRowData ? prevRowData.description : description}

                        />

                    </FormControl>
                </Grid>}



                {prevRowData ? <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            Drawing Revision Number*

                        </InputLabel>
                        <BootstrapInput id="Sender-Name"
                            name="drawingrevisionnumber"
                            onChange={handlePrevDra}
                            disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            value={prevDrawing}

                        />

                    </FormControl>
                </Grid> : <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            Drawing Revision Number*

                        </InputLabel>
                        <BootstrapInput id="Sender-Name"
                            name="drawingrevisionnumber"
                            onChange={handleDrawingRebisionNumber}
                            disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            value={prevRowData ? prevRowData.drawingRevisionNumber : drawingRevisionNumber}

                        />

                    </FormControl>
                </Grid>}



                {prevRowData ? <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            Product

                        </InputLabel>
                        <BootstrapInput id="Sender-Name"
                            name="product"
                            onChange={handlePrevProd}
                            disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            value={prevProd}
                        />

                    </FormControl>
                </Grid> : <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            Product

                        </InputLabel>
                        <BootstrapInput id="Sender-Name"
                            name="product"
                            onChange={handleProduct}
                            disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            value={prevRowData ? prevRowData.product : product}
                        />

                    </FormControl>
                </Grid>}






                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            {groupData === 3 || prevRowData.groupId == 3 ? "SESO No.*" : "SESO No."}

                        </InputLabel>
                        <BootstrapInput id="Sender-Name"
                            name="sesono"
                            onChange={handleSESONo}
                            value={sesoNo}
                            disabled={groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5 || prevRowData.groupId == 1 || prevRowData.groupId == 2 || prevRowData.groupId == 4 || prevRowData.groupId == 5 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            required
                        />
                    </FormControl>
                </Grid>




                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Supplier contact person email Id2*
                        </InputLabel>
                        <BootstrapInput
                            name="spirinumber"

                            onChange={handleSSecondEmail}

                            value={prevSSecondEmailId}
                            disabled={prevRowData.groupId == 3 || groupData === 3 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}

                        />
                    </FormControl>
                </Grid>



                {
                    (groupData == 1 || groupData == 2 || groupData == 4 || groupData == 5 || prevRowData.groupId == 1 || prevRowData.groupId == 2 || prevRowData.groupId == 4 || prevRowData.groupId == 5) && (groupData !== 3 || prevRowData.groupId !== 3) ? <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input" >
                                CC(CE HOD)*
                            </InputLabel>
                            <BootstrapInput id="Sender-Name"
                                name="cchod"
                                //onChange={handleCcForOtherThanseso}
                                //prevRowData ? ccData 
                                value={ssiCCemails}
                                disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            />

                        </FormControl>
                    </Grid> : <></>
                }
                {
                    groupData == 3 || prevRowData.groupId == 3 ? <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input" >
                                CC(CE HOD)*

                            </InputLabel>
                            <BootstrapInput id="Sender-Name"
                                name="cehod1"
                                onChange={handleCc}
                                //prevRowData ? ccData 
                                value={cc}
                                disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            />

                        </FormControl>

                    </Grid> : <></>
                }













                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            {groupData === 2 || prevRowData.clearQuestNo == 2 ? "ClearQuest No.*" : "ClearQuest No."}

                        </InputLabel>
                        <BootstrapInput id="Sender-Name"
                            name="sesono"
                            onChange={handleClearQst}
                            value={clearQst}
                            disabled={groupData == 1 || groupData == 3 || groupData == 4 || groupData == 5 || prevRowData.clearQuestNo == 1 || prevRowData.clearQuestNo == 3 || prevRowData.clearQuestNo == 4 || prevRowData.clearQuestNo == 5 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                            required
                        />

                    </FormControl>
                </Grid>




                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            Component Engineer/Initiator*
                        </InputLabel>
                        <BootstrapInput id="Sender-Name"
                            name="clearquesnumber"

                            value={prevRowData ? prevRowData.componentEngineerInitiator : userDetails.userFirstName}
                            disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                        />

                    </FormControl>
                </Grid>


            </Grid>
            <Grid className="data-part" >
                {userDetails.roleId === 1 || userDetails.roleId === 2 || userDetails.roleId === 4 || userDetails.roleId === 5 || userDetails.roleId === 6 || userDetails.roleId === 7 || userDetails.roleId === 8 || userDetails.roleId === 9 || userDetails.roleId === 10 ?
                    <div className="datatwo-part" onClick={(iipActionDataState && poTextActionDataState) || (prevRowData.groupId == 3 && poTextActionDataState) ? openDataTwo : " "} >Data Two</div> : <></>}



                {dataTwo ? <Grid container spacing={2}>
                    <Grid container spacing={2} className="mandatory-fields">

                        {prevRowData ?
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">SQE Person Assigned</InputLabel>
                                    <Select
                                        labelId="sqeperson"
                                        id="sqep"
                                        name="sqepa"
                                        onChange={handlePrevSqe}

                                        value={prevSqe}
                                        disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                        input={<BootstrapInput />}
                                    >
                                        {getSqePersonData.map((item) => (
                                            <MenuItem key={item.id} value={item.userId}>
                                                {item.userEmail}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>

                            </Grid> : <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">SQE Person Assigned</InputLabel>
                                    <Select
                                        labelId="sqeperson"
                                        id="sqep"
                                        name="sqepa"
                                        onChange={handleCsQE}

                                        value={sqepValue}
                                        disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                        input={<BootstrapInput />}
                                    >
                                        {getSqePersonData.map((item) => (
                                            <MenuItem key={item.id} value={item.userId}>
                                                {item.userEmail}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>

                            </Grid>}



                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input"> SPR Person Assigned</InputLabel>
                                <Select
                                    labelId="SPR Person Assigned"
                                    id="sprperson"
                                    name="sprperson"
                                    onChange={handleChangeSpr}
                                    value={sprpValue}
                                    disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 9 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 10}
                                    input={<BootstrapInput />}
                                >
                                    {getSprPersonData.map((item) => (
                                        <MenuItem key={item.id} value={item.userId}>
                                            {item.userEmail}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                        </Grid>




                        {prevRowData ? <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input"> OPR Person Assigned</InputLabel>
                                <Select
                                    labelId="OPR Person Assigned"
                                    id="oprperson"
                                    name="oprperson"
                                    onChange={handlePrevOpr}
                                    value={prevOpr}
                                    disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                    input={<BootstrapInput />}
                                >
                                    {getOprPersonData.map((item) => (
                                        <MenuItem key={item.id} value={item.userId}>
                                            {item.userEmail}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                        </Grid> : <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input"> OPR Person Assigned</InputLabel>
                                <Select
                                    labelId="OPR Person Assigned"
                                    id="oprperson"
                                    name="oprperson"
                                    onChange={handleChangeOpr}

                                    value={oprpValue}
                                    disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                    input={<BootstrapInput />}
                                >
                                    {getOprPersonData.map((item) => (
                                        <MenuItem key={item.id} value={item.userId}>
                                            {item.userEmail}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                        </Grid>}




                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input" >
                                    Pilot Quantity

                                </InputLabel>
                                <BootstrapInput id="Sender-Name"
                                    name="pilotQuantity"
                                    onChange={handlePilotQunatity}
                                    value={pilotQuantity}
                                    disabled={userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                />

                            </FormControl>
                        </Grid>



                    </Grid>


                    <Grid container spacing={2} className="spr-fields">

                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input" >
                                    Q-info Request

                                </InputLabel>
                                <BootstrapInput id="Sender-Name"
                                    name="qinfor"
                                    onChange={handleQinfo}
                                    value={qInfo}
                                    disabled={userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 6 || userDetails.roleId == 7 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                />

                            </FormControl>
                        </Grid>


                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    Qinfo Updated Date
                                </InputLabel>
                                <BootstrapInput
                                    type="date"
                                    id="bootstrap-input"
                                    name="qud"
                                    onChange={handlePilotRelease}
                                    value={pilotRelease}
                                    disabled={userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 6 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                />
                            </FormControl>
                        </Grid>

                        {
                            prevRowData.groupId == 3 && prevRowData.developmentTypeId == 1 && (prevRowData.purchaseorderDate !== null && prevRowData.purchaseorderNumber !== null) ?
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            Mass Release Date
                                        </InputLabel>
                                        <BootstrapInput
                                            type="date"
                                            id="bootstrap-input"
                                            name="requestraisedby"
                                            onChange={handleRequestRaisedBy}
                                            value={requestRaised}
                                            disabled={(userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 6 || userDetails.roleId == 9 || userDetails.roleId == 10)}
                                        //disabled={(!incomingQualityReportValue && !ppapXlsmActionDataState) || userDetails.roleId == 1 || userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 6 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                        />
                                    </FormControl>
                                </Grid> : <></>}
                        {
                            prevRowData.groupId == 3 && prevRowData.developmentTypeId == 2 && (prevRowData.purchaseorderDate !== null && prevRowData.purchaseorderNumber !== null) ?
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            Mass Release Date
                                        </InputLabel>
                                        <BootstrapInput
                                            type="date"
                                            id="bootstrap-input"
                                            name="requestraisedby"
                                            onChange={handleRequestRaisedBy}
                                            value={requestRaised}
                                            disabled={(userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 6 || userDetails.roleId == 9 || userDetails.roleId == 10)}
                                        //disabled={(!incomingQualityReportValue && !ppapXlsmActionDataState) || userDetails.roleId == 1 || userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 6 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                        />
                                    </FormControl>
                                </Grid> : <></>}

                        {(prevRowData.groupId == 1 || prevRowData.groupId == 2 || prevRowData.groupId == 4 || prevRowData.groupId == 5) && (prevRowData.developmentTypeId == 1) && (prevRowData.purchaseorderDate !== null && prevRowData.purchaseorderNumber !== null) ?
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        Mass Release Date
                                    </InputLabel>
                                    <BootstrapInput
                                        type="date"
                                        id="bootstrap-input"
                                        name="requestraisedby"
                                        onChange={handleRequestRaisedBy}
                                        value={requestRaised}
                                        disabled={(userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 6 || userDetails.roleId == 9 || userDetails.roleId == 10)}
                                    />
                                </FormControl>
                            </Grid> : <></>
                        }
                        {(prevRowData.groupId == 1 || prevRowData.groupId == 2 || prevRowData.groupId == 4 || prevRowData.groupId == 5) && prevRowData.developmentTypeId == 2 && (incomingQualityReportValue !== null && ppapXlsmActionDataState !== null && prevRowData.purchaseorderDate !== null && prevRowData.purchaseorderNumber !== null) ?
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        Mass Release Date
                                    </InputLabel>
                                    <BootstrapInput
                                        type="date"
                                        id="bootstrap-input"
                                        name="requestraisedby"
                                        onChange={handleRequestRaisedBy}
                                        value={requestRaised}
                                        disabled={(userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 6 || userDetails.roleId == 9 || userDetails.roleId == 10)}
                                    />
                                </FormControl>
                            </Grid> : <></>
                        }






                    </Grid>


                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input"> Supply Chain Establishment Done?</InputLabel>
                            <Select
                                labelId="supplychainestdone"
                                id="estdone"
                                name="estcdone"
                                onChange={handleEstablishment}
                                value={establishmentDone}
                                disabled={userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 6 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 10}
                                input={<BootstrapInput />}
                            >

                                <MenuItem value="true">Yes</MenuItem>
                                <MenuItem value="false">No</MenuItem>

                            </Select>
                        </FormControl>

                    </Grid>








                    {establishmentDone == "true" ? <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Supply Chain Establishment Date
                            </InputLabel>
                            <BootstrapInput
                                type="date"
                                id="bootstrap-input"
                                name="supplyChainEstDate"
                                onChange={handleSupplyChainEstDate}
                                disabled={userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 6 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 10}
                                value={supplyChainEstDate}
                            />
                        </FormControl>
                    </Grid> : <></>}





                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input" >
                                Purchase Order Number

                            </InputLabel>
                            <BootstrapInput id="Sender-Name"
                                name="purchaseOrderNumber"
                                onChange={handlePurchaseOrderNumber}
                                value={purchaseOrderNumber}
                                disabled={userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 6 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9}
                            />

                        </FormControl>
                    </Grid>





                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Purchase Order Date
                            </InputLabel>
                            <BootstrapInput
                                type="date"
                                id="bootstrap-input"
                                name="purchaseOrderDate"
                                onChange={handlePurchaseOrderDate}
                                disabled={userDetails.roleId === 6 || userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 6 || userDetails.roleId == 5 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9}
                                value={purchaseOrderDate}

                            />
                        </FormControl>
                    </Grid>





                </Grid> : <></>}

            </Grid>

            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange1}
                        indicatorColor="primary"
                        textColor="secondary"
                        variant="fullWidth"
                        aria-label="full width tabs example">
                        <Tab label="Tab One" {...a11yProps(0)} />
                        <Tab label="Tab Two" {...a11yProps(1)} />


                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={value}
                    onChangeIndex={handleChangeIndex}>

                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <PartOne isirNumberPassed={isirWorkFlowNumber} groupData={groupData} developmentData={developmentData} prevDataAdd={prevRowData} formData={formData} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <PartTwo isirNumberPassed={isirWorkFlowNumber} prevDataAdd2={prevRowData} groupData={groupData} developmentData={developmentData} />
                    </TabPanel>

                </SwipeableViews>
            </div>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />



            <div className="btn-section">

                {userDetails.roleId == 1 || userDetails.roleId == 6 || userDetails.roleId == 2 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10 ?

                    <DialogActions>
                        <Button
                            className="pr-button--secondary bulk-upload-submit-btn submit"
                            onClick={handleSavedraftDataOne}
                            disabled={afterClosingIsirActionDataState || (prevRowData.statusName == "ISIR Closed" && prevRowData.statusId == 7 && prevRowData.massReleasetoSQE != null)}

                        >
                            Save as Draft
                        </Button>
                    </DialogActions> : <></>}

                {userDetails.roleId == 1 || userDetails.roleId == 6 || userDetails.roleId == 2 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10 ?
                    <DialogActions>
                        <Button
                            className="pr-button--secondary bulk-upload-submit-btn submit"
                            onClick={handleSubmitDataOne}
                            disabled={afterClosingIsirActionDataState || (prevRowData.statusName == "ISIR Closed" && prevRowData.statusId == 7 && prevRowData.massReleasetoSQE != null)}
                        >
                            Submit
                        </Button>
                    </DialogActions> : <></>}



                {userDetails.roleId == 6 || userDetails.roleId == 1 || userDetails.roleId == 2 || userDetails.roleId == 8 ? <DialogActions>
                    <Button
                        className="pr-button--secondary bulk-upload-submit-btn submit"
                        onClick={getClosureCloseDate}
                        disabled={!closeBtnActionDataState}
                    //disabled={toShowCloseBtn == false || showCloseWhileSavingD==false}   //&& !userDetails.roleId === 8
                    >
                        Close
                    </Button>
                </DialogActions> : <></>}



            </div>
            {isLoaderFeedback ? <Loading loaderText="Loading...." /> : <></>}



        </div>);
};
export default AddIsir;