//import AddIsir from "./AddlSir";
import { useSelector } from "react-redux";
const AddIsirRoot = ({ formData, subData,props }) => {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const handledata = (e) => {
        console.log("data from addisirroot", formData);
        console.log("propsdata", props);
    }

    return (
        <div>hii this is shivani
            
            <button onClick={handledata}>see data</button>
            <h4 style={{ background: "var(--btn-hero-bg-hover)" }}>
                ISIR Number  : {
                    
                    props?.rowData ?.automatedIsirnumber
        
                
                }
            </h4>

        </div>
   
    );
}
export default AddIsirRoot;