import { baseApiURL,apiKeys, user } from "../../Utilities/Utility";
import {
    authUserAction,
    storeUserDetailsAction,
    saveAccessTokenAction,
    errorMessageAction,
    saveUserIdAction, 
    saveBearerTokenAction} from "../Actions/actions";
let gID,
    generatedToken = "";

//export function authenticateUserBasedOnValidToken(dispatch, newToken, gID) {
//    var formdataPart1 = new FormData();
//    formdataPart1.append("Token", newToken);


//    const options = {
//        method: "post",
//        processData: false,
//        mimeType: "multipart/form-data",
//        contentType: false,
//        body: formdataPart1,
//    };

//    return fetch(baseApiURL + "/Login/AuthenticateUser", options)
//        .then((response) => response.json())
//        .then((data) => {
//            if (typeof data.result.response == "string") {
//                var userDataNull = {
//                    userData: {
//                        gid: "",
//                        roleId: 0,
//                        roleName: "",
//                        userEmail: "",
//                        userFirstName: "",
//                        userLastName: "",
//                        userId: 0,
//                    },
//                };
//                dispatch(storeUserDetailsAction(userDataNull));
//                return true;
//            } else if (data.result.objResult.roleId != null && data.result.response) {
//                console.log("data", data);
//                dispatch(saveUserIdAction(data.result.objResult));
//                //dispatch(storeUserDetailsAction(data.result.response));
//                //dispatch(saveAccessTokenAction(data.result.tokenString));
//                window.localStorage.setItem(
//                    "response",
//                    JSON.stringify(data.result.response)
//                );
//                return true;
//            } else if (data?.result?.response?.roleId == null) {
//                var userDataNull = {
//                    gid: null,
//                    roleId: null,
//                    roleName: null,
//                    userEmail: null,
//                    userFirstName: null,
//                    userLastName: null,
//                    userId: null,
//                };
//                dispatch(storeUserDetailsAction(userDataNull));
//                return false;
//            } else {
//                return false;
//            }
//        })
//        .catch((error) => {
//            return false;
//        });
//}

//authenticateUserBasedOnValidToken-->authenticateUserWithMyID
export function authenticateUserBasedOnValidToken(dispatch, accessToken) {
    var formdataPart1 = new FormData();
    formdataPart1.append("Token", accessToken);


    const options = {
        method: "post",
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        body: formdataPart1,
    };
    return fetch(baseApiURL + "/Login/AuthenticateUser", options)
        .then((response) => response.json())
        .then((data) => {
            if (typeof data.result.response == "string") {
                var userDataNull = {
                    userData: {
                        gid: "",
                        roleId: 0,
                        roleName: "",
                        userEmail: "",
                        userFirstName: "",
                        userLastName: "",
                        userId: 0,
                    },
                };
                dispatch(storeUserDetailsAction(userDataNull));
                return true;
            } else if (data.result.response.roleId != null) {
                dispatch(saveUserIdAction(data.result.uid));
                dispatch(storeUserDetailsAction(data.result.response));
                dispatch(saveBearerTokenAction(data.result.tokenString));
                window.localStorage.setItem(
                    "response",
                    JSON.stringify(data.result.response)
                );
                return true;
            } else if (data?.result?.response?.roleId == null) {
                var userDataNull = {
                    gid: null,
                    roleId: null,
                    roleName: null,
                    userEmail: null,
                    userFirstName: null,
                    userLastName: null,
                    userId: null,
                };
                dispatch(storeUserDetailsAction(userDataNull));
                return false;
            } else {
                return false;
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
            return {
                response: false,
                responseMsg: "Exception occured while authenticating user.",
            };
        });
    // const responseJson = await response.json();
    // dispatch(storeUserDetailsAction(responseJson.result));
}


//export function authenticateUserWithMyID(dispatch, newToken, data) {
//    const options = {
//      method: "post",
//      mode: "cors",
//      headers: {
//        Accept: "application/json, text/plain, */*",
//        "Content-Type": "application/json",
//        "Access-Control-Allow-Credentials": true,
//        Authorization: "Bearer " + newToken,
//      },
//      body: JSON.stringify(data),
//    };
  
//    return fetch(baseApiURL + "/Login/AuthenticateUser", options)
//      .then((response) => response.json())
//      .then((data) => {
//        if (data.result.response) {
//          dispatch(storeUserDetailsAction(data.result.objResult));
//          return data.result;
//        } else {
//          return data.result;
//        }
//      })
//      .catch((error) => {
//          console.log("Error : ", error);
//        return {
//          response: false,
//          responseMsg: "Exception occured while authenticating user.",
//        };
//      });
//  }
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
}
  export function generateJwtForAPI(dispatch) {
    const systemUser = {
        userName: atobSecure(user.userName),
        password: atobSecure(user.password),
    };
    const options = {
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify(systemUser),
    };
  
    return fetch(baseApiURL + "/Login/ValidateRequest", options)
      .then((response) => response.json())
      .then((data) => {
        generatedToken = data.result.objResult;
        dispatch(saveAccessTokenAction(generatedToken));
        return data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
        return {
          response: false,
          responseMsg: "Exception occured while generating access token to database.",
        };
      }
      );
  }
  export function authenticateExternalUser(newToken, userData) {
    const options = {
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: "Bearer " + newToken,
      },
      body: JSON.stringify(userData),
    };
  
    return fetch(baseApiURL + "/Login/AuthenticateExternalUser", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.result) {
          return data.result;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  }
 export function generateTokenOnly(dispatch) {
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify(apiKeys),
  };
  return fetch(baseApiURL + "/Login/ValidateRequest", options)
    .then((res) => res.json())
    .then((data) => {
      generatedToken = data?.result?.objResult;
      dispatch(saveAccessTokenAction(generatedToken));
      return generatedToken;
    })
    .catch((error) => {
      return error;
    });
}
  export function validateTheUserBasedOnApi(dispatch, auth) {
    const options = {
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify(apiKeys),
    };
  
    return fetch(baseApiURL + "/Login/ValidateRequest", options)
      .then((res) => res.json())
      .then((data) => {
        if (auth !== undefined && auth.user) {
          const arr = auth.user.profile.sub.split("|");
          gID = arr && arr.length ? arr[1] : "";
          dispatch(authUserAction(gID));
        }
        generatedToken = data?.result?.objResult;
        dispatch(saveAccessTokenAction(generatedToken));
  
        return data?.result;
      })
      .catch((error) => dispatch(errorMessageAction(error)));
  }
  export function forgotUserPassword(siteToken, data) {
    const options = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + siteToken,
      },
      body: JSON.stringify(data),
    };
    return fetch(
      baseApiURL + "/Login/ForgotPassword",
      options
    )
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }
  export function ResetUserPassword(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Login/ChangePassword", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }