import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";


export function GetAttachmentDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, complaintNumber) {
    const options = {
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId, },
       /* params: { userId: userId, complaintNumber: complaintNumber },*/
        params: { complaintNumber: complaintNumber },
    };
    return axios.get(
        baseApiURL + "/Admin/GetAttachmentsDetails/",
        options
    )
        .then((response) => {
            return response.data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function AddAttachmentDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
        body: formData,
    };
    return fetch(baseApiURL + "/Admin/UploadMultiple", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function DeleteAttachmentDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {

    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        body: JSON.stringify(formData),
    };
    return fetch(baseApiURL + "/Admin/DeleteAttachments", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function DeleteDisciplineAttachments(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, attachmentId) {
    const options = {
        method: "put",
        url: baseApiURL + "/Discipline/DeleteAttachments",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        /*params: { userId: userId, attachmentId: attachmentId },*/
        params: { attachmentId: attachmentId },
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function AddSnapShotDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        body: formData,
    };
    return fetch(baseApiURL + "/Admin/UploadSnapshot", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetNonConformityDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetAllNonConformities",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        /*params: { userId: userId },*/
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result.objResult;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}

export function PostComplaintDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,

        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostNotification", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetComplaintDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId, },
    };
    return fetch(
        baseApiURL + "/Admin/GetAllComplaints",
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetComplaintDetailsBySupplier(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, supplierId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId, },
    };
    return fetch(
        baseApiURL + "/Admin/GetComplaintsbySupplier?supplierId=" + supplierId,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetComplaintDetailsByComponent(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId, },
    };
    return fetch(
        baseApiURL + "/Admin/GetComponentComplaints",
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function PostDescriptionDetails(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostDescriptionDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function SaveDraft(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/SaveDraft", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function DownloadFile(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        responseType: 'arraybuffer',
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/downloadfile/", options)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function GetSnapShotDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, complaintNumber) {
    const options = {
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId, },
       /* params: { userId: userId, complaintNumber: complaintNumber },*/
        params: { complaintNumber: complaintNumber },
    };
    return axios.get(
        baseApiURL + "/Admin/GetSnapshotView/",
        options
    )
        .then((response) => {
            return response.data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetKPIDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, fromDate, toDate, supplierId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetKPI'sDetails",
        headers: {
            Authorization: "Bearer " + siteToken,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            userId: userId,
        },
        params: {
            /*userId: userId,*/
            supplierId: supplierId,
            fromDate: fromDate,
            toDate: toDate
        },
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}