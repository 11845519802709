import React from "react";
import { Link } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    IconButton,
    InputBase,
    Tooltip,
    Zoom,
} from "@material-ui/core";
import SideDrawer from "../SideDrawer/SideDrawer";
import SearchIcon from "@material-ui/icons/Search";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SiemensLogoGreen from "../../assets/images/siemens-logo-petrol.svg";
import { useSelector } from "react-redux";
import "./Header.scss";
import { spocEmail } from "../../Utilities/Utility";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    appStyle: {
        backgroundColor: `var(--white)`,
        color: `var(--dark-blue)`,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    search: {
        display: "none",
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: `transparent`,
    },
    inputRoot: {
        color: "inherit",
        backgroundColor: "var(--light-sand)",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
        [theme.breakpoints.only("xs")]: {
            width: "2ch",
        },
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
}));



export default function Header() {
    const classes = useStyles();
    const { logout } = useAuth0();
    let userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    let custHeader = useSelector(
        (state) => state.custHeaderReducer
    );
    const headerType = useSelector((state) => state.custHeaderReducer);

    function logoutUser() {
        //var newurl =
        //  window.location.protocol +
        //  "//" +
        //  window.location.host +
        //  window.location.pathname;
        /* window.history.pushState({ path: newurl }, "", `/`);*/
        localStorage.clear();
        sessionStorage.clear();
        //caches.keys().then((names) => {
        //  names.forEach((name) => {
        //    caches.delete(name);
        //  });
        /*  });*/

        logout({ logoutParams: { returnTo: window.location.origin } });
    }

    return (
        <div className={classes.grow}>
            <AppBar position="fixed" className={classes.appStyle}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        style={{ margin: "0 0 5px 0" }}
                    >
                        <SideDrawer />
                    </IconButton>

                    {headerType === "defaultHeader" ? (
                        <Link to="/">
                            <img
                                src={SiemensLogoGreen}
                                alt="Siemens"
                                className="official-logo"
                            />
                        </Link>
                    ) : (
                        <Link to="/">
                            <img
                                src={SiemensLogoGreen}
                                alt="SiemensLogo"
                                className="official-logo"
                            />
                        </Link>
                    )}

                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon className="header-search" />
                        </div>
                        <InputBase
                            placeholder="Search"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div>
                    <div className={classes.grow}>
                        <h2
                            className="text-center"
                            style={{
                                padding: "0 8rem 0 0",
                            }}
                        >{custHeader}</h2>
                    </div>
                    <div className={classes.sectionDesktop}></div>
                    {

                        <>
                            <h4>{userDetails.role}</h4>
                            <Link to="/contactus" >
                                <Tooltip
                                    title="Having issues? Contact us"
                                    TransitionComponent={Zoom}
                                    enterDelay={100}
                                    leaveDelay={100}
                                >
                                    <IconButton
                                        className="header-icon"
                                    >
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </Link>

                            {userDetails.roleId !== 3 ? (<Link to="/Supplier_Quality_Management_8D_User Manual.pdf" target="_blank" download>
                                <Tooltip
                                    title="Download User Guide"
                                    TransitionComponent={Zoom}
                                    enterDelay={100}
                                    leaveDelay={100}
                                >
                                    <IconButton
                                        className="header-icon"
                                    >
                                        <MenuBookOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Link>) : (<></>)}

                            <Tooltip
                                title="Logout"
                                TransitionComponent={Zoom}
                                enterDelay={100}
                                leaveDelay={100}

                            >
                                <IconButton className="header-icon" onClick={logoutUser}>
                                    <ExitToAppIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                        // headerType === "defaultHeader" && (
                        // )
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}
