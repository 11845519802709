import { useEffect, useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import Loading from "../../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { GetD2Details, PostD2Details } from "../../../Redux/APIs/api_Disciplines";
import { disciplineAction } from "../../../Redux/Actions/actions";
export default function DIscipline2(props) {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const discpline2Data = useSelector(
        (state) => state.disciplineReducer.disciplineData
    );
    //azure ad
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const azureToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [isLoaderdraft, setIsLoaderDraft] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function getD2Details() {
        GetD2Details(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, props.rowData[0].complaintNumber)
            .then((response) => {
                response.isDraft = true;
                if (response.length > 0) {
                    discpline2Data.discipline2 = true;
                    dispatch(disciplineAction(discpline2Data));
                }
            })
            .catch((error) => {
                console.log(error)
            });

    };
    useEffect(() => {
        getD2Details()
    }, [])
    function AddD2Details() {
        const inputs = {
            ComplaintNum: props?.rowData[0].complaintNumber,
            WhatIsTheNonConformitySpecificationIsNotMet: props.rowData[0]?.detaildescription[0]?.detailedDescription,
            "WhyIsItAProblemWhatAreTheEffectsOfTheFailure": props.rowData[0]?.detaildescription[1]?.detailedDescription,
            "WhoDetectedTheDefect": props.rowData[0]?.detaildescription[2]?.detailedDescription,
            "HowHasItBeenDetected": props.rowData[0]?.detaildescription[3]?.detailedDescription,
            "WhereHasItBeenDetected": props.rowData[0]?.detaildescription[4]?.detailedDescription,
            "OtherUsefulInformationToShare": props.rowData[0]?.detaildescription[5]?.detailedDescription
        }
        setIsLoaderDraft(true);
        PostD2Details(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
, inputs)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoaderDraft(false);

                    getD2Details();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoaderDraft(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoaderDraft(false);
            });

    }

    return (

        <Grid container spacing={2} md={12}>
            <Grid item xs={12} className="details-center">
                <h3 className="title-heading">Description of Non-Conformity</h3>
            </Grid>
            <Grid className="align-ctr" item xs={12} sm={3} md={6}>
                <span>What is the non-conformity ?(Which specification is not met?)</span>
            </Grid>
            <Grid item xs={12} sm={3} md={6}>
                <TextField
                    className="mt-1"
                    value={props.rowData.length > 0 ? props.rowData[0].detaildescription[0]?.detailedDescription : ""}
                    fullWidth
                    disabled
                    multiline
                    maxRows={2}
                    name="whereitisdetected"
                    type="text"
                    id="rejectQuantity"

                />
            </Grid>
            <Grid className="align-ctr" item xs={12} sm={3} md={6}>
                <span>Why is it a problem ?(What are the effects of the failure?)</span>
            </Grid>
            <Grid item xs={12} sm={3} md={6}>
                <TextField
                    className="mt-1"
                    name="businessSegment"
                    value={props.rowData.length > 0 ? props.rowData[0]?.detaildescription[1]?.detailedDescription : ""}
                    multiline
                    maxRows={2}
                    fullWidth
                    disabled
                    id="rejectQuantity"

                />
            </Grid>
            <Grid className="align-ctr" item xs={12} sm={3} md={6}>
                <span>Who detected the defect ?</span>
            </Grid>
            <Grid item xs={12} sm={3} md={6}>
                <TextField
                    className="mt-1"
                    multiline
                    maxRows={2}
                    value={props.rowData.length > 0 ? props.rowData[0]?.detaildescription[2]?.detailedDescription : ""}
                    name="businessSegment"
                    fullWidth
                    disabled

                    id="rejectQuantity"

                />
            </Grid>
            <Grid className="align-ctr" item xs={12} sm={3} md={6}>
                <span>How has it been detected ?</span>
            </Grid>
            <Grid item xs={12} sm={3} md={6}>
                <TextField
                    className="mt-1"
                    multiline
                    maxRows={2}
                    value={props.rowData.length > 0 ? props.rowData[0]?.detaildescription[3]?.detailedDescription : ""}
                    name="businessSegment"
                    fullWidth
                    disabled
                    id="rejectQuantity"

                />
            </Grid>

            <Grid className="align-ctr" item xs={12} sm={3} md={6}>
                <span>Other useful information to share</span>
            </Grid>
            <Grid item xs={12} sm={3} md={6}>
                <TextField
                    className="mt-1"
                    fullWidth
                    multiline
                    disabled
                    maxRows={2}
                    value={props.rowData.length > 0 ? props.rowData[0]?.detaildescription[4]?.detailedDescription : ""}
                    name="businessSegment"
                    type="text"
                    id="rejectQuantity"

                />
            </Grid>

            {userDetails.roleId === 3 ? <Grid container spacing={3} className="submit-btn" style={{ margin: "10px" }}>
                <Grid item>
                    <Button className="pt-button--primary button-submit" disabled={props?.rowData[0].status === "8D Completed" || props?.rowData[0]?.status === "Approved"} onClick={AddD2Details} variant="contained">

                        Save
                    </Button>
                </Grid>

                <>
                    <SnackbarCustom
                        open={open}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleClose}
                    />
                </>
            </Grid> : <Grid></Grid>}
            {isLoaderdraft ? <Loading loaderText="Loading...." /> : <></>}
        </Grid>
    )
}