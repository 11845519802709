import { combineReducers } from "redux";

let tokenInitialState = {
  token: "",
};
const saveTokenReducer = (state = tokenInitialState, action) => {
  switch (action.type) {
    case "saveAccessToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
const saveBearerTokenReducer = (state = tokenInitialState, action) => {
    switch (action.type) {
        case "saveBearerToken": {
            return {
                token: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};


let userDataInitialState = {
  userData: {
    gid: "",
    roleId: 0,
    roleName: "",
    userEmail: "",
    userFirstName: "",
    userLastName: "",
        userId: 0,
        supplierLoginType: 0,
    roleList:[],
  },
};
const storeUserDetailsReducer = (
  state = userDataInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "storeUserData": {
      return {
        userData: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};
let userInitialState = {
  gid: "",
};
const authUserReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case "myIDLogin": {
      return {
        gid: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let isUpdateResponse = { response: 0 };
const updateResponseReducer = (state = isUpdateResponse, action) => {
  switch (action.type) {
    case "updatePotential": {
      return { response: action.payLoad + state.response };
    }
    case "updateBudget": {
      return { response: action.payLoad + state.response };
    }
    case "updateManualIndirect": {
      return { response: action.payLoad + state.response };
    }
    default:
      return state;
  }
};

let disciplineIntialState= {
  discipline1: "",
  discipline2: "",
  discipline3: "",
  discipline4: "",
  discipline41: "",
  discipline42: "",
  discipline5: "",
  discipline51: "",
  discipline6: "",
  discipline61: "",
  discipline7: "",
  discipline71: "",
  discipline8: "",
  disciplineStatus3:"",
  disciplineStatus51:"",
  disciplineStatus52:"",
  disciplineStatus6:"",
  disciplineStatus7:"",
  disciplineStatus8:"",
};
const disciplineReducer = (state = disciplineIntialState, action) => {
  switch (action.type) {
    case "discipline": {
      return {
        disciplineData: {
          ...action.payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};
let isForgotPasswordInitialState = false;
const isForgotPasswordReducer = (state = isForgotPasswordInitialState, action) => {
  switch (action.type) {
    case "isForgotPassword": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
};

let isVerifiedOTPInitialState = false;
const isVerifiedOTPReducer = (state = isVerifiedOTPInitialState, action) => {
  switch (action.type) {
    case "isVerifiedOTP": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
};
let custHeaderInitialState = "defaultHeader";
const custHeaderReducer = (state = custHeaderInitialState, action) => {
  switch (action.type) {
    case "custHeader": {
      return action.payLoad
    }
    default: {
      return state;
    }
  }
}
let supplierMailValInitialState = null;
const supplierMailValActionReducer = (state = supplierMailValInitialState, action) => {
  switch (action.type) {
    case "supplierMailVal": {
      return action.payLoad
    }
    default: {
      return state;
    }
  }
}

let supplierEmailArrInitialState = {};
const supplierEmailArrReducer = (state = supplierEmailArrInitialState, action) => {
    switch (action.type) {
        case "supplierEmailArr": {
            return action.payLoad
        }
        default: {
            return state;
        }
    }
};

let uploadFileValidation = {
        isDrawingsUploaded: false,
        //is3DUploaded: false,
        //isIsirPdfuploaded: false,
        //isIipupload:false,
};

const uploadFileValidationReducer = (state = uploadFileValidation, action) => {
    switch (action.type) {
        case "uploadFileValidation": {
            return {
                isDrawingsUploaded: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};

let is3DUploaded = {
    is3DUploaded: false
};
const is3DUploadedReducer = (state = is3DUploaded, action) => {
    switch (action.type) {
        case "is3DUploaded": {
            return {
                is3DUploaded: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
let isIsirPdfuploaded = {
    isIsirPdfuploaded: false
};
const isIsirPdfuploadedReducer = (state = isIsirPdfuploaded, action) => {
    switch (action.type) {
        case "isIsirPdfuploaded": {
            return {
                isIsirPdfuploaded: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};

let tfcCheckedValue = {
    tfcCheckedValue: false
};
const istfcCheckedorNotReducer = (state = tfcCheckedValue, action) => {
    switch (action.type) {
        case "tfcCheckedValue": {
            return {
                tfcCheckedValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
let dfmCheckedValue = {
    dfmCheckedValue: false
};
const isDfmCheckedorNotReducer = (state = dfmCheckedValue, action) => {
    switch (action.type) {
        case "dfmCheckedValue": {
            return {
                dfmCheckedValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//trmCheckedValue
let trmCheckedValue = {
    trmCheckedValue: false
};
const istrmCheckedorNotReducer = (state = trmCheckedValue, action) => {
    switch (action.type) {
        case "trmCheckedValue": {
            return {
                trmCheckedValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};

//ppapCheckedValue
let ppapCheckedValue = {
    ppapCheckedValue: false
};
const isppapCheckedorNotReducer = (state = ppapCheckedValue, action) => {
    switch (action.type) {
        case "ppapCheckedValue": {
            return {
                ppapCheckedValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//ppapCheckedValue
let iipQpValue = {
    iipQpValue: false
};
const isIipqpReducer = (state = iipQpValue, action) => {
    switch (action.type) {
        case "iipQpValue": {
            return {
                iipQpValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//poTextValue
let poTextValue = {
    poTextValue: false
};
const isPoTextReducer = (state = poTextValue, action) => {
    switch (action.type) {
        case "poTextValue": {
            return {
                poTextValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};

//draft data checbox
let draftTfcValue = {
    draftTfcValue: false
};
const tfcDraftCheckedorNotReducer = (state = draftTfcValue, action) => {
    switch (action.type) {
        case "draftTfcValue": {
            return {
                draftTfcValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//draftTrmValue
let draftTrmValue = {
    draftTrmValue: false
};
const trmDraftCheckedorNotReducer = (state = draftTrmValue, action) => {
    switch (action.type) {
        case "draftTrmValue": {
            return {
                draftTrmValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//draftDfmValue
let draftDfmValue = {
    draftDfmValue: false
};
const dfmDraftCheckedorNotReducer = (state = draftDfmValue, action) => {
    switch (action.type) {
        case "draftDfmValue": {
            return {
                draftDfmValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//draftppapValue
let draftppapValue = {
    draftppapValue: false
};
const ppapDraftCheckedorNotReducer = (state = draftppapValue, action) => {
    switch (action.type) {
        case "draftppapValue": {
            return {
                draftppapValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//hidetb2Value
let hidetb2Value = {
    hidetb2Value: false
};
const hideTabPart2Reducer = (state = hidetb2Value, action) => {
    switch (action.type) {
        case "hidetb2Value": {
            return {
                hidetb2Value: action.payLoad
            };
        }
        default: {

            return state;
        }
    }
};
//drawingUploadVlaue
let drawingUploadVlaue = {
    drawingUploadVlaue: false
};
const drawingUploadReducer = (state = drawingUploadVlaue, action) => {
    switch (action.type) {
        case "drawingUploadVlaue": {
            return {
                drawingUploadVlaue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};

//threeDUploadValue
let threeDUploadValue = {
    threeDUploadValue: false
};
const threeDUploadReducer = (state = threeDUploadValue, action) => {
    switch (action.type) {
        case "threeDUploadValue": {
            return {
                threeDUploadValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//isirUploadValue
let isirUploadValue = {
    isirUploadValue: false
};
const isirUploadReducer = (state = isirUploadValue, action) => {
    switch (action.type) {
        case "isirUploadValue": {
            return {
                isirUploadValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//ppapxlsmValue
let ppapxlsmValue = {
    ppapxlsmValue: false
};
const ppapxlsmReducer = (state = ppapxlsmValue, action) => {
    switch (action.type) {
        case "ppapxlsmValue": {
            return {
                ppapxlsmValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//incomingQualityReportValue
let incomingQualityReportValue = {
    incomingQualityReportValue: false
};
const incomingQualityReportReducer = (state = incomingQualityReportValue, action) => {
    switch (action.type) {
        case "incomingQualityReportValue": {
            return {
                incomingQualityReportValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//componentApprovalFormValue
let componentApprovalFormValue = {
    componentApprovalFormValue: false
};
const componentApprovalFormReducer = (state = componentApprovalFormValue, action) => {
    switch (action.type) {
        case "componentApprovalFormValue": {
            return {
                componentApprovalFormValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//closeBtnValue
let closeBtnValue = {
    closeBtnValue: false
};
const closeBtnValueReducer = (state = closeBtnValue, action) => {
    switch (action.type) {
        case "closeBtnValue": {
            return {
                closeBtnValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//afterClosingIsirValue
let afterClosingIsirValue = {
    afterClosingIsirValue: false
};
const afterClosingIsirReducer = (state = afterClosingIsirValue, action) => {
    switch (action.type) {
        case "afterClosingIsirValue": {
            return {
                afterClosingIsirValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
//justClosebtnPressValue
let justClosebtnPressValue = {
    justClosebtnPressValue: false
};
const justClosebtnPressReducer = (state = justClosebtnPressValue, action) => {
    switch (action.type) {
        case "justClosebtnPressValue": {
            return {
                justClosebtnPressValue: action.payLoad
            };
        }
        default: {
            return state;
        }
    }
};
let azureToken = {
    token: "",
};
const saveAzureTokenReducer = (state = azureToken, action) => {
    switch (action.type) {
        case "saveAzureToken": {
            return {
                token: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let encryptedId = {
    id: "",
};
const saveUserIdReducer = (state = encryptedId, action) => {
    switch (action.type) {
        case "encryptedId": {
            return {
                id: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
const storeMenuItems = [];
const storeLandingMenuItemsReducer = (state = storeMenuItems, action) => {
    switch (action.type) {
        case "saveLandingMenuItems": {
            return {
                menuItems: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
}



export const masterReducer = combineReducers({
  saveTokenReducer: saveTokenReducer,
  storeUserDetailsReducer: storeUserDetailsReducer,
  authUserReducer: authUserReducer,
  updateResponseReducer: updateResponseReducer,
  disciplineReducer:disciplineReducer,
  custHeaderReducer: custHeaderReducer,
  isVerifiedOTPReducer: isVerifiedOTPReducer,
  isForgotPasswordReducer: isForgotPasswordReducer,
    supplierMailValActionReducer: supplierMailValActionReducer,
    supplierEmailArrReducer: supplierEmailArrReducer,
    uploadFileValidationReducer: uploadFileValidationReducer,
    is3DUploadedReducer: is3DUploadedReducer,
    isIsirPdfuploadedReducer: isIsirPdfuploadedReducer,
    istfcCheckedorNotReducer: istfcCheckedorNotReducer,
    isDfmCheckedorNotReducer: isDfmCheckedorNotReducer,
    istrmCheckedorNotReducer: istrmCheckedorNotReducer,
    isppapCheckedorNotReducer: isppapCheckedorNotReducer,
    isIipqpReducer: isIipqpReducer,
    isPoTextReducer: isPoTextReducer,
    tfcDraftCheckedorNotReducer: tfcDraftCheckedorNotReducer,
    trmDraftCheckedorNotReducer: trmDraftCheckedorNotReducer,
    dfmDraftCheckedorNotReducer: dfmDraftCheckedorNotReducer,
    ppapDraftCheckedorNotReducer: ppapDraftCheckedorNotReducer,
    hideTabPart2Reducer: hideTabPart2Reducer,
    drawingUploadReducer: drawingUploadReducer,
    threeDUploadReducer: threeDUploadReducer,
    isirUploadReducer: isirUploadReducer,
    ppapxlsmReducer: ppapxlsmReducer,
    incomingQualityReportReducer: incomingQualityReportReducer,
    componentApprovalFormReducer: componentApprovalFormReducer,
    closeBtnValueReducer: closeBtnValueReducer,
    afterClosingIsirReducer: afterClosingIsirReducer,
    justClosebtnPressReducer: justClosebtnPressReducer,
    saveAzureTokenReducer: saveAzureTokenReducer,
    saveUserIdReducer: saveUserIdReducer,
    saveBearerTokenReducer: saveBearerTokenReducer,
    storeLandingMenuItemsReducer: storeLandingMenuItemsReducer,
});


