
    import React from 'react';
    import "./MasterFiles.scss"
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Grid ,ThemeProvider} from "@material-ui/core";
import { lightTheme } from '../../../Themes/LightTheme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RoleMaster from './MasterTabs/RoleMaster';
import MaterialMaster from './MasterTabs/MaterialMaster';

     export default function MasterFiles() {
      return (
      <ThemeProvider theme={lightTheme}>

          <>
              <div className="master-paperSty">
                  <div className="master-data">
                      <Grid container spacing={2} className="master-div">
                          <Grid item xs={12} md={12} className="d-flex fd-col pd-1">
                              <h3 className="d-flex jc-center">Role Master</h3>
                              <RoleMaster />
                          </Grid>
                          <Grid item xs={12} md={12} className="d-flex fd-col pd-1">
                              <h3 className="d-flex jc-center">Part Master</h3>
                              <MaterialMaster
                               
                              />
                          </Grid>
                        


                      </Grid>
                  </div>
              </div>
          </>

      </ThemeProvider>
        );
    }


    
    
