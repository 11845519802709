import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./Complaints.scss";
import { ComplaintScreenOptions } from "../../Utilities/Utility";
import cognisphere from "../../assets/images/HomePage-ImgNew.png"
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { supplierMailValAction } from "../../Redux/Actions/actions";
export default function Admin() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(supplierMailValAction(null));
    }, []);
  return (
    <React.Fragment>
    <main className="main-component">
          <img
            src={cognisphere}
            alt="Cognisphere"
            className="homepage-cognisphere"
          />
       
      <h2>SQM Tool</h2>
      <Grid container className="Complaints-block" spacing={2}>
        {ComplaintScreenOptions.map((complaintItem) => (
          <Grid item xs={12} sm={3} key={complaintItem.complaintMenuId}>
            <Link to={complaintItem.complaintMenuLink} key={complaintItem.complaintMenuId}>
              <div className="complaintMenu-item-container">
                <div className="complaintMenu-item-text-block">
                  <span>{complaintItem.complaintMenuName}</span>
                </div>
              </div>
            </Link>
          </Grid>
        ))}
      </Grid>
      </main>
    </React.Fragment>
  );
        }