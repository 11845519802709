
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { lightTheme } from "./Themes/LightTheme";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from "./Redux/Store/store";
import { Auth0Provider } from '@auth0/auth0-react';

document.body.style.zoom = `90%`;

const allowedConnections = ['microsoft', 'google', 'github'];
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Auth0Provider
                 domain="prd-op.siemens.auth0app.com"
                 clientId="VmlVfjUImE1cbKNq47lWTuNkY2ezPzbZ"
                
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: `https://prd-op.siemens.auth0app.com/api/v2/`,
                    display: "amazon-off|apple-off|azure-social-SAG-off|facebook-off|flender-off|github-off|gitlab-off|google-off|linkedin-off|azure-social-common-off|wechat-off|sup",
                    post_logout_redirect_uri: window.location.origin,
                }}
            >
                <ThemeProvider theme={lightTheme}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </Auth0Provider>
        </PersistGate>
    </Provider>,


    document.getElementById("root")
);
