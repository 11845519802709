import { Grid, TextField, Button, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { baseApiURL, tableIcons } from "../../../Utilities/Utility";
import { tableOptionsExport } from "../../../Themes/LightTheme";
import MaterialTable from "material-table";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loading from "../../../Components/Loader/Loader";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useSelector, useDispatch } from "react-redux";
import { AddDisciplineAttachmentDetails, DeleteD4WhyDetails, DeleteD4WhyNotDetails, GetD4WhyDetails, GetD4WhyNotDetails, GetDisciplineAttachmentDetails, PostD4Details, PostD4WhyDetails, postD4WhyNotDetails, UpdateD4, UpdateD4_Why, UpdateD4_WhyNot } from "../../../Redux/APIs/api_Disciplines";
import { DeleteDisciplineAttachments } from "../../../Redux/APIs/api_Complaint";
import { disciplineAction } from "../../../Redux/Actions/actions";
export default function Discipline4(props) {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const dispatch = useDispatch();
    const discpline4Data = useSelector(
        (state) => state.disciplineReducer.disciplineData
    );
    const [cause1TableData, setCause1TableData] = useState([]);
    const [cause2TableData, setCause2TableData] = useState([]);
    const [attachmentData, setAttchmentData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [isLoaderDraft, setIsLoaderDraft] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [reproduce, setReproduce] = useState("")
    let [inputFileAttachment, setInputFileAttachment] = useState(Date.now);
    const [open, setOpen] = useState(false);
    const [responseLengthTable1, setResponseLengthTable1] = useState(null);
    const [responseLengthTable2, setResponseLengthTable2] = useState(null);
    const [disableJustify, setdisableJustify] = useState(true)
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpenValidationDialog(false);
    };
    const [openValidationDialog, setOpenValidationDialog] = useState("");
    const [dialogErrorText, setDialogErrorText] = useState("");
    const handleClickOpenDialogValidation = (text) => {
        setDialogErrorText(text);
        setOpenValidationDialog(true);
    };
    const [inputValues, setInputValues] = useState({
        HaveYouReproduced: props.response.length > 0 ? props?.response[0]?.haveYouReproduced : null,
        Justification: props.response.length > 0 ? props?.response[0]?.justification : "",
        complaintNum: props.rowData[0]?.complaintNumber,
        wheredidit: props.response.length > 0 ? props?.response[0]?.wheredidit : "",
        whydidoccur: props.response.length > 0 ? props?.response[0]?.whydidoccur : "",
        loggedinUserId: userDetails.userId,
        whereInYourManufacturingProcessDidTheRootCauseOccur: props.response.length > 0 ? props?.response[0]?.whereInYourManufacturingProcessDidTheRootCauseOccur : "",
    })

    const [errorTexts, setErrorTexts] = useState({
        HaveYouReproduced: "",
        Justification: "",
        manufacturingCause: ""
    })
    const defectValues = [
        {
            id: true,
            name: "Yes"
        },
        {
            id: false,
            name: "No"
        },
    ];
    //azure ad
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const azureToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    function validateWhy(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    const columnsCause1 = [
        {
            title: "WHY (Root Cause)",
            field: "whyDidTheNonConformityOccur",
            editComponent: ({ value, onChange }) => (
                <TextField
                    fullWidth
                    onChange={e => onChange(e.target.value)}
                    value={value}
                    error={!value}
                    helperText={!value ? "required" : ""}
                    multiline
                />
            ),
            validate: (rowData) => validateWhy(rowData.whyDidTheNonConformityOccur)
        },
    ];
    const columnsCause2 = [
        {
            title: "WHY (Root Cause)",
            field: "whyWereYouNotAbleToDetectNonConformity",
            editComponent: ({ value, onChange }) => (
                <TextField
                    fullWidth
                    onChange={e => onChange(e.target.value)}
                    error={!value}
                    helperText={!value ? "required" : ""}
                    value={value}
                    multiline
                />
            ),
            validate: (rowData) => validateWhy(rowData.whyWereYouNotAbleToDetectNonConformity)
        },
    ];
    var columnsAttachment = [
        {
            title: "Attachment",
            field: "fileName",
            editComponent: (props) => getAttachmentComponent(props)
        },
        {
            title: "Download",
            field: "download",
            editable: "never",
            render: (rowData) => DownloadFile(rowData),
        }

    ];
    function DownloadFile(data) {
        return (
            <Button
                className="pt-button--tertiary" variant="contained"
                onClick={() =>
                    downloadFileAttachment(data)
                }
            >
                Download
            </Button>
        );
    }
    function downloadFileAttachment(data) {
        let attachmentData = {
            fileName: (data.fileName).trim(),
            fileUrl: (data.fileUrl).trim(),
            filetype: (data.filetype).trim()
        }
        const fetchPromise = fetch(baseApiURL + "/Admin/downloadfile",
            {
                method: "post",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: "Bearer " + siteToken.token,
                    AzureToken: azureToken.token,
                    UserEmail: userDetails.userEmail,
                    RoleName: userDetails.role,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    userId: UID.id,
                },
                body: JSON.stringify(attachmentData),
            });
        fetchPromise.then(response => {
            response.blob().then(blob => {
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = "AttachmentD4_" + props.rowData[0]?.complaintNumber + attachmentData.filetype;
                link.click();
            })
        })
    }
    function handleRowDelete(oldFormData, resolve, reject) {
        var attachmentId = oldFormData.attachmentId;
        setIsLoader(true);
        DeleteDisciplineAttachments(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, attachmentId)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getAttchmentData()
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoader(false);
                handleSnackOpen("Exception : " + error, "error");
                reject();
            });
    }
    const [uploadedFileName, setUploadedFileName] = useState("");
    const updateExcelHandler = (event) => {
        setSelectedFile(event.target.files);
        setUploadedFileName(event.target.files[0].name);
    };
    const handleChange = (event, value) => {
        let elem = event.target.name;
        switch (elem) {
            case "HaveYouReproduced":
                setInputValues({
                    ...inputValues,
                    [elem]: value.props.value

                })
                setErrorTexts({
                    ...errorTexts,
                    HaveYouReproduced: ""
                })
                if (value.props.value === true) {
                    setdisableJustify(true);
                    setReproduce("Yes");
                    inputValues.Justification = "";
                }
                else {
                    setdisableJustify(false);
                    setReproduce("No");
                }
                break;
            case "manufacturingCause":
                setInputValues({
                    ...inputValues,
                    whereInYourManufacturingProcessDidTheRootCauseOccur: event.target.value
                })

                break;
            case "Justification":
                setInputValues({
                    ...inputValues,
                    [elem]: event.target.value
                })

                setErrorTexts({
                    ...errorTexts,
                    Justification: ""
                })
                break;
            case "wheredidit":
                setInputValues({
                    ...inputValues,
                    wheredidit: event.target.value
                })

                break;
            case "whydidoccur":
                setInputValues({
                    ...inputValues,
                    whydidoccur: event.target.value
                })
                break;
            default:
                break;
        }
    }
    function getAttachmentComponent(objProps) {
        return (
            <form className="bulk-upload-form">
                {selectedFile == null ? <>
                    <TextField
                        id="fileUploadAttachment"
                        variant="filled"
                        label="Attachment Upload"
                        fullWidth
                        onChange={updateExcelHandler}
                        key={inputFileAttachment}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ multiple: true }}
                        helperText={uploadedFileName}
                    />
                </> : <>
                    <span>{uploadedFileName}</span></>}
            </form>
        );
    }

    const AddD4Details = () => {

        if
            (
            !inputValues.whereInYourManufacturingProcessDidTheRootCauseOccur) {
            handleClickOpenDialogValidation("Please Fill Inputs !!");
        }
        if (!reproduced) {
            handleClickOpenDialogValidation("Please Fill Inputs !!");
        }
        else if (inputValues.HaveYouReproduced === false && !inputValues.Justification) {
            handleClickOpenDialogValidation("Please Fill Inputs !!");
        }
        else if (!inputValues.wheredidit) {
            handleClickOpenDialogValidation("Please Fill Inputs !!");
        }
        else if (!inputValues.whydidoccur) {
            handleClickOpenDialogValidation("Please Fill Inputs !!");
        }
        else {
            setIsLoaderDraft(true);
            if (props?.d4UpdateResponse?.length > 0) {
                var updateId = props.d4UpdateResponse[0].discipline4Id;
                const updateValues = {
                    ...inputValues,
                    discipline4Id: updateId
                }
                UpdateD4(siteToken.token,
                    azureToken.token,
                    UID.id,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.role,
                    userDetails.roleId, updateValues)
                    .then((response) => {
                        if (response.response) {
                            handleSnackOpen(response.responseMsg, "success");
                            setIsLoaderDraft(false);
                            props.getD4Details();
                        } else {
                            handleSnackOpen(response.responseMsg, "error");
                            setIsLoaderDraft(false);
                        }
                    })
                    .catch((error) => {
                        handleSnackOpen("Exception : " + error, "error");
                        setIsLoaderDraft(false);
                    });
            }
            else {
                PostD4Details(siteToken.token,
                    azureToken.token,
                    UID.id,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.role,
                    userDetails.roleId, inputValues)
                    .then((response) => {
                        if (response.response) {
                            handleSnackOpen(response.responseMsg, "success");
                            setIsLoaderDraft(false);
                            props.getD4Details();
                        } else {
                            handleSnackOpen(response.responseMsg, "error");
                            setIsLoaderDraft(false);
                        }
                    })
                    .catch((error) => {
                        handleSnackOpen("Exception : " + error, "error");
                        setIsLoaderDraft(false);
                    });
            }
        };
    }
    function getTableDataCause1() {
        var complaintNum = props?.rowData[0].complaintNumber;
        setIsLoader(true);
        GetD4WhyDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
            , complaintNum)
            .then((response) => {
                setResponseLengthTable1(response);
                if (response.length > 1) {
                    discpline4Data.discipline41 = true;
                    dispatch(disciplineAction(discpline4Data));
                }
                setCause1TableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }

    function getTableDataCause2() {
        var complaintNum = props?.rowData[0].complaintNumber;
        setIsLoader(true);
        GetD4WhyNotDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, complaintNum)
            .then((response) => {
                setResponseLengthTable2(response);
                if (response.length > 1) {
                    discpline4Data.discipline42 = true;
                    dispatch(disciplineAction(discpline4Data));
                }
                setCause2TableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }

    const [d4Data, setD4Data] = useState({
        HaveYouReproduced: props.response.length > 0 ? props?.response[0].haveYouReproduced : null,
        Justification: props.response.length > 0 ? props?.response[0].justification : "",
        loggedinUserId: userDetails.userId,
        wheredidit: props.response.length > 0 ? props?.response[0]?.wheredidit : "",
        whydidoccur: props.response.length > 0 ? props?.response[0]?.whydidoccur : "",
        whereInYourManufacturingProcessDidTheRootCauseOccur: props.response.length > 0 ? props?.response[0].whereInYourManufacturingProcessDidTheRootCauseOccur : "",
    });

    const handleRowAddCause1 = (newTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        newTableData.complaintNum = props?.rowData[0].complaintNumber;
        if (isEditable() === false) {
            handleSnackOpen("Action Restricted", "Info");
            reject();
            setIsLoader(false)
        }
        else {
            if (responseLengthTable1.length > 4) {
                reject();
                setIsLoader(false);
                handleSnackOpen("Maximum 5 WHY Analysis are Allowed", "info");
            }
            else {
                PostD4WhyDetails(siteToken.token,
                    azureToken.token,
                    UID.id,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.role,
                    userDetails.roleId
                    , newTableData)
                    .then((response) => {
                        if (response.response) {
                            handleSnackOpen(response.responseMsg, "success");
                            resolve();
                            setIsLoader(false);
                            getTableDataCause1();

                        } else {
                            handleSnackOpen(response.responseMsg, "error");
                            reject();
                            setIsLoader(false);
                        }
                    })
                    .catch((error) => {
                        handleSnackOpen("Exception : " + error, "error");
                        reject();
                        setIsLoader(false);
                    });
            }
        }
    };
    const handleRowAddCause2 = (newTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        newTableData.complaintNum = props?.rowData[0].complaintNumber;
        if (isEditable() === false) {
            handleSnackOpen("Action Restricted", "Info");
            reject();
            setIsLoader(false)
        }
        else {
            if (responseLengthTable2.length > 4) {
                reject();
                setIsLoader(false);
                handleSnackOpen("Maximum 5 WHY Analysis are Allowed", "info");
            }
            else {
                postD4WhyNotDetails(siteToken.token,
                    azureToken.token,
                    UID.id,
                    userDetails.userEmail,
                    userDetails.gid,
                    userDetails.role,
                    userDetails.roleId, newTableData)
                    .then((response) => {
                        if (response.response) {
                            handleSnackOpen(response.responseMsg, "success");
                            resolve();
                            setIsLoader(false);
                            getTableDataCause2();
                        } else {
                            handleSnackOpen(response.responseMsg, "error");
                            reject();
                            setIsLoader(false);
                        }
                    })
                    .catch((error) => {
                        handleSnackOpen("Exception : " + error, "error");
                        reject();
                        setIsLoader(false);
                    });
            }
        }
    };
    function getAttchmentData() {
        setIsLoader(true);
        GetDisciplineAttachmentDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, props?.rowData[0].complaintNumber, "D4")
            .then((response) => {
                setAttchmentData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }

    function handleRowAddAttachment(newTableData, resolve, reject) {
        if (isEditable() === false) {
            handleSnackOpen("Action Restricted", "Info");
            reject();
            setIsLoader(false)
        }
        else if (!selectedFile) {
            handleSnackOpen("Please Select a File", "info");
            setIsLoader(false);
            reject();
        }
        else {
            setIsLoader(true);
            const formData = new FormData();
            for (let i = 0; i < selectedFile.length; i++) {
                formData.append("file", selectedFile[i]);
            }
            formData.append("files", selectedFile);
            formData.append("userId", userDetails.userId);
            formData.append("ComplaintNumber", props?.rowData[0].complaintNumber);
            formData.append("Discipline", "D4");
            setIsLoader(false);
            AddDisciplineAttachmentDetails(siteToken.token,
                azureToken.token,
                UID.id,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.role,
                userDetails.roleId
                , formData)
                .then((response) => {
                    if (response.response) {
                        handleSnackOpen(response.responseMsg, "success");
                        setUploadedFileName("");
                        resolve();
                        setIsLoader(false);
                        getAttchmentData();
                        setSelectedFile(null);
                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        reject();
                        setIsLoader(false);
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setIsLoader(false);
                    setSelectedFile(null);
                    setInputFileAttachment(Date.now);
                    handleSnackOpen("Exception : " + error, "error");
                    reject();
                });
        }
    }

    const handleRowUpdateCause1 = (newTableData, oldTableData, resolve, reject) => {
        newTableData.loggedinUserId = userDetails.userId;

        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        newTableData.complaintNum = props?.rowData[0].complaintNumber;
        UpdateD4_Why(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
            , newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableDataCause1();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function isEditable() {
        if (userDetails.roleId !== 3 || props.rowData[0]?.status === "Approved") {
            return false;
        }
        else {
            if (props.rowData[0]?.status === "Pending by Supplier" || props.rowData[0]?.status === "Rejected by User") {
                return true
            }
            else {
                return false;
            }
        }
    }
    const handleRowUpdateCause2 = (newTableData, oldTableData, resolve, reject) => {
        newTableData.loggedinUserId = userDetails.userId;
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        newTableData.complaintNum = props?.rowData[0].complaintNumber;
        UpdateD4_WhyNot(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
            , newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableDataCause2();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function handleRowDeleteWhy(oldFormData, resolve, reject) {
        setIsLoader(true);
        DeleteD4WhyDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, oldFormData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableDataCause1();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoader(false);
                handleSnackOpen("Exception : " + error, "error");
                reject();
            });
    }
    function handleRowDeleteWhyNot(oldFormData, resolve, reject) {
        setIsLoader(true);
        DeleteD4WhyNotDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, oldFormData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableDataCause2();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoader(false);
                handleSnackOpen("Exception : " + error, "error");
                reject();
            });
    }
    let reproduced = (inputValues.HaveYouReproduced === false || inputValues.HaveYouReproduced === true) ? true : false;

    useEffect(() => {
        if (props?.response[0]?.justification) {
            setdisableJustify(false);
        }
        props.getD4Details();
        getTableDataCause1();
        getTableDataCause2();
        getAttchmentData();
    }, [])

    return (
        <Grid container spacing={2} md={12}>
            <Grid item xs={12} className="details-center">
                <h3 className="title-heading">Root Cause Analysis</h3>
            </Grid>
            <Grid className="align-ctr" item xs={12} md={6}>
                <span>Where in your manufacturing process did the root cause occur? </span>
            </Grid>
            <Grid item xs={12} sm={3} md={4}>
                <TextField
                    className="mt-1"
                    fullWidth
                    name="manufacturingCause"
                    onChange={handleChange}
                    disabled={userDetails.roleId !== 3 || props?.rowData[0]?.status === "8D Completed" || props?.rowData[0]?.status === "Approved"}
                    value={
                        userDetails.roleId === 1 || userDetails.roleId === 2 || userDetails.roleId === 6
                            ? d4Data?.whereInYourManufacturingProcessDidTheRootCauseOccur
                            : inputValues.whereInYourManufacturingProcessDidTheRootCauseOccur
                    }
                    error={
                        !inputValues.whereInYourManufacturingProcessDidTheRootCauseOccur
                    }
                    helperText={!inputValues.whereInYourManufacturingProcessDidTheRootCauseOccur ? "** Required" : ""}
                    type="text"
                    id="manufacturingCause"
                    multiline
                />
            </Grid>
            <Grid className="align-ctr" item xs={12} md={6}>
                <span>Have you reproduced the defect with root cause identified ? </span>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
                <TextField
                    className="mt-1"
                    name="HaveYouReproduced"
                    value={
                        userDetails.roleId !== 3 ?
                            d4Data?.HaveYouReproduced :
                            inputValues.HaveYouReproduced
                    }
                    error={
                        inputValues.HaveYouReproduced === true || inputValues.HaveYouReproduced === false ? true : false
                    }

                    helperText={inputValues.HaveYouReproduced === true || inputValues.HaveYouReproduced === false ? "" : "**Required"}
                    disabled={userDetails.roleId !== 3 || props?.rowData[0]?.status === "8D Completed" || props?.rowData[0]?.status === "Approved"}
                    onChange={handleChange}
                    type="text"
                    select
                    fullWidth
                    defaultValue=""
                    id="HaveYouReproduced"
                >
                    {
                        defectValues.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
                <TextField
                    className="mt-1"
                    name="Justification"
                    type="text"
                    onChange={handleChange}
                    multiline
                    maxRows={2}
                    fullWidth
                    error={
                        inputValues.HaveYouReproduced === false && !inputValues.Justification
                    }
                    helperText={inputValues.HaveYouReproduced === false && !inputValues.Justification ? "** Required" : ""}
                    value={
                        userDetails.roleId !== 3 ?
                            d4Data?.Justification :
                            inputValues.Justification}
                    disabled={disableJustify || props?.rowData[0]?.status === "8D Completed" || props?.rowData[0]?.status === "Approved"}
                    placeholder="Justification"
                    defaultValue=""
                    id="rejectQuantity"
                />
            </Grid>

            <Grid className="align-ctr" item xs={12} md={6}>
                <span>Why did the Non Conformity Occur ? Only the root cause has to be reported here</span>
            </Grid>
            <Grid item xs={12} sm={3} md={4}>
                <TextField
                    className="mt-1"
                    fullWidth
                    name="wheredidit"
                    multiline
                    maxRows={2}
                    onChange={handleChange}
                    disabled={userDetails.roleId !== 3 || props?.rowData[0]?.status === "8D Completed" || props?.rowData[0]?.status === "Approved"}
                    value={
                        userDetails.roleId === 1 || userDetails.roleId === 2 || userDetails.roleId === 6
                            ? d4Data?.wheredidit
                            : inputValues.wheredidit
                    }
                    error={
                        !inputValues.wheredidit
                    }
                    helperText={!inputValues.wheredidit ? "** Required" : ""}
                    type="text"
                    id="rootCause"

                />
            </Grid>
            <Grid item xs={12} md={4}>
                <h4 style={{ color: "red" }}>Note : Add minimum 2 WHY Analysis</h4>
            </Grid>
            <Grid item xs={12} md={12}>
                <>
                    <Grid container xs={12} className="complaintsHomePage">
                        <Grid item xs={12} className="matTable">
                            <div className="Table-Margin">
                                <MaterialTable
                                    title="WHY"
                                    columns={columnsCause1}
                                    data={cause1TableData}
                                    icons={tableIcons}
                                    localization={{
                                        header: {
                                            actions: ""
                                        }
                                    }}
                                    editable={{
                                        editTooltip: () => userDetails.roleId === 3 ?
                                            "Edit" : "Action Restricted"
                                        ,
                                        deleteTooltip: () => userDetails.roleId === 3 ?
                                            "Delete" : "Action Restricted"
                                        ,
                                        isEditable: isEditable,
                                        isDeletable: isEditable,
                                        onRowUpdate: (newTableData, oldTableData) =>
                                            new Promise((resolve, reject) => {
                                                handleRowUpdateCause1(
                                                    newTableData,
                                                    oldTableData,
                                                    resolve,
                                                    reject
                                                );
                                            }),
                                        onRowDelete: (oldFormData) =>
                                            new Promise((resolve, reject) => {
                                                handleRowDeleteWhy(oldFormData, resolve, reject);
                                            }),
                                        onRowAdd: (newTableData) =>
                                            new Promise((resolve, reject) => {
                                                handleRowAddCause1(newTableData, resolve, reject);
                                            }),

                                    }}
                                    isLoading={isLoader}
                                    options={tableOptionsExport}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </>

            </Grid>
            <Grid className="align-ctr" item xs={12} sm={12} md={6}>
                <span>Why were you not able to Detect the Non Conformity ? Only the root cause has to be reported here</span>
            </Grid>
            <>
                <Grid item xs={12} sm={3} md={4}>
                    <TextField
                        className="mt-1"
                        fullWidth
                        name="whydidoccur"
                        multiline
                        maxRows={2}
                        onChange={handleChange}
                        disabled={userDetails.roleId !== 3 || props?.rowData[0]?.status === "8D Completed" || props?.rowData[0]?.status === "Approved"}
                        value={
                            userDetails.roleId === 1 || userDetails.roleId === 2 || userDetails.roleId === 6
                                ? d4Data?.whydidoccur
                                : inputValues.whydidoccur
                        }
                        error={
                            !inputValues.whydidoccur
                        }
                        helperText={!inputValues.whydidoccur ? "** Required" : ""}
                        type="text"
                        id="whydidoccur"

                    />
                </Grid>
            </>
            <Grid item xs={12} md={4}>
                <h4 style={{ color: "red" }}>Note : Add minimum 2 WHY Analysis</h4>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <Grid item xs={12} className="matTable">
                    <div className="Table-Margin">
                        <MaterialTable
                            columns={columnsCause2}
                            data={cause2TableData}
                            icons={tableIcons}
                            localization={{
                                header: {
                                    actions: ""
                                }
                            }}
                            editable={{
                                editTooltip: () => userDetails.roleId === 3 ?
                                    "Edit" : "Action Restricted"
                                ,
                                deleteTooltip: () => userDetails.roleId === 3 ?
                                    "Delete" : "Action Restricted"
                                ,
                                isEditable: isEditable,
                                isDeletable: isEditable,
                                onRowUpdate: (newTableData, oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowUpdateCause2(
                                            newTableData,
                                            oldTableData,
                                            resolve,
                                            reject
                                        );
                                    }),
                                onRowDelete: (oldFormData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDeleteWhyNot(oldFormData, resolve, reject);
                                    }),
                                onRowAdd: (newTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowAddCause2(newTableData, resolve, reject);
                                    }),
                            }}
                            isLoading={isLoader}
                            options={tableOptionsExport}
                        />
                    </div>
                </Grid>

            </Grid>
            <Grid className="align-ctr" item xs={12} sm={12} md={6}>
                <h3>Upload Attachment</h3>
            </Grid>
            <Grid item xs={12} md={12}>
                <Grid item xs={12} className="fileMatTable">
                    <MaterialTable
                        columns={columnsAttachment}
                        data={attachmentData}
                        icons={tableIcons}
                        isLoading={isLoader}
                        editable={{
                            deleteTooltip: () => userDetails.roleId === 3 ?
                                "Delete" : "Action Restricted",

                            isDeletable: isEditable,
                            onRowDelete: (oldFormData) =>
                                new Promise((resolve, reject) => {
                                    handleRowDelete(oldFormData, resolve, reject);
                                }),
                            onRowAdd: (newTableData) =>
                                new Promise((resolve, reject) => {
                                    handleRowAddAttachment(newTableData, resolve, reject);
                                }),
                            onRowAddCancelled: () => {
                                setUploadedFileName("");
                                setSelectedFile(null)
                            },
                        }}
                        options={tableOptionsExport}
                    />
                </Grid>
            </Grid>

            {userDetails.roleId === 3 ?
                <Grid container spacing={3} className="submit-btn">
                    <Grid item>
                        <Button className="pt-button--primary button-submit" disabled={props?.rowData[0].status === "8D Completed" || props?.rowData[0]?.status === "Approved"} variant="contained" onClick={AddD4Details}>
                            Save
                        </Button>
                    </Grid>
                </Grid> : <Grid></Grid>}
            <Dialog open={openValidationDialog}>

                <DialogContent className="content-center">
                    {dialogErrorText}
                </DialogContent>
                <DialogActions>
                    <Button className="pt-button--secondary button-submit" variant="contained" onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <>
                <SnackbarCustom

                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
            </>
            <>
                {isLoaderDraft ? <Loading loaderText="Loading...." /> : <></>}
            </>
        </Grid>
    )
}