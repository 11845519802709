import {
    Button, TextField,
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    Container,
    InputAdornment, makeStyles,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
//import { IsirScreenOptions } from "../../Utilities/Utility";

//import "./Isirhm.scss";
import "./Isirhm.scss";
import { Link } from "react-router-dom";
//import AddIsir from "../AddIsir/AddSir";

//import AddIsir from "../AddlSir/AddlSir";
import AddIsir from "../ADDISIR/AddlSir";
import { IsirScreenOptions } from "../../Utilities/Utility";
//import cognisphere from "../../assets/images/HomePage-ImgNew.png"

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { custHeaderAction } from "../../Redux/Actions/actions";

const Isirhm = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(custHeaderAction("DEVELOPMENT TRACKER"));
    });

    return (<div>
        <p className="heading">Welcome to Development Tracker</p>

        <Grid container className="Complaints-block" spacing={2}>
            {IsirScreenOptions.map((complaintItem) => (
                <Grid item xs={12} sm={3} key={complaintItem.complaintMenuId}>
                    <Link to={complaintItem.complaintMenuLink} key={complaintItem.complaintMenuId}>
                        <div className="complaintMenu-item-container">
                            <div className="complaintMenu-item-text-flock hm-btns">

                                <span>{complaintItem.complaintMenuName}</span>
                            </div>
                        </div>
                    </Link>
                </Grid>
            ))}
        </Grid>

    </div>);
}
export default Isirhm;
