export const authUserAction = (gid) => {
  return {
    type: "myIDLogin",
    payLoad: gid,
  };
};
export const saveAccessTokenAction = (token) => {
  return {
    type: "saveAccessToken",
    payLoad: token,
  };
};
export const saveBearerTokenAction = (token) => {
    return {
        type: "saveBearerToken",
        payLoad: token,
    };
};
export const errorMessageAction = (errorMessage) => {
  return {
    type: "errorMessage",
    payLoad: errorMessage,
  };
};
export const storeUserDetailsAction = (userData) => {
  return {
    type: "storeUserData",
    payLoad: userData,
  };
};
export const storeUpdatePotentialResponse = (response) => {
  return {
    type: "updatePotential",
    payLoad: 1,
  };
};
export const storeUpdateBudgetResponse = (response) => {
  return {
    type: "updateBudget",
    payLoad: 2,
  };
};
export const storeUpdateManualIndirectResponse = (response) => {
  return {
    type: "updateManualIndirect",
    payLoad: 3,
  };
};
export const isVerifiedOTPAction = (response) => {
  return {
    type: "isVerifiedOTP",
    payLoad: response,
  };
};

export const isForgotPasswordAction = (response) => {
  return {
    type: "isForgotPassword",
    payLoad: response,
  };
};
export const custHeaderAction = (custHeaderType) => {
  return {
    type: 'custHeader',
    payLoad: custHeaderType
  }
}
export const disciplineAction= (disciplineData) => {
  return {
    type: 'discipline',
    payLoad: disciplineData
  }
}
export const supplierMailValAction = (supplierMailVal) => {
  return {
    type: 'supplierMailVal',
    payLoad: supplierMailVal
  }
}
export const supplierEmailArrAction = (supplierEmailArr) => {
    return {
        type: 'supplierEmailArr',
        payLoad: supplierEmailArr
    }
}


export const uploadFileValidationAction = (uploadFileValidation) => {
    return {
        type: 'uploadFileValidation',
        payLoad: uploadFileValidation
    }
}
export const is3DUploadedAction = (is3DUploaded) => {
    return {
        type: 'is3DUploaded',
        payLoad: is3DUploaded
    }
}
export const isIsirPdfuploadedAction = (isIsirPdfuploaded) => {
    return {
        type: 'isIsirPdfuploaded',
        payLoad: isIsirPdfuploaded
    }
}
//tfc--means tfm
export const tfcAction = (tfcvalue) => {  
    return {
        type: 'tfcCheckedValue',
        payLoad: tfcvalue
    }
}
export const dfmAction = (dfmvalue) => {
    return {
        type: 'dfmCheckedValue',
        payLoad: dfmvalue
    }
}
export const trmAction = (trmvalue) => {
    return {
        type: 'trmCheckedValue',
        payLoad: trmvalue
    }
}
export const ppapAction = (ppapvalue) => {
    return {
        type: 'ppapCheckedValue',
        payLoad: ppapvalue
    }
}
//tabTwo
export const iipAction = (iipQpValue) => {
    return {
        type: 'iipQpValue',
        payLoad: iipQpValue
    }
}
//ppap.xlsm
export const ppapxlsmAction = (ppapxlsmValue) => {
    return {
        type: 'ppapxlsmValue',
        payLoad: ppapxlsmValue
    }
}
//incomingreport

export const incomingQualityReportAction = (incomingQualityReportValue) => {
    return {
        type: 'incomingQualityReportValue',
        payLoad: incomingQualityReportValue
    }
}

//po text
export const poTextAction = (poTextValue) => {
    return {
        type: 'poTextValue',
        payLoad: poTextValue
    }
}

///draftdata actions

export const draftTfcAction = (draftTfcValue) => {
    return {
        type: 'draftTfcValue',
        payLoad: draftTfcValue
    }
}

export const draftTrmAction = (draftTrmValue) => {
    return {
        type: 'draftTrmValue',
        payLoad: draftTrmValue
    }
}
export const draftDfmAction = (draftDfmValue) => {
    return {
        type: 'draftDfmValue',
        payLoad: draftDfmValue
    }
}
export const draftPpapAction = (draftppapValue) => {
    return {
        type: 'draftppapValue',
        payLoad: draftppapValue
    }
}
export const hideTb1 = (hidetb2Value) => {
    return {
        type: 'hidetb2Value',
        payLoad: hidetb2Value
    }
}
export const drawingUpload = (drawingUploadVlaue) => {
    return {
        type: 'drawingUploadVlaue',
        payLoad: drawingUploadVlaue
    }
}

export const threeDUpload = (threeDUploadValue) => {
    return {
        type: 'threeDUploadValue',
        payLoad: threeDUploadValue
    }
}

export const isirUpload = (isirUploadValue) => {
    return {
        type: 'isirUploadValue',
        payLoad: isirUploadValue
    }
}

//componentApprovalFormAction
export const componentApprovalFormAction = (componentApprovalFormValue) => {
    return {
        type: 'componentApprovalFormValue',
        payLoad: componentApprovalFormValue
    }
}

//for closebtn
export const closeBtnAction = (closeBtnValue) => {
    return {
        type: 'closeBtnValue',
        payLoad: closeBtnValue
    }
}
//for disabled the submit-draft btn after closing ISIR
export const afterClosingIsirAction = (afterClosingIsirValue) => {
    return {
        type: 'afterClosingIsirValue',
        payLoad: afterClosingIsirValue
    }
}
export const justCloseBtnPress = (justClosebtnPressValue) => {
    return {
        type: 'justClosebtnPressValue',
        payLoad: justClosebtnPressValue
    }
}
export const saveUserIdAction = (id) => {
    return {
        type: "encryptedId",
        payLoad: id,
    };
};
export const saveAzureTokenAction = (token) => {
    return {
        type: "saveAzureToken",
        payLoad: token,
    };
};
export const storeLandingMenuItemsAction = (menuItems) => {
    return {
        type: "saveLandingMenuItems",
        payLoad: menuItems,
    };
};












