import { useState, useEffect } from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import { theme, customStylesDefault, baseApiURL } from "../../../Utilities/Utility";
import { useSelector, useDispatch } from "react-redux";
import { tableOptionsExport } from "../../../Themes/LightTheme";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loading from "../../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import Select from 'react-select'
import {
    FormControl,
    FormHelperText
} from "@material-ui/core";
import { tableIcons } from "../../../Utilities/Utility";
import { getActionOwnerDDL, getStatusDDL, getStockDDL } from "../../../Redux/APIs/api_Common";
import { addD3Details, AddDisciplineAttachmentDetails, DeleteD3Details, GetD3Details, GetDisciplineAttachmentDetails, UpdateD3 } from "../../../Redux/APIs/api_Disciplines";
import { disciplineAction } from "../../../Redux/Actions/actions";
import { DeleteDisciplineAttachments } from "../../../Redux/APIs/api_Complaint";
export default function Discipline3(props) {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const discpline3Data = useSelector(
        (state) => state.disciplineReducer.disciplineData
    );
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    //azure ad
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const azureToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [attachmentData, setAttchmentData] = useState([]);
    const [isLoader1, setIsLoader1] = useState(false);
    let [inputFileAttachment, setInputFileAttachment] = useState(Date.now);
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };

    const [stockData, setStockData] = useState([]);
    async function getStockDetails() {
        const response = await getStockDDL(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setStockData(
                response.map((item) => ({ value: item.id, label: item.name }))
            );
        } else {
            setStockData([]);
        }
    }
    function renderStockValue(value) {
        let obj = {};
        if (stockData && stockData.length > 0) {
            obj = stockData.find((c) => c.value === value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    const [actionOwnerData, setActionOwnerData] = useState([]);
    async function getActionOwnerDetails() {
        const response = await getActionOwnerDDL(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, props?.rowData[0].complaintNumber);
        if (response && response.length > 0) {
            setActionOwnerData(
                response.map((item, key) => ({ value: key, label: response[key] }))
            );
        } else {
            setActionOwnerData([]);
        }
    }
    function validateD3(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    function isValidNumberField(value) {
        const re = /^\d*$/;
        return re.test(value);
    }
    function validateNumber(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidNumberField(value)
                ? { isValid: false, helperText: "Invalid Number" }
                : { isValid: true, helperText: "" };
    }
    const [statusData, setStatusData] = useState([]);

    async function getStatusDetails() {
        const response = await getStatusDDL(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId);
        if (response && response.length > 0) {
            setStatusData(
                response.map((item) => ({ value: item.id, label: item.name }))
            );
        } else {
            setStatusData([]);
        }
    }
    function renderStatusValue(value) {
        let obj = {};
        if (statusData && statusData.length > 0) {
            obj = statusData.find((c) => c.value === value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    function getStockComponent(objProps) {
        const Stock = objProps.rowData.stockId
            ? stockData.find((x) => x.value === objProps.rowData.stockId)
            : null;
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={theme}
                    options={stockData}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? Stock
                            : objProps.value
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt?.value);
                    }}
                    styles={customStylesDefault}
                    placeholder="Stock"
                    isClearable
                />
                <>
                    <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
                </>
            </FormControl>
        );
    }
    function getActionownerComponent(objProps) {
        const actionOwner = objProps.rowData.actionOwner
            ? actionOwnerData.find((x) => x.label === objProps.rowData.actionOwner)
            : null;
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={theme}
                    options={actionOwnerData}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? actionOwner
                            : objProps.label
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt?.label);
                    }}
                    styles={customStylesDefault}
                    placeholder="Action Owner"
                    isClearable
                />
                <>
                    <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
                </>
            </FormControl>
        );
    }
    function getStatusComponent(objProps) {
        const Status = objProps.rowData.statusId
            ? statusData.find((x) => x.value === objProps.rowData.statusId)
            : null;
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={theme}
                    options={statusData}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? Status
                            : objProps.value
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt?.value);
                    }}
                    styles={customStylesDefault}
                    placeholder="Status"
                    isClearable
                />
                <>
                    <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
                </>
            </FormControl>
        );
    }

    useEffect(() => {
        getAttchmentData();
        getTableData();
        getActionOwnerDetails();
        getStatusDetails();
        getStockDetails()
    }, [])
    const columns = [
        {
            title: "Stock Details",
            field: "stockId",
            render: (rowData) => <span>{renderStockValue(rowData.stockId)}</span>,
            editComponent: (props) => getStockComponent(props)
        },
        {
            title: "Quantity",
            field: "quantity",
            validate: (rowData) => validateNumber(rowData.quantity)
        },
        {
            title: "Containment action(replacement/rework/inspection/Scrap)",
            field: "containment",
            validate: (rowData) => validateD3(rowData.containment)
        },
        {
            title: "Action Owner",
            field: "actionOwner",
            validate: (rowData) => validateD3(rowData.actionOwner),
            editComponent: (props) => getActionownerComponent(props)
        },
        {
            title: "Status",
            field: "statusId",
            validate: (rowData) => validateD3(rowData.statusId),
            render: (rowData) => <span>{renderStatusValue(rowData.statusId)}</span>,
            editComponent: (props) => getStatusComponent(props)
        }
    ];
    function isEditable() {
        if (userDetails.roleId !== 3) {
            return false;
        }
        else {
            if (props.rowData[0]?.status === "Pending by Supplier" || props.rowData[0]?.status === "Rejected by User") {
                return true
            }
            else {
                return false;
            }
        }
    }
    function getTableData() {
        var flag = true;
        setIsLoader(true);
        GetD3Details(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
            , props?.rowData[0].complaintNumber)
            .then((response) => {
                if (response.result.length > 0) {
                    discpline3Data.discipline3 = true;
                    for (var i = 0; i < response.result.length; i++) {
                        var obj = response.result[i];
                        if (obj?.statusId !== 2) {
                            flag = false;
                        }
                    }
                    if (flag === true) {
                        discpline3Data.disciplineStatus3 = true;
                    }
                    else {
                        discpline3Data.disciplineStatus3 = false;
                    }
                    dispatch(disciplineAction(discpline3Data));
                }
                setTableData(response.result);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    const handleRowAdd = (newTableData, resolve, reject) => {

        if ((newTableData.stockId == "" || newTableData.stockId == undefined) ||
            (newTableData.quantity == "" || newTableData.quantity == undefined) ||
            (newTableData.containment == "" || newTableData.containment == undefined) ||
            (newTableData.actionOwner == "" || newTableData.actionOwner == undefined) ||
            (newTableData.statusId == "" || newTableData.statusId == undefined)) {
            handleSnackOpen("One or more mandatory fields are empty", "warning");
            reject();
            return false;
        }

        setIsLoader(true);
        newTableData.quantity = parseInt(newTableData.quantity);
        newTableData.loggedinUserId = userDetails.userId;
        newTableData.complaintNum = props?.rowData[0].complaintNumber;
        if (isEditable() === false) {
            handleSnackOpen("Action Restricted", "Info");
            reject();
            setIsLoader(false)
        }
        else {
            addD3Details(siteToken.token,
                azureToken.token,
                UID.id,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.role,
                userDetails.roleId, newTableData)
                .then((response) => {
                    if (response.response) {
                        handleSnackOpen(response.responseMsg, "success");
                        resolve();
                        setIsLoader(false);
                        getTableData();

                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        reject();
                        setIsLoader(false);
                    }
                })
                .catch((error) => {
                    handleSnackOpen("Exception : " + error, "error");
                    reject();
                    setIsLoader(false);
                });
        }
    };
    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {

        if ((newTableData.stockId == "" || newTableData.stockId == undefined) ||
            (newTableData.quantity == "" || newTableData.quantity == undefined) ||
            (newTableData.containment == "" || newTableData.containment == undefined) ||
            (newTableData.actionOwner == "" || newTableData.actionOwner == undefined) ||
            (newTableData.statusId == "" || newTableData.statusId == undefined)) {
            handleSnackOpen("One or more mandatory fields are empty", "warning");
            reject();
            return false;
        }

        newTableData.loggedinUserId = userDetails.userId;
        newTableData.quantity = parseInt(newTableData.quantity);
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        newTableData.complaintNum = props?.rowData[0].complaintNumber;
        UpdateD3(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function handleRowDeleteD3(oldFormData, resolve, reject) {
        setIsLoader(true);
        DeleteD3Details(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId
            , oldFormData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoader(false);
                handleSnackOpen("Exception : " + error, "error");
                reject();
            });
    }
    var columnsAttachment = [
        {
            title: "Attachment",
            field: "fileName",
            editComponent: (props) => getAttachmentComponent(props)
        },
        {
            title: "Download",
            field: "download",
            editable: "never",
            render: (rowData) => DownloadFile(rowData),
        }];
    function DownloadFile(data) {
        return (
            <Button
                className="pt-button--tertiary" variant="contained"
                onClick={() =>
                    downloadFileAttachment(data)
                }
            >
                Download
            </Button>
        );
    }
    function downloadFileAttachment(data) {
        let attachmentData = {
            fileName: (data.fileName).trim(),
            fileUrl: (data.fileUrl).trim(),
            filetype: (data.filetype).trim()
        }
        const fetchPromise = fetch(baseApiURL + "/Admin/downloadfile",
            {
                method: "post",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: "Bearer " + siteToken.token,
                    AzureToken: azureToken.token,
                    UserEmail: userDetails.userEmail,
                    RoleName: userDetails.role,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    userId: UID.id,
                },
                body: JSON.stringify(attachmentData),
            });
        fetchPromise.then(response => {
            response.blob().then(blob => {
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = "AttachmentD3_" + props.rowData[0]?.complaintNumber + attachmentData.filetype;
                link.click();
            })
        })
    }
    const [uploadedFileName, setUploadedFileName] = useState("");
    const updateExcelHandler = (event) => {
        setSelectedFile(event.target.files);
        setUploadedFileName(event.target.files[0].name);
    };
    function getAttachmentComponent(objProps) {
        return (
            <form className="bulk-upload-form">
                {selectedFile == null ? <>
                    <TextField
                        id="fileUploadAttachment"
                        variant="filled"
                        label="Attachment Upload"
                        fullWidth
                        onChange={updateExcelHandler}
                        key={inputFileAttachment}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ multiple: true }}
                        helperText={uploadedFileName}
                    />
                </> : <>
                    <span>{uploadedFileName}</span></>}
            </form>
        );
    };
    function getAttchmentData() {
        setIsLoader1(true);
        GetDisciplineAttachmentDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, props?.rowData[0].complaintNumber, "D3")
            .then((response) => {
                setAttchmentData(response);
                setIsLoader1(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader1(false);
            });
    }
    function handleRowAddAttachment(newTableData, resolve, reject) {
        if (isEditable() === false) {
            handleSnackOpen("Action Restricted", "Info");
            reject();
            setIsLoader(false)
        }
        else if (!selectedFile) {
            handleSnackOpen("Please Select a File", "info");
            setIsLoader1(false);
            reject();
        }
        else {
            setIsLoader1(true);
            const formData = new FormData();
            for (let i = 0; i < selectedFile.length; i++) {
                formData.append("file", selectedFile[i]);
            }
            const CompNumber = props?.rowData[0].complaintNumber;
            formData.append("files", selectedFile);
            formData.append("userId", userDetails.userId);
            formData.append("ComplaintNumber", CompNumber);
            formData.append("Discipline", "D3");
            setIsLoader1(false);
            AddDisciplineAttachmentDetails(siteToken.token,
                azureToken.token,
                UID.id,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.role,
                userDetails.roleId
                , formData)
                .then((response) => {
                    if (response.response) {
                        handleSnackOpen(response.responseMsg, "success");
                        setUploadedFileName("");
                        resolve();
                        setIsLoader1(false);
                        getAttchmentData();
                        setSelectedFile(null);
                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        setUploadedFileName("");
                        reject();
                        setIsLoader1(false);
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setIsLoader1(false);
                    setSelectedFile(null);
                    setInputFileAttachment(Date.now);
                    handleSnackOpen("Exception : " + error, "error");
                    reject();
                });
        }
    };
    function handleRowDelete(oldFormData, resolve, reject) {
        var attachmentId = oldFormData.attachmentId;
        setIsLoader(true);
        DeleteDisciplineAttachments(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId, attachmentId)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getAttchmentData()
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoader(false);
                handleSnackOpen("Exception : " + error, "error");
                reject();
            });
    }
    return (
        <>

            <Grid container xs={12} className="complaintsHomePage">
                <Grid item xs={12} className="pageTitle">
                    <h3 className="title-heading">Take Containment Actions </h3>
                </Grid>
                <Grid item xs={12} className="matTable">
                    <MaterialTable
                        columns={columns}
                        data={tableData}
                        icons={tableIcons}
                        localization={{
                            body: {
                                addTooltip: userDetails.roleId === 3 ? 'Add' : 'Action Restricted',
                            }
                        }}
                        editable={{
                            editTooltip: () => userDetails.roleId === 3 ?
                                "Edit" : "Action Restricted"
                            ,
                            deleteTooltip: () => userDetails.roleId === 3 ?
                                "Delete" : "Action Restricted"
                            ,
                            isEditable: isEditable,
                            isDeletable: isEditable,
                            onRowAdd: (formData) => {
                                return new Promise((resolve, reject) => {
                                    handleRowAdd(formData, resolve, reject)
                                })
                            },
                            onRowDelete: (oldFormData) =>
                                new Promise((resolve, reject) => {
                                    handleRowDeleteD3(oldFormData, resolve, reject);
                                }),
                            onRowUpdate: (newTableData, oldTableData) =>
                                new Promise((resolve, reject) => {
                                    handleRowUpdate(
                                        newTableData,
                                        oldTableData,
                                        resolve,
                                        reject
                                    );
                                }),
                        }}
                        isLoading={isLoader}
                        options={tableOptionsExport}
                    />
                </Grid>
                <Grid className="align-ctr" item xs={12} sm={12} md={6}>
                    <h3>Upload Attachment</h3>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid item xs={12} className="fileMatTable">
                        <MaterialTable
                            columns={columnsAttachment}
                            data={attachmentData}
                            icons={tableIcons}
                            editable={{

                                onRowAdd: (newTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowAddAttachment(newTableData, resolve, reject);
                                    }),
                                onRowAddCancelled: () => {
                                    setUploadedFileName("");
                                    setSelectedFile(null)
                                },
                                onRowDelete: (oldFormData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDelete(oldFormData, resolve, reject);
                                    }),
                                editTooltip: () => userDetails.roleId === 3 ?
                                    "Edit" : "Action Restricted"
                                ,
                                deleteTooltip: () => userDetails.roleId === 3 ?
                                    "Delete" : "Action Restricted"
                                ,
                                isEditable: isEditable,
                                isDeletable: isEditable
                            }}

                            isLoading={isLoader1}
                            options={tableOptionsExport}
                        />
                    </Grid>

                    <>
                        <SnackbarCustom
                            open={open}
                            message={snackMessage}
                            alertType={alertType}
                            handleClose={handleClose}
                        />
                    </>
                </Grid>
                {userDetails.roleId === 3 ? <Grid container spacing={3} className="submit-btn">


                </Grid> : <Grid></Grid>}
                <>
                    <SnackbarCustom
                        open={open}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleClose}
                    />
                </>
            </Grid>
        </>
    )
}
