import { Routes, Route, Navigate} from "react-router-dom";
import LoginRoot from "../Screens/LoginRegister/LoginRoot";
import ChangePassword from "../Screens/ResetPassword/ChangePassword";

import NewDownload from "../Screens/ADDISIR/NewDownload";
import Error from "../Screens/Error/Error404";

export const LoginRoute = (
  <Routes>
    <Route path="/?Code/*" element={<Navigate from="/?Code/*" to="/" />} />
        <Route exact path="/" element={<LoginRoot />} />
        <Route exact path="/ISIRdownloadfile" element={<NewDownload />} />
        <Route path="*" element={<Error />} />

  </Routes>
);

export const ResetPasswordRoute = (
    <Routes>
        <Route exact path="/" element={<ChangePassword/>} />
    </Routes>
);
