import { Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { getTodayDateMoment, tableIcons } from "../../../Utilities/Utility";
import { tableOptionsComplaints } from "../../../Themes/LightTheme";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { BootstrapInput } from "../../../Utilities/Utility";
import { GetDetectedDDL, GetSeverityDDL } from "../../../Redux/APIs/api_Common";
import { useSelector } from "react-redux";
import { GetNonConformityDetails, PostDescriptionDetails } from "../../../Redux/APIs/api_Complaint";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import moment from "moment";

export default function NonConformityList(props) {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    //azure ad
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const azureToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    const [severity, setSeverity] = useState([]);
    async function SeverityDDL() {
        const response = await GetSeverityDDL(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
        if (response && response.length > 0) {
            setSeverity(response);
        }
        else {
            setSeverity([]);
        }
    }
    const [whereitisdetecteded, setwhereitisdetecteded] = useState([]);
    async function whereitisdetectedDDL() {
        const response = await GetDetectedDDL(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
        if (response && response.length > 0) {
            setwhereitisdetecteded(response);
        }
        else {
            setwhereitisdetecteded([]);
        }
    }
    const [disableRejectedQuantity, setDisableRejectedQuantity] = useState(true);
    const handleChangeNonConformity = (event, value) => {
        let elem = event.target.name;
        switch (elem) {
            case "severity":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        severityId: value.props.value,
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: value.props.value,
                    });
                }
                break;
            case "whereitisdetected":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        whereitisdetected: value.props.value,
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: value.props.value,
                    });
                }
                break;
            case "issueReportedOn":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        [elem]: event.target.value,
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    });
                }
                break;
            case "defectiveParts":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        defectivePartsCount: parseInt(event.target.value),
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    });
                }
                break;
            case "estimatedDefectiveRate":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        [elem]: event.target.value,
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    });
                }
                break;
            case "potentialTotalQty":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        [elem]: parseInt(event.target.value),
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    });
                }
                break;
            case "isSortingrework":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        [elem]: value.props.value,
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: value.props.value,
                    });
                }
                break;
            case "inspectedQty":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        inspectedQuantity: parseInt(event.target.value),
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    });
                }
                break;
            case "isRma":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        [elem]: value.props.value,
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: value.props.value,
                    });
                }
                if (value.props.value === false) {
                    setDisableRejectedQuantity(true);
                }
                else {
                    setDisableRejectedQuantity(false);
                }
                break;
            case "rejectedRMAQty":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        rmarejectedQty: parseInt(event.target.value),
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    });
                }
                break;
            case "rejectedQty":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        [elem]: parseInt(event.target.value),
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    });
                }
                break;
            case "ncc":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        [elem]: event.target.value,
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    });
                }
                break;
            default:
                break;
        }
    };
    const [disable, setDisable] = useState(true);
    function disableForm() {
        if (props?.rowData?.status === "Draft" || props?.rowData === "" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
            if (props?.rowData?.status === "Sent back to Siemens" && userDetails.roleId === 3) {
                setDisable(true);
            }
            else {
                setDisable(false);
            }
        }
        else {
            setDisable(true);
        }
    }
    function getTableData() {
        setIsLoader(true);
        GetNonConformityDetails(siteToken.token,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.role,
            userDetails.roleId)
            .then((response) => {
                if (props.rowData?.status) {
                    var viewData =
                        [
                            {
                                NonConformityId: 1,
                                id: 1,
                                isActive: true,
                                nonConformityDescription: response[0].nonConformityDescription,
                                complaintNumber: props.rowData?.name ? props?.complaintDetails.complaintNumber : props.rowData?.complaintNumber,
                                DetailedDescription: props?.rowData?.detaildescription.length === 0 ? "" : props?.rowData?.detaildescription[0].detailedDescription
                            },
                            {
                                NonConformityId: 2,
                                id: 2,
                                isActive: true,
                                nonConformityDescription: response[1].nonConformityDescription,
                                complaintNumber: props.rowData?.name ? props?.complaintDetails.complaintNumber : props.rowData?.complaintNumber,
                                DetailedDescription: props?.rowData?.detaildescription.length === 0 ? "" : props?.rowData?.detaildescription[1].detailedDescription
                            },
                            {
                                NonConformityId: 3,
                                id: 3,
                                isActive: true,
                                nonConformityDescription: response[2].nonConformityDescription,
                                complaintNumber: props.rowData?.name ? props?.complaintDetails.complaintNumber : props.rowData?.complaintNumber,
                                DetailedDescription: props?.rowData?.detaildescription.length === 0 ? "" : props?.rowData?.detaildescription[2].detailedDescription
                            },
                            {
                                NonConformityId: 4,
                                id: 4,
                                isActive: true,
                                nonConformityDescription: response[3].nonConformityDescription,
                                complaintNumber: props.rowData?.name ? props?.complaintDetails.complaintNumber : props.rowData?.complaintNumber,
                                DetailedDescription: props?.rowData?.detaildescription.length === 0 ? "" : props?.rowData?.detaildescription[3].detailedDescription
                            },
                            {
                                NonConformityId: 6,
                                id: 6,
                                isActive: true,
                                nonConformityDescription: response[4].nonConformityDescription,
                                complaintNumber: props.rowData?.name ? props?.complaintDetails.complaintNumber : props.rowData?.complaintNumber,
                                DetailedDescription: props?.rowData?.detaildescription.length === 0 ? "" : props?.rowData?.detaildescription[4].detailedDescription
                            }
                        ]
                    setTableData(viewData);
                }
                else {
                    var newData = response.map((item) => {
                        item.DetailedDescription = "";
                        item.complaintNumber = props?.complaintDetails.complaintNumber;
                        item.NonConformityId = item.id;
                        return item;
                    })
                    setTableData(newData)
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        detailDescription: newData,
                    })
                }
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    const InspectionValues = [
        {
            id: true,
            name: "Yes"
        },
        {
            id: false,
            name: "No"
        },
    ];
    const RejectionValues = [
        {
            id: true,
            name: "Yes"
        },
        {
            id: false,
            name: "No"
        },
    ]
    function validateNonConformity(value) {
        return !value.DetailedDescription
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    var columns = [
        {
            title: "Questions",
            field: "nonConformityDescription",
            editable: "never"
        },
        {
            title: "Description",
            field: "DetailedDescription",
            editable:
                props?.rowData
                    ? props.rowData.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                        ? "always"
                        : "never"
                    : "always",
            editComponent: ({ value, onChange }) => (
                <TextField
                    fullWidth
                    onChange={e => onChange(e.target.value)}
                    value={value}
                    error={!value}
                    helperText={!value ? "required" : ""}
                    multiline
                />
            ),
            validate: (rowData) => validateNonConformity(rowData)
        },
        {
            title: "ComplaintNumber",
            field: "complaintNumber",
            hidden: true
        },
    ];

    useEffect(() => {
        if (props) {
            disableForm();
            if (props.rowData.isRma === true) {
                setDisableRejectedQuantity(false);
            }
        }

        SeverityDDL();
        whereitisdetectedDDL();

    }, [])
    useEffect(() => {
        getTableData();
    }, [props?.complaintDetails?.complaintNumber])
    return (
        <>
            <Grid container className="complaintsHomePage">
                <Grid item xs={12} >
                    <h3 className="title-heading">Description of Non Conformity **</h3>
                </Grid>
                <Grid item xs={12} className="matTable">
                    <div className="Table-margin">
                        <MaterialTable
                            columns={columns}
                            data={tableData}
                            icons={tableIcons}
                            isLoading={isLoader}
                            options={tableOptionsComplaints}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            const dataUpdate = [...tableData];
                                            const index = oldData.tableData.id;
                                            dataUpdate[index] = newData;
                                            setTableData([...dataUpdate]);
                                            if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                                                props.setDraftInputs({
                                                    ...props.draftInputs,
                                                    detaildescription: dataUpdate,
                                                })
                                            }
                                            else {
                                                props.setCreateComplaintInputs({
                                                    ...props.createComplaintInputs,
                                                    detailDescription: dataUpdate,
                                                })
                                            }

                                            resolve();
                                        }, 1000);
                                    }),
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid className="view-block">
                <div className="inner-vb">
                    <Grid container spacing={2}
                    >

                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl error={
                                props.rowData
                                    ? props.rowData.status === "Draft" || props.rowData.status === "Recall"
                                        ? !(props.draftInputs.severityId)
                                        : !(props.rowData.severityId)
                                    : !(props.createComplaintInputs.severity)
                            } fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input"> Severity**</InputLabel>
                                <Select
                                    labelId="severity"
                                    id="severity"
                                    name="severity"
                                    value={
                                        props.rowData
                                            ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                                ? props.draftInputs.severityId
                                                : props.rowData.severityId
                                            : props.createComplaintInputs.severity
                                    }
                                    disabled={disable}
                                    onChange={handleChangeNonConformity}
                                    input={<BootstrapInput />}
                                >
                                    {
                                        severity.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl error={
                                props.rowData
                                    ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                        ? !(props.draftInputs.issueReportedOn)
                                        : !(props.rowData.issueReportedOn)
                                    : !(props.createComplaintInputs.issueReportedOn)
                            } fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    Issue Reported Date **
                                </InputLabel>
                                <BootstrapInput
                                    type="date"
                                    id="bootstrap-input"
                                    name="issueReportedOn"
                                    inputProps={{ max: getTodayDateMoment() }}
                                    value={
                                        props.rowData
                                            ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                                ? moment(props.draftInputs.issueReportedOn).format('YYYY-MM-DD')
                                                : moment(props.rowData.issueReportedOn).format('YYYY-MM-DD')
                                            : props.createComplaintInputs.issueReportedOn
                                    }
                                    disabled={disable}
                                    onChange={handleChangeNonConformity}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    Rejected Quantity
                                </InputLabel>
                                <BootstrapInput type="number" id="bootstrap-input"
                                    name="rejectedQty"
                                    inputProps={{ min: 0 }}
                                    value={
                                        props.rowData
                                            ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                                ? props.draftInputs.rejectedQty
                                                : props.rowData.rejectedQty
                                            : props.createComplaintInputs.rejectedQty
                                    }
                                    disabled={disable}
                                    onChange={handleChangeNonConformity}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    NCC
                                </InputLabel>
                                <BootstrapInput type="text" id="bootstrap-input"
                                    name="ncc"
                                    value={
                                        props.rowData
                                            ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                                ? props.draftInputs.ncc
                                                : props.rowData.ncc
                                            : props.createComplaintInputs.ncc
                                    }
                                    disabled={disable}
                                    onChange={handleChangeNonConformity}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={2} md={12}>
                    <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                        <span>Where has it been detected?</span>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <TextField
                            className="mt-1"
                            select
                            fullWidth
                            value={
                                props.rowData
                                    ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                        ? props.draftInputs.whereitisdetected
                                        : props.rowData.whereitisdetected
                                    : props.createComplaintInputs.whereitisdetected
                            }
                            error=
                            {props.rowData
                                ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                    ? !(props.draftInputs.whereitisdetected)
                                    : !(props.rowData.whereitisdetected)
                                : !(props.createComplaintInputs.whereitisdetected)
                            }
                            helperText={props.rowData
                                ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                    ? !(props.draftInputs.whereitisdetected)
                                    : !(props.rowData.whereitisdetected)
                                : !(props.createComplaintInputs.whereitisdetected)
                                    ? "** Required" : ""}
                            disabled={disable}

                            onChange={handleChangeNonConformity}
                            name="whereitisdetected"
                            type="text"
                            id="whereitisdetected"

                        >{
                                whereitisdetecteded.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                        <span>How many defective parts ? Actually observed</span>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <TextField
                            className="mt-1"
                            name="defectiveParts"
                            fullWidth
                            type="number"
                            inputProps={{ min: 0 }}
                            disabled={disable}
                            onChange={handleChangeNonConformity}
                            value={
                                props.rowData
                                    ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                        ? props.draftInputs.defectivePartsCount
                                        : props.rowData.defectivePartsCount
                                    : props.createComplaintInputs.defectiveParts
                            }
                            whereitisdetected
                            id="rejectQuantity"

                        />
                    </Grid>
                    <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                        <span>Inspected Estimated Defective Rate in (% / PPM)</span>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <TextField
                            className="mt-1"
                            name="estimatedDefectiveRate"
                            type="text"
                            fullWidth
                            disabled={disable}
                            onChange={handleChangeNonConformity}
                            value={
                                props.rowData
                                    ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                        ? props.draftInputs.estimatedDefectiveRate
                                        : props.rowData.estimatedDefectiveRate
                                    : props.createComplaintInputs.estimatedDefectiveRate
                            }
                            id="rejectQuantity"
                        />
                    </Grid>
                    <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                        <span>Potentially total concerned Quantity (stock /transit)</span>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <TextField
                            className="mt-1"
                            name="potentialTotalQty"
                            type="number"
                            inputProps={{ min: 0 }}
                            fullWidth
                            disabled={disable}
                            onChange={handleChangeNonConformity}
                            value={
                                props.rowData
                                    ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                        ? props.draftInputs.potentialTotalQty
                                        : props.rowData.potentialTotalQty
                                    : props.createComplaintInputs.potentialTotalQty
                            }
                            id="rejectQuantity"

                        />
                    </Grid>
                    <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                        <span>Sorting /rework / inspection required for stock </span>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <TextField
                            className="mt-1"
                            value={
                                props.rowData
                                    ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                        ? props.draftInputs.isSortingrework
                                        : props.rowData.isSortingrework
                                    : props.createComplaintInputs.isSortingrework
                            }
                            disabled={disable}
                            fullWidth
                            select
                            name="isSortingrework"
                            type="text"
                            onChange={handleChangeNonConformity}
                            id="rejectQuantity"

                        >{
                                InspectionValues.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                        <span>Inspected Quantity</span>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <TextField
                            className="mt-1"
                            fullWidth
                            name="inspectedQty"
                            value={
                                props.rowData
                                    ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                        ? props.draftInputs.inspectedQuantity
                                        : props.rowData.inspectedQuantity
                                    : props.createComplaintInputs.inspectedQty
                            }
                            disabled={disable}
                            onChange={handleChangeNonConformity}
                            type="number"
                            inputProps={{ min: 0 }}
                            id="rejectQuantity"

                        />
                    </Grid>
                    <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                        <span>RMA For Rejected </span>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <TextField
                            className="mt-1"
                            value={
                                props.rowData
                                    ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                        ? props.draftInputs.isRma
                                        : props.rowData.isRma
                                    : props.createComplaintInputs.isRma
                            }
                            disabled={disable}
                            onChange={handleChangeNonConformity}
                            fullWidth
                            select
                            name="isRma"
                            type="text"
                            id="rejectQuantity"
                        >{
                                RejectionValues.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid className="align-ctr" item xs={12} sm={3} md={4}>
                        <span>RMA Rejected Quantity </span>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <TextField
                            className="mt-1"
                            fullWidth
                            onChange={handleChangeNonConformity}
                            value={
                                props.rowData
                                    ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                        ? props.draftInputs.rmarejectedQty
                                        : props.rowData.rmarejectedQty
                                    : props.createComplaintInputs.rejectedRMAQty
                            }
                            disabled={disable || disableRejectedQuantity}
                            name="rejectedRMAQty"
                            type="number"
                            inputProps={{ min: 0 }}
                            id="rejectedRMAQty"

                        />
                        <>
                            <SnackbarCustom
                                open={open}
                                message={snackMessage}
                                alertType={alertType}
                                handleClose={handleClose}
                            />
                        </>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}