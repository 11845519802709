import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./Admin.scss";
import { AdminstratorOptions } from "../../Utilities/Utility";
import cognisphere from "../../assets/images/HomePage-ImgNew.png";

export default function Admin() {
  return (
    <React.Fragment>
    <main className="main-component">
    <img
      src={cognisphere}
      alt="Cognisphere"
      className="homepage-cognisphere"
    />
      <h2>Administrator</h2>
      <Grid container className="Admin-block" spacing={2}>
        {AdminstratorOptions.map((adminItem) => (
          <Grid item xs={12} sm={3} key={adminItem.adminMenuId}>
            <Link to={adminItem.adminMenuLink} key={adminItem.adminMenuId}>
              <div className="adminMenu-item-container">
                <div className="adminMenu-item-text-block">
                  <span>{adminItem.adminMenuName}</span>
                </div>
              </div>
            </Link>
          </Grid>
        ))}
      </Grid>
      </main>
    </React.Fragment>
  );
}