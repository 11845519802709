import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DrawerMenuIcon from "../../assets/icons/Menu.svg";
import SiemensLogoWhite from "../../assets/images/siemens-logo-white.svg";
import HomeIcon from "@material-ui/icons/Home";
import {useSelector} from "react-redux"
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import DescriptionIcon from '@material-ui/icons/Description';

import "./SideDrawer.scss";

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
});

export default function SideDrawer() {
    const classes = useStyles();
    let userDetails = useSelector((state) => state.storeUserDetailsReducer.userData);
    const [drawerState, setDrawerState] = React.useState({
        left: false,
    });
    const landingMenuItems = useSelector(state => state.storeLandingMenuItemsReducer.menuItems) || [];
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerState({ ...drawerState, [anchor]: open });
    };

    function logoutUser() {
        var newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        window.history.pushState({ path: newurl }, "", `/`);
        localStorage.clear();
        sessionStorage.clear();
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        window.location.href = "/";
    }
    let [sideDrawerItems, setSideDrawerItems] = useState([])

    const sideDrawerItemsGeneric1 = [

        {
            icon: <GroupAddIcon />,
            title: "List Of Development Tracker",
            link: "Epwork",
            supplierLoginType: [2],//for ISIR
            roleId: [3]
        },
        {
            icon: <GroupAddIcon />,
            title: "Complaints List",
            link: "complaintsList",
            supplierLoginType: [1], //for SQM
            roleId: [3]
        },];

    const sideDrawerItemsGeneric = [
        { icon: <HomeIcon />, title: "Home", link: "/", roleId: [1], supplierLoginType: [0, 1, 2], },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Complaints List",
            link: "complaintsList",


            roleId: [1]
        },

        {
            icon: <GroupAddIcon />,
            title: "User Management",
            link: "userManagement",

            roleId: [1]
        },
        {
            icon: <GroupAddIcon />,
            title: "List Of Development Tracker",
            link: "Epwork",

            roleId: [7, 8, 9, 10]
        },

        {
            icon: <GroupAddIcon />,
            title: "Development Tool",
            link: "Isirhm",

            roleId: [1, 6]
        },
        {
            icon: <PowerSettingsNewOutlinedIcon />,
            title: "Logout",
            link: "logout",

            roleId: [1, 3, 6, 7, 8, 9, 10]
        },
    ];
    //c?.roleId.includes(userDetails.roleId)
    const icons = {
        "<ListAltOutlined />": <ListAltOutlinedIcon />,

        "<DescriptionIcon />": <DescriptionIcon />,

        "<GroupAdd />": <GroupAddIcon />,

    };
    useEffect(() => {
        if (userDetails.roleId === 3) {
            sideDrawerItemsGeneric1.forEach((x) => {
                if (x?.supplierLoginType.includes(userDetails.supplierLoginType)) {
                    sideDrawerItems.push(x);
                }
                return x;
            
        })
}
        
    else {
            sideDrawerItemsGeneric.forEach((c) => {
                if (c?.roleId.includes(userDetails.roleId)) {
                    sideDrawerItems.push(c);
                }
                
            })
    }
    
    setSideDrawerItems(sideDrawerItems);
}, [userDetails])

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Link to="/" className="d-flex jc-center side-drawer-logo">
        <img src={SiemensLogoWhite} alt="Siemens" className="official-logo" />
      </Link>
          <List className="side-drawer-menu-list" style={{ marginTop: '-10px' }}>
              {landingMenuItems.map((item, index) =>
              (
                  <Link to={item.menuLink} key={index}>
                      <ListItem button>
                          <ListItemIcon className="side-drawer-icons">
                             

                              {icons[item.menuIcon]}
                             
                          </ListItemIcon>
                          <ListItemText primary={item.menuName} />
                      </ListItem>

                  </Link>

              )

              )}



          </List>
      <div className="sideDrawer-footer-block">
        <p>© 2021 Siemens Intranet</p>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <img
            src={DrawerMenuIcon}
            alt="Besys Menu"
            className="besys-menu-icon"
            onClick={toggleDrawer(anchor, true)}
          />
          <SwipeableDrawer
            anchor={anchor}
            open={drawerState[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
